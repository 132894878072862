const targetOrigin = '*';

export const hideCart = () => {
  window.parent.postMessage({ type: 'HIDE_CART' }, targetOrigin);
};

export const goToCartAddress = () => {
  window.parent.postMessage({ type: 'GO_TO_ADDRESS' }, targetOrigin);
};

export const goToProduct = (productUrl: string) => {
  window.parent.postMessage({ type: 'GO_TO_PRODUCT', payload: productUrl }, targetOrigin);
};

export const goToWishlist = () => {
  window.parent.postMessage({ type: 'GO_TO_WISHLIST' }, targetOrigin);
};

export const addWishlistIdInParentStore = (id: string) => {
  window.parent.postMessage({ type: 'ADD_WISHLIST_ID_IN_PARENT_STORE', payload: id }, targetOrigin);
};

export const removeWishlistIdInParentStore = (id: string) => {
  window.parent.postMessage(
    { type: 'REMOVE_WISHLIST_ID_IN_PARENT_STORE', payload: id },
    targetOrigin
  );
};
