// libs
import React, { memo, useCallback } from 'react';
import { styled } from '@nykaa/ui-components';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation } from 'react-router-dom';

// global components
import ModalBase from 'shared/components/Modal';
import OtpFlow from 'shared/layouts/OTPVerification/Mobile';

// constants
import { AUTH_URI } from 'shared/constants/uri';

// styles
export const Modal = styled(ModalBase)`
  width: 680px;
  min-height: 360px;
  border-radius: ${({ theme }) => theme.borders.radiusNone};
  padding: 0;
`;

// defs
interface IAuthModalProps {
  onHide: VoidFunction;
  redirectUrl?: string;
  isLoggedIn: boolean;
}

function AuthModal({ onHide, redirectUrl, isLoggedIn }: IAuthModalProps) {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);

  const onModalClose = useCallback(
    (closeOnly) => {
      if (isEmpty(params.get(AUTH_URI.PARAM_KEY)) === false) {
        params.delete(AUTH_URI.PARAM_KEY);
        history.push(pathname + '?' + params.toString());
      }

      if (redirectUrl && !closeOnly) {
        window.location.href = redirectUrl;
        return;
      }

      onHide();
    },
    [history, onHide, params, pathname, redirectUrl]
  );

  return (
    <Modal
      onHide={onModalClose}
      showCrossIcon={false}
      onEscapeHide={false}
      allowBackdropClick={false}
    >
      <OtpFlow isUserLoggedIn={isLoggedIn} hide={onModalClose} />
    </Modal>
  );
}

export default memo(AuthModal);
