// libs
import isEmpty from 'lodash/isEmpty';

// helpers
import {
  pushMultipleEventsToGTMDatalayer,
  sendDataToGTM,
  sendEventToGTM,
} from 'shared/helpers/analytics/index';
import {
  getPageInfoFromURL,
  getPersistentData as getPersistentDataFromStore,
  removePersistentData,
  updatePersistentData,
  setUserIdentified,
  removeUserIdentified,
  getUserIdentified,
} from 'shared/helpers/analytics/helpers';
import { getDeviceType } from 'shared/components/ResponsiveLoader/helpers';
import { Impression } from 'shared/helpers/utils/impressions/index';

// constants
import {
  IDENTIFY,
  PAGE_VIEW,
  RESET,
  TRACK_TYPE,
  SET_USER_PROPERTY,
  NF_CURRENT_URL,
  NF_PREVIOUS_URL,
  FIRST_PAGE_VIEW,
  DEVICE_TYPE,
  IMPRESSION_CONFIG,
} from './constants';
import { DEFAULT_CURRENCY_CODE, RENDER_MODE } from 'shared/constants';

// type
import { EventsProps, UserData } from './types';
export default class NFAnalytics {
  // page properties
  public static pageProperties = {
    pagename: '',
    pagetype: '',
    previousPagename: '',
    previousPagetype: '',
  };

  // class properties
  private static _isLoginSet = false;

  // global properties: will be sent in all event
  private static isLoggedIn = false;
  private static sourcePlatform = '';
  private static _events: EventsProps[] = [];
  private static isFirstPageView = false;

  // Interval to batch impressions
  private static impressionInterval: NodeJS.Timeout | null = null;

  // Array to store impressions for batching
  private static batchImpressionEvents: Impression[] = [];

  private constructor() {
    NFAnalytics.initializeImpressionInterval();
    NFAnalytics.handlePageUnload();
  }

  private static setPageProperties() {
    const previousPageUrl = sessionStorage.getItem(NF_CURRENT_URL) || '';
    const currentPageUrl = window && window.location && window.location.href;

    sessionStorage.setItem(NF_PREVIOUS_URL, previousPageUrl);
    sessionStorage.setItem(NF_CURRENT_URL, currentPageUrl);

    const { pagename: previousPagename, pagetype: previousPagetype } =
      getPageInfoFromURL(previousPageUrl);
    const { pagename, pagetype } = getPageInfoFromURL(currentPageUrl);

    this.pageProperties = {
      ...this.pageProperties,
      pagename,
      pagetype,
      previousPagename,
      previousPagetype,
    };
    this.updateDataLayer(this.pageProperties);
  }

  private static _updateEvents(event: EventsProps) {
    this._events.push(event);
  }

  private static _clearEvents() {
    this._events = [];
  }

  private static getGlobalProperties() {
    return {
      isLoggedIn: this.isLoggedIn,
      sourcePlatform: this.sourcePlatform,
      currency: DEFAULT_CURRENCY_CODE,
    };
  }

  public static setSourcePlatform(sourcePlatform = '') {
    if (sourcePlatform) {
      this.sourcePlatform = sourcePlatform;
      return;
    }

    this.sourcePlatform =
      getDeviceType() === RENDER_MODE.MOBILE ? DEVICE_TYPE.MWEB : DEVICE_TYPE.DESKTOP;
  }

  private static getPersistentData = (type: string) => {
    const persistentData = getPersistentDataFromStore();
    let data: { [key: string]: any } = {};

    if (isEmpty(persistentData)) return;

    data = type === TRACK_TYPE.EVENT ? persistentData?.event : persistentData?.pageload;
    return data;
  };

  private static identifyLoggedInUsers() {
    if (!this.isFirstPageView) return;

    if (this.isLoggedIn && getUserIdentified() === false) {
      sendEventToGTM(IDENTIFY);
      sendEventToGTM(SET_USER_PROPERTY);
      setUserIdentified();
    }
  }

  private static pushPendingData() {
    sendDataToGTM({
      ...this.getGlobalProperties(),
      ...this.pageProperties,
      ...this.getPersistentData(TRACK_TYPE.PAGELOAD),
    });
    removePersistentData(TRACK_TYPE.PAGELOAD);
  }
  private static pushPendingEvent() {
    if (this.isFirstPageView && this.isLoggedIn) {
      sendEventToGTM(IDENTIFY);
      sendEventToGTM(SET_USER_PROPERTY);
    }

    if (this._events.length > 0) {
      pushMultipleEventsToGTMDatalayer(this._events);
      this._clearEvents();
      return;
    }
    this.identifyLoggedInUsers();
  }

  public static setIsLogin(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
    this._isLoginSet = true;

    this.pushPendingData();
    this.pushPendingEvent();
    this.sendImpressions();
  }

  public static firePageView() {
    this.setFirstPageView();
    this.setPageProperties();
    sendEventToGTM(PAGE_VIEW);
  }

  public static identify(customerId: string) {
    setUserIdentified();
    sendEventToGTM(IDENTIFY, { customerId });
    this.isLoggedIn = true;
  }

  // to update GTM datalayer
  public static updateDataLayer(data: any) {
    sendDataToGTM(data);
  }

  private static pushPersistentData(type: string) {
    const data = this.getPersistentData(type);

    if (data != undefined) {
      this.updateDataLayer(data);
      removePersistentData(type);
    }
  }

  private static setFirstPageView() {
    if (window.sessionStorage) {
      if (sessionStorage.getItem(FIRST_PAGE_VIEW)) {
        sessionStorage.setItem(FIRST_PAGE_VIEW, 'false');
        this.isFirstPageView = false;
      } else {
        // otherwise set this hit as first page view
        sessionStorage.setItem(FIRST_PAGE_VIEW, 'true');
        this.isFirstPageView = true;
      }
    }
  }

  // push events to GTM
  public static track({ type = TRACK_TYPE.EVENT, event, data = {} }: EventsProps) {
    if (type === TRACK_TYPE.PAGELOAD) {
      this.firePageView();
    }
    // if _islogin not set then save events in memory
    if (this._isLoginSet === false) {
      this._updateEvents({ event, data });
      return;
    }

    // persistent data
    this.pushPersistentData(type);

    // track impressions
    if (type === TRACK_TYPE.IMPRESSION) {
      sendEventToGTM(event, data);
      return;
    }

    // send event to GTM
    sendEventToGTM(event, { ...this.getGlobalProperties(), ...data });
  }

  // should be called only on logout and token expiry to reset distinct id and set super properties
  public static reset() {
    sendEventToGTM(RESET);
    removeUserIdentified();
  }

  // to set user data
  public static setUserData(userData: UserData) {
    // gtm
    this.updateDataLayer(userData);
  }

  // update GTM data layer before next track call with this data stored in session storage
  public static updateDatalayerBeforeNext(
    data: { [key: string]: any },
    updateBefore = TRACK_TYPE.PAGELOAD
  ) {
    updatePersistentData(data, updateBefore);
  }

  private static sendImpressions() {
    if (this.batchImpressionEvents.length > 0) {
      this.track({
        type: TRACK_TYPE.IMPRESSION,
        event: 'impressions',
        data: { impressions: this.batchImpressionEvents },
      });

      this.batchImpressionEvents = []; // Clear impression events for the next batch
    }
  }

  // Function to initialize the interval for batching impressions
  private static initializeImpressionInterval() {
    const { IMPRESSION_INTERVAL } = IMPRESSION_CONFIG;

    this.impressionInterval = setInterval(() => {
      this.sendImpressions();
    }, IMPRESSION_INTERVAL);
  }

  // Function to destroy the interval for batching impressions
  private static handlePageUnload() {
    window.addEventListener('beforeunload', () => {
      this.sendImpressions(); // Send any remaining impressions
      this.destroyImpressionInterval(); // Destroy the impression interval
    });
  }
  private static destroyImpressionInterval() {
    if (this.impressionInterval !== null) {
      clearInterval(this.impressionInterval);
      this.impressionInterval = null;
    }
  }

  public static trackImpression(impressionData: Impression) {
    const { IMPRESSION_BATCH_SIZE } = IMPRESSION_CONFIG;

    if (this.impressionInterval === null) {
      this.initializeImpressionInterval();
    }

    // Add the impression to the batch
    this.batchImpressionEvents.push(impressionData);

    // If the batch size is greater than or equal to 8, send the impression
    if (this.batchImpressionEvents.length >= IMPRESSION_BATCH_SIZE) {
      this.sendImpressions();
    }
  }
}
