import { styled } from '@nykaa/ui-components';
import { keyframes } from '@emotion/core';
import { hexToRgb } from '@nykaa/ui-components/styles/utils';

const animateShimmer = keyframes`
   0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
`;

export const Shimmer = styled.div`
  background: #dfe1e6;
  background-image: linear-gradient(to right, #dfe1e6 0%, #f2f4fa 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  animation: ${animateShimmer} 1s linear forwards infinite;
`;

export const ShimmerAvatar = styled.div`
  background: #dfe1e6;
  background-image: linear-gradient(to right, #dfe1e6 0%, #f2f4fa 100%);
  animation: ${animateShimmer} 1s linear forwards infinite;
  background-repeat: no-repeat;
  border-radius: ${({ theme }) => theme.borders.radius50}; //chose floor value
`;

export const ShimmerV2 = styled.div`
  ${({ theme }) => `
    background-image: linear-gradient(to right, ${theme.colors.surface20} 0%, ${theme.colors.surface10} 20%, ${theme.colors.surface20} 40%, ${theme.colors.surface20} 100%);
  `};
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  animation: ${animateShimmer} 1s linear forwards infinite;
`;

export const BarShimmer = styled.div`
  ${({ theme }) => `
  background-image: linear-gradient(to right, ${theme.colors.primary} 0%, ${
    theme.colors.primary
  } 20%, ${theme.colors.primary} 40%, ${theme.colors.primary} 100%);
  background-color: ${hexToRgb(theme.colors.primary, 0.08)};
`};
  background-repeat: no-repeat;
  background-size: 20vw 2vw;
  display: inline-block;
  position: relative;
  animation: ${animateShimmer} 2s linear forwards infinite;
`;
