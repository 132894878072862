import React, { useContext, memo } from 'react';
import ExperimentContext from './ExperimentContext';

interface IVariantProps {
  value: string;
  children: React.ReactNode;
}

function Variant({ value, children }: IVariantProps) {
  const { variant } = useContext(ExperimentContext);

  if (value !== variant) {
    return null;
  }

  return <>{children}</>;
}

export default memo(Variant);
