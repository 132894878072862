// libs
import { connect } from 'react-redux';

// Components
import SuccessLoginToast from './SuccessLoginToast';

// redux store
import { shouldShowSuccessLoginToast } from 'shared/store/app/selectors';
import { hideLoginInfoToast } from 'shared/store/app/actions';

const mapStateToProps = (state: any) => {
  return {
    shouldShowToast: shouldShowSuccessLoginToast(state),
  };
};

const mapDispatchToProps = {
  afterHide: hideLoginInfoToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessLoginToast);
