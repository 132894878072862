// libs
import React, { memo } from 'react';
import { Redirect } from 'react-router-dom';

// global components
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import ChunkFallback from 'shared/components/ChunkFallback';

// layouts
import AllBrandsLayout from 'shared/layouts/AllBrandsLayout';

const MOBILE_ALL_BRANDS_PATH = '/mega-menu-shop?path=All%20Brands';

function AllBrands() {
  return (
    <RouteChunkRenderer
      mobileComponent={<Redirect to={MOBILE_ALL_BRANDS_PATH} />}
      desktopComponent={<AllBrandsLayout fallback={<ChunkFallback />} />}
    />
  );
}

export default memo(AllBrands);
