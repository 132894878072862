// libs
import React, { memo } from 'react';

// global components
import RedirectToHomePage from 'shared/components/RedirectToHomePage/RedirectToHomePage';
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';

// component
import SizeGuide from 'shared/layouts/SizeGuide';

function SizeGuideSkuVersion() {
  return (
    <RouteChunkRenderer mobileComponent={<SizeGuide />} desktopComponent={<RedirectToHomePage />} />
  );
}

export default memo(SizeGuideSkuVersion);
