// libs
import React from 'react';

// global components
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import ChunkFallback from 'shared/components/ChunkFallback';

// components
import AddressBookComponent from 'shared/layouts/AddressBook';
import RedirectToHomePage from 'components/RedirectToHomePage';

function AddressBook() {
  return (
    <RouteChunkRenderer
      mobileComponent={<AddressBookComponent fallback={<ChunkFallback />} />}
      desktopComponent={<RedirectToHomePage />}
    />
  );
}

export default AddressBook;
