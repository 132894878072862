import createLightTheme from '@nykaa/ui-components/themes/lightTheme/createLightTheme';
import { Theme } from '@nykaa/ui-components/themes/types';
import { getDomainConfig } from 'shared/domainConfig/helpers';

/**
 *
 * Note:
 * While migrating to UI components, please verify the snapshots for any null values
 * applied in css.
 * This will happen due to hexToRgb function being applied twice.
 * In that case we'll have to ask the design team for a new global token instead of
 * hexToRgb conversion in this file.
 * Example: textPrimary token
 */

export const getAppTheme = (): Theme => {
  const { THEME } = getDomainConfig();
  return createLightTheme(THEME);
};
