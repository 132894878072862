//helpers
import { isScriptLoaded } from 'shared/helpers/utils';
import asyncDeferScript, { LOAD_TYPE } from 'shared/helpers/dom/deferScript';
import { getCookie, setCookie } from 'shared/helpers/utils';
import { CHECKOUT_COOKIES } from 'shared/constants';
import logger from 'shared/logger';

export const loadScripts = (scriptDetails: { scriptPath: string; loadType: LOAD_TYPE }[] = []) => {
  scriptDetails.map((scriptInfo) => {
    const { scriptPath, loadType } = scriptInfo;

    if (!isScriptLoaded(scriptPath)) {
      asyncDeferScript(scriptPath, loadType);
    }
  });
};

export const removeCartCheckoutExperimentCookies = () => {
  try {
    if (getCookie(CHECKOUT_COOKIES.DESKTOP)) setCookie(CHECKOUT_COOKIES.DESKTOP, '', -1);
    if (getCookie(CHECKOUT_COOKIES.MOBILE)) setCookie(CHECKOUT_COOKIES.MOBILE, '', -1);
    if (getCookie(CHECKOUT_COOKIES.PCI)) setCookie(CHECKOUT_COOKIES.PCI, '', -1);
  } catch (error) {
    logger.info({
      message: `Cart Checkout Cookie Remove ${error?.message}`,
    });
  }
};
