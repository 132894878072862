// config
import { BROWSER, DEVICE_TYPE, OS, WEBVIEW } from './deviceConfig';

// const
import { DESKTOP, DEFAULT_BROWSER, DEFAULT_OS, BROWSER_VALUE } from './constants';

// types
import { UserEnvironment, DetectDeviceByRegexTypes } from './types';

// logger
import logger from 'shared/logger';

export const detectDeviceByRegex: DetectDeviceByRegexTypes = (
  userAgent,
  items,
  defaultValue = ''
) => {
  userAgent = getUserAgentStrings(userAgent);
  for (let i = 0; i < items.length; i++) {
    if (items[i].regex.test(userAgent)) return items[i].value;
  }
  return defaultValue;
};

// Function to get user-agent strings
export const getUserAgentStrings = (userAgent: string = '') => {
  return (
    userAgent ||
    (typeof window !== 'undefined' && window?.navigator ? window.navigator.userAgent : '')
  );
};

// Function to detect the Operating System
export const detectOS = (userAgent: string) => detectDeviceByRegex(userAgent, OS, DEFAULT_OS);

// Function to detect the Browser
export const detectBrowser = (userAgent: string) =>
  detectDeviceByRegex(userAgent, BROWSER, DEFAULT_BROWSER);

// Function to detect the Device Type
export const detectDeviceType = (userAgent: string) =>
  detectDeviceByRegex(userAgent, DEVICE_TYPE, DESKTOP);

// Function to detect WebView
export const detectWebView = (userAgent: string) =>
  detectDeviceByRegex(userAgent, WEBVIEW, BROWSER_VALUE);

// Main function to get User Agent Information
export const getUserAgentInfo = (userAgent: string): UserEnvironment => {
  try {
    return {
      os: detectOS(userAgent),
      browser: detectBrowser(userAgent),
      deviceType: detectDeviceType(userAgent),
      environment: detectWebView(userAgent),
    };
  } catch (e) {
    logger.error({ e, METHOD_NAME: 'getUserAgentInfo' });
    return {
      os: DEFAULT_OS,
      browser: DEFAULT_BROWSER,
      deviceType: DESKTOP,
      environment: BROWSER_VALUE,
    };
  }
};
