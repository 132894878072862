// libs
import { connect } from 'react-redux';

// component
import MobileHeaderProvider from './MobileHeaderProvider';

// redux selectors
import { getHeaderOptions, isHeaderVisible } from 'shared/store/app/selectors';

const mapStateToProps = (state: any) => ({
  headerProps: getHeaderOptions(state),
  isHeaderVisible: isHeaderVisible(state),
});

export default connect(mapStateToProps)(MobileHeaderProvider);
