// libs
import React, { memo } from 'react';

// styles
import { SpinnerBase } from './styles';

const Loader = () => {
  return (
    <SpinnerBase className="spinnerBase">
      <svg className="svg-container" viewBox="0 0 56 56">
        <circle className="loader-svg bg" cx="28" cy="28" r="24"></circle>
        <circle className="loader-svg animate" cx="28" cy="28" r="24"></circle>
      </svg>
    </SpinnerBase>
  );
};

export default memo(Loader);
