import React, { memo } from 'react';
import { css, Global } from '@emotion/core';
import Helmet from 'react-helmet';
import InterWoff from './assets/fonts/Inter.woff2';

const fontStyles = css`
  @font-face {
    font-family: 'Inter';
    src: url('${InterWoff}') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url('${InterWoff}') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url('${InterWoff}') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
`;

function Fonts() {
  return (
    <>
      <Helmet>
        <link rel="preload" as="font" href={InterWoff} crossOrigin="" />
      </Helmet>
      <Global styles={fontStyles} />
    </>
  );
}

export default memo(Fonts);
