export const OS = [
    { regex: /Windows/, value: "windows" },
    { regex: /Android/, value: "android" },
    { regex: /iPhone|iPad|iPod/, value: "iOS" },
    { regex: /Macintosh|Mac OS X/, value: "mac" },
    { regex: /Linux/, value: "linux" }
];

export const BROWSER = [
    { regex: /Opera|OPR/, value: "Opera" },
    { regex: /Edge|Edg/, value: "Edge" },
    { regex: /Chrome/, value: "Chrome" },
    { regex: /Safari/, value: "Safari" },
    { regex: /Firefox/, value: "Firefox" },
    { regex: /MSIE|Trident/, value: "Internet Explorer" },
];

export const DEVICE_TYPE = [
    { regex: /Tablet|iPad|iPod/, value: "Tablet" },
    { regex: /Mobi|Android|iPhone/, value: "mobile" },
];

export const WEBVIEW = [
    { regex: /nykaa-ios/, value: "iOS WebView" },
    { regex: /nykaa-android/, value: "Android WebView" }
];