export const VP_DOC_KEY = 'NYKAA_VP_DATA';
export const VP_USER = 'NYKAA_VP_USER';
export const VP_STATE = {
  ALLOW_ALL: 0,
  BLOCKED: 1,
  BLOCKED_ALL: 2,
};

export const VP_BLOCK_MODE = {
  FULL_PAGE: 'FULL_PAGE',
  RESPONSE: 'RESPONSE',
};
