// libs
import React from 'react';

// global components
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import ChunkFallback from 'shared/components/ChunkFallback';

// components
import HomePageMobile from 'shared/layouts/Homepage/HomePageMobile';
import HomePageDesktop from 'shared/layouts/Homepage/HomePageDesktop';

function HomePage() {
  return (
    <>
      <RouteChunkRenderer
        mobileComponent={<HomePageMobile fallback={<ChunkFallback />} />}
        desktopComponent={<HomePageDesktop fallback={<ChunkFallback />} />}
      />
    </>
  );
}

export default HomePage;
