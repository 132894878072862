/**
 * Generate random number between and inclusive of minimum and maximum
 * @param {number} min minimum number
 * @param {number} max maximum number
 */

import { VP_DOC_KEY } from 'shared/components/VisitorPriortization/constants';
export const getRandomNumber = (min = 1, max = 100) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const getCachedVPDoc = (): VP.Doc | undefined => {
  const cachedVPDoc = window.sessionStorage.getItem(VP_DOC_KEY);

  if (cachedVPDoc === null) {
    return undefined;
  }

  try {
    return JSON.parse(cachedVPDoc) as VP.Doc;
  } catch (e) {
    /** TODO - sentry */
    return undefined;
  }
};
