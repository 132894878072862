// libs
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// defs
import { AppState } from 'shared/store/app/types';

// constants
import { WEB_APP_TYPE, DEFAULT_COUNTRY } from 'shared/constants';

export const app = (state: { app: AppState }): AppState => state.app;

export const getHeaderDiscount = createSelector([app], (appData) => appData.headerDiscount);

export const hasHeaderDiscount = createSelector(
  [app],
  (appData) => appData.headerDiscount.length > 0
);

export const isMenuFetching = createSelector([app], (appData) => appData.menu.isFetching);

export const getMenuData = createSelector([app], (appData) => appData.menu.data);

export const isLoggedIn = createSelector(
  [app],
  (appData) => isEmpty(appData.user.customerData) === false
);

export const getCustomerData = createSelector([app], (appData): AppState['user']['customerData'] =>
  get(appData, 'user.customerData')
);

export const isCustomerFromIndia = createSelector(
  [app],
  (appData): boolean => get(appData, 'user.base_location', '') === 'IN'
);

export const getCustomerFirstName = createSelector([app], (appData): string =>
  get(appData, 'user.customerData.firstname', '')
);

export const getCustomerLastName = createSelector([app], (appData): string =>
  get(appData, 'user.customerData.lastname', '')
);

export const getCustomerEmail = createSelector([app], (appData): string =>
  get(appData, 'user.customerData.email', '')
);

export const getCustomerId = createSelector([app], (appData): string =>
  get(appData, 'user.customerData.id', '')
);

export const getCustomerDisplayEmail = createSelector([app], (appData): string => {
  const hideEmail = get(appData, 'user.customerData.hideEmail', false);
  if (hideEmail) {
    return '';
  }

  return get(appData, 'user.customerData.email', '');
});

export const getCartCount = createSelector(
  [app],
  (appData): number | string => appData.user.cartCount
);

export const getUserFormKey = createSelector([app], (appData): string => appData.user.formKey);

export const getSSRHeaders = createSelector([app], (appData) => appData.ssrHeaders);

export const getHeaderOptions = createSelector(
  [app],
  (appData): AppState['headerOptions'] => appData.headerOptions
);

export const isInProgress = createSelector([app], (appData) => appData.inProgress);

export const isUserFetching = createSelector([app], (appData) => appData.user.isFetching);

export const isUserFetchingError = createSelector([app], (appData) => appData.user.isError);

export const isHeaderVisible = createSelector([app], (appData) => appData.showHeader);

export const isFooterVisible = createSelector([app], (appData) => appData.showFooter);

export const isFooterDownloadAppSectionVisible = createSelector(
  [app],
  (appData) => appData.showFooterDownloadAppSection
);

export const getCustomerMobileNumber = createSelector([app], (appData): string =>
  get(appData, 'user.customerData.mobile', '')
);

export const getWebAppType = createSelector([app], (appData): string =>
  get(appData, 'deviceType.webAppType', WEB_APP_TYPE.DESKTOP)
);

export const getOSType = createSelector([app], (appData): string =>
  get(appData, 'deviceType.os', '')
);

export const shouldHideCustomerEmail = createSelector([app], (appData): boolean =>
  get(appData, 'user.customerData.hideEmail', false)
);

export const shouldMergeAccount = createSelector([app], (appData): boolean =>
  get(appData, 'user.customerData.allowMergeAccount', false)
);
export const getAppSearchTerm = createSelector([app], (appData): string =>
  get(appData, 'searchTerm', '')
);

export const getBaseCountry = createSelector([app], (appData): string =>
  get(appData, 'baseCountry', DEFAULT_COUNTRY)
);

export const shouldShowSuccessLoginToast = createSelector([app], (appData): boolean =>
  get(appData, 'showSuccessLoginToast', false)
);

export const isPostRequestAllowed = createSelector(
  [app],
  (appData): boolean => appData.isPostRequestAllowed
);

export const onIntlNetwork = createSelector([app], (appData): boolean => appData.onIntlNetwork);

export const getCartNudgeCount = createSelector(
  [app],
  (appData): number | undefined => appData.headerOptions.cartNudgeCount
);

export const getWishlistNudgeCount = createSelector(
  [app],
  (appData): boolean => appData.headerOptions.wishlistNudgeCount
);

export const isIncognitoMode = createSelector([app], (appData): boolean => appData.isIncognitoMode);

export const isCustomerNewUser = createSelector(
  [app],
  (appData): boolean => get(appData, 'user.customerData.newUser', '') === 1
);
