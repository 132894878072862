// libs
import React, { memo } from 'react';
import ResponsiveLoader from 'shared/components/ResponsiveLoader';

// constants
import { BREAKPOINTS } from 'shared/styles/base';

interface IRouteChunkRendererProps {
  mobileComponent: React.ReactNode;
  desktopComponent: React.ReactNode;
}

function RouteChunkRenderer({ mobileComponent, desktopComponent }: IRouteChunkRendererProps) {
  return (
    <>
      <ResponsiveLoader breakpoint={BREAKPOINTS.DESKTOP} down>
        {mobileComponent}
      </ResponsiveLoader>
      <ResponsiveLoader breakpoint={BREAKPOINTS.DESKTOP} up>
        {desktopComponent}
      </ResponsiveLoader>
    </>
  );
}

export default memo(RouteChunkRenderer);
