import { createContext, useContext } from 'react';

interface IOverlaySheetContext {
  doHide: VoidFunction;
}

const initialValue: IOverlaySheetContext = {
  doHide: () => {
    /** DO NOTHING */
  },
};

const contextValue = createContext(initialValue);

export const useOverlaySheetContext = () => useContext(contextValue);

export default contextValue;
