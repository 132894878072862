import storage from 'shared/helpers/storage';
import { NUDGE_LOCAL_STORAGE_KEY_NAME } from './constants';

interface IUpdateSessionProps {
  currentTime: Date;
  prevValue: { shown_time: number; expiry: number };
  firstBreakpoint: number;
  ttlBeforeFirstBreakpoint: number;
  ttlAfterFirstBreakpoint: number;
}

export const getSessionInfo = () => storage.getItem<string>(NUDGE_LOCAL_STORAGE_KEY_NAME, '');

export const setSessionInfo = (ttlBeforeFirstBreakpoint: number) => {
  const now = new Date();
  const value = {
    shown_time: 1,
    expiry: now.getTime() + ttlBeforeFirstBreakpoint,
  };

  storage.setItem(NUDGE_LOCAL_STORAGE_KEY_NAME, JSON.stringify(value));
};

export const updateSessionInfo = ({
  currentTime,
  prevValue,
  firstBreakpoint,
  ttlBeforeFirstBreakpoint,
  ttlAfterFirstBreakpoint,
}: IUpdateSessionProps) => {
  const newValue = {
    shown_time: prevValue.shown_time + 1,
    expiry:
      currentTime.getTime() +
      (prevValue.shown_time <= firstBreakpoint
        ? ttlBeforeFirstBreakpoint
        : ttlAfterFirstBreakpoint),
  };
  storage.setItem(NUDGE_LOCAL_STORAGE_KEY_NAME, JSON.stringify(newValue));
};
