// libs
import { memo, useEffect } from 'react';
import { initializeExperiment } from '@nykaa/experiment-sdk/client';
import { reInitializeForNykaaUsers } from '@nykaa/experiment-sdk/client/initializeExperiment';
import { ExperimentConfig } from '@nykaa/experiment-sdk/types';
import { useSelector } from 'react-redux';

// helpers
import { log } from 'shared/helpers/newRelic';

// analytics
import { sendExperiments } from './analytics';

// constants
import { TAG_IDENTIFIER, ERROR_TAGS } from 'shared/helpers/SentryLogger/constants';

// redux
import { getCustomerEmail, onIntlNetwork } from 'shared/store/app/selectors';

interface InitializeExperimentProps {
  experimentConfig: ExperimentConfig[];
  isExperimentV2Enabled: boolean;
}

function InitializeExperiment({
  experimentConfig,
  isExperimentV2Enabled,
}: InitializeExperimentProps) {
  const email = useSelector(getCustomerEmail);
  const isInternationalUser = useSelector(onIntlNetwork);

  // Initializing Experiment SDK
  // disable experiment for international users
  useEffect(() => {
    if (isExperimentV2Enabled === false || isInternationalUser) {
      return;
    }

    try {
      initializeExperiment(experimentConfig, (data) => sendExperiments(data));
    } catch (err) {
      log({
        errTitle: 'Error in initializeExperiment',
        tags: { [TAG_IDENTIFIER.MODULE]: ERROR_TAGS.AB_EXPERIMENT },
        err: err,
      });
    }
  }, [experimentConfig, isExperimentV2Enabled, isInternationalUser]);

  // Re-Initializing Experiment SDK (To be used only for bypassing support for NYKAA internal users)
  useEffect(() => {
    if (isExperimentV2Enabled === false || isInternationalUser) {
      return;
    }

    try {
      reInitializeForNykaaUsers(experimentConfig, (data) => sendExperiments(data), email);
    } catch (err) {
      log({
        errTitle: 'Error in reInitializeForNykaaUsers',
        err: err,
        tags: { [TAG_IDENTIFIER.MODULE]: ERROR_TAGS.AB_EXPERIMENT },
      });
    }
  }, [email, experimentConfig, isExperimentV2Enabled, isInternationalUser]);

  return null;
}

export default memo(InitializeExperiment);
