// libs
import { styled } from '@nykaa/ui-components';
import { keyframes } from '@emotion/core';

// constants
import { MWEB_HEADER_DISCOUNT_HEIGHT } from 'shared/styles/base';

const blobs = keyframes`
  0% {
    opacity: 0;
    transform: scale(0) translate(calc(-330px - 50%), -50%);
  }
  1%,
  99% {
    opacity: 1;
  }
  35%,
  65% {
    opacity: 1;
    transform: scale(0.9) translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: scale(0) translate(calc(330px - 50%), -50%);
  }
`;

const blobGrow = keyframes`

  0%,
  100%,
  39%,
  92% {
    transform: scale(0) translate(-50%, -50%);
  }
  40%,
  42% {
    transform: scale(1, 0.9) translate(-50%, -50%);
  }
  43%,
  44%,
  86%,
  87% {
    transform: scale(1.2, 1.1) translate(-50%, -50%);
  }
  45%,
  46%,
  84%,
  85% {
    transform: scale(1.3, 1.2) translate(-50%, -50%);
  }
  47%,
  48%,
  82%,
  83% {
    transform: scale(1.4, 1.3) translate(-50%, -50%);
  }
  52%,
  80%,
  81% {
    transform: scale(1.5, 1.4) translate(-50%, -50%);
  }
  54% {
    transform: scale(1.7, 1.6) translate(-50%, -50%);
  }
  58% {
    transform: scale(1.8, 1.7) translate(-50%, -50%);
  }
  68%,
  70% {
    transform: scale(1.7, 1.5) translate(-50%, -50%);
  }
  78% {
    transform: scale(1.6, 1.4) translate(-50%, -50%);
  }
`;

export const Container = styled.div`
  display: flex;
  height: calc(100vh - ${MWEB_HEADER_DISCOUNT_HEIGHT}px);
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  height: 12px;
  width: 12px;
  margin: auto;
`;

export const Blobs = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borders.radius60}; // chose floor value
`;

export const BaseBlob = styled.div`
  position: absolute;
  background: #030303;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: ${({ theme }) => theme.borders.radiusFull};
`;

export const BlobsCenter = styled(BaseBlob)`
  display: flex;
  z-index: 10000;
  align-items: center;
  transform-origin: left top;
  transform: scale(0.9) translate(-50%, -50%);
  animation: ${blobGrow} linear 3.4s infinite;
`;

export const Blob = styled(BaseBlob)`
  animation: ${blobs} ease-out 3.4s infinite;
  transform: scale(0.9) translate(-50%, -50%);
  transform-origin: center top;
  opacity: 0;

  &:nth-of-type(1) {
    animation-delay: 0.2s;
  }

  &:nth-of-type(2) {
    animation-delay: 0.4s;
  }

  &:nth-of-type(3) {
    animation-delay: 0.6s;
  }

  &:nth-of-type(4) {
    animation-delay: 0.8s;
  }

  &:nth-of-type(5) {
    animation-delay: 1s;
  }
`;
