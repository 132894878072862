// libs
import { SerializedStyles } from '@emotion/core';
import { styled } from '@nykaa/ui-components';

export const Container = styled.div`
  margin: 0 auto;
`;

export const AppLayoutContainer = styled.div<{ cssProps?: (boolean | SerializedStyles)[] }>`
  ${({ cssProps }) => cssProps}
`;
