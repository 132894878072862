// constants
import { CHECKOUT_V3_ADDRESS_URI, NEW_PAYMENT_STACK } from 'shared/constants/uri';

// helpers
import { getDeviceType } from 'shared/helpers/app';

// defs
import { CartItem } from 'shared/types/cart';

export const getAllImageFromItems = (items: CartItem[]) => {
  return items.map((item) => item.imageUrl);
};

export const getNewCheckoutURI = () => {
  const isMobile = getDeviceType() === 'MSITE';

  if (isMobile) {
    return NEW_PAYMENT_STACK;
  }

  return CHECKOUT_V3_ADDRESS_URI;
};

export const setCheckOutTiming = () => {
  window.sessionStorage.setItem('checkoutRedirectionTimestamp', String(Date.now()));
};
