// libs
import React, { memo } from 'react';
import DomainConfigContext from './context';

// helpers
import { getDomainConfig } from 'shared/domainConfig/helpers';

interface DomainContextProvider {
  children: React.ReactNode;
}

function Provider({ children }: DomainContextProvider) {
  const domainConfig = getDomainConfig();
  return (
    <DomainConfigContext.Provider value={domainConfig}>{children}</DomainConfigContext.Provider>
  );
}

export default memo(Provider);
