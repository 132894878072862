// constants
import { PRODUCT_TO_ADD_TO_WISHLIST } from './constants';

export const setProductToAddToWishlistAfterLogin = (productId: string) => {
  try {
    window.sessionStorage.setItem(PRODUCT_TO_ADD_TO_WISHLIST, productId);
  } catch (err) {
    return;
  }
};

export const getStoredProductToBeAddedToWishlist = () => {
  try {
    return window.sessionStorage.getItem(PRODUCT_TO_ADD_TO_WISHLIST) || '';
  } catch (err) {
    return '';
  }
};

export const removeStoredProductToBeAddedToWishlist = () => {
  try {
    window.sessionStorage.removeItem(PRODUCT_TO_ADD_TO_WISHLIST);
  } catch (err) {
    return;
  }
};
