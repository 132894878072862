// libs
import get from 'lodash/get';

// helpers
import { getCachedCustomerData } from 'shared/helpers/userCache';

const mobileRegExp = /mobile|phone|ipad|mini|tablet/i;
const androidRegExp = /nykaa-android/i;
const iosRegExp = /nykaa-ios/i;

export const getDeviceType = () => {
  let appType: 'android' | 'ios' | 'mweb' | 'dweb' = 'dweb';

  const userAgent = window.navigator.userAgent || '';

  if (androidRegExp.test(userAgent)) {
    appType = 'android';
  }

  if (iosRegExp.test(userAgent)) {
    appType = 'ios';
  }

  if (mobileRegExp.test(userAgent)) {
    appType = 'mweb';
  }

  return appType;
};

export const getEnvironment = (): 'prod' | 'preprod' | 'qa' => {
  const host = window.location.host;

  switch (host) {
    case 'www.nykaafashion.com': {
      return 'prod';
    }

    case 'preprod.nykaafashion.com': {
      return 'preprod';
    }

    default: {
      return 'qa';
    }
  }
};

export const getCachedCustomerId = () => {
  const cachedCustomerData = getCachedCustomerData();

  return get(cachedCustomerData, 'id', undefined);
};
