// libs
import React, { memo, useEffect } from 'react';
import Helmet from 'react-helmet';

// styles
import { Container } from './styles';

// constants
import { TEXT } from './constants';

import { onPageLoad } from './analytics';

function NotFoundError({ onBack }: { onBack?: VoidFunction }) {
  useEffect(() => {
    onPageLoad();
  }, []);

  const handleBackClick = (event: React.MouseEvent) => {
    event.preventDefault();

    if (onBack) {
      onBack();
    } else {
      window.location.href = '/';
    }
  };

  return (
    <Container>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className="brokenPage">
        <div className="brokenLink icon-broken-link" />
        <div data-test={'404'} className="msg404">
          {TEXT.FOUR_O_FOUR}
        </div>
        <div data-test={'broken-message'} className="brokenmsg">
          {TEXT.BROKEN_MSG}
        </div>
        <div data-test={'corrupt-message'} className="corruptmsg">
          {TEXT.CORRUPT_MSG}
        </div>
        <a href="/" className="goBack" onClick={handleBackClick}>
          {TEXT.HOME_LINK_LABEL}
        </a>
      </div>
    </Container>
  );
}

export default memo(NotFoundError);
