import React, { memo, useEffect, useState } from 'react';
import { styled } from '@nykaa/ui-components';
import { keyframes } from '@emotion/core';

const slideIn = keyframes`
    0% {
        transform: translateY(100%);
    }

    8% {
        transform: translateY(0);
    }
    92% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
`;

const Connectivity = styled.p<{ variant: 'online' | 'offline' }>`
  position: fixed;

  /*z-index above pdp CTA*/
  z-index: 6;
  bottom: 0;
  margin: 0;
  padding: ${({ theme }) => theme.spacing.spacing20};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  ${({ theme }) => theme.typography.subTitleSmall};
  color: #fff;
  background: ${({ variant }) =>
    variant === 'online'
      ? ({ theme }) => theme.colors.positive
      : ({ theme }) => theme.colors.negative};
  animation: ${slideIn} 3s ease-in forwards;
`;

const OFFLINE_TEXT = 'No internet connection';
const ONLINE_TEXT = "You're back online";

function ConnectivityNotifier() {
  const [isOnline, setIsOnline] = useState(true);
  const [showNotification, setShowNotification] = useState(false);

  const onAnimationEndHandler = () => setShowNotification(false);

  useEffect(() => {
    const handleConnectivity = (detectOnline: boolean = true) => {
      setIsOnline(detectOnline);
      setShowNotification(true);
    };

    const handleOnline = () => {
      handleConnectivity(true);
    };

    const handleOffline = () => {
      handleConnectivity(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (showNotification === false) {
    return null;
  }

  return (
    <Connectivity
      variant={isOnline ? 'online' : 'offline'}
      data-test="ConnectivityElement"
      onAnimationEnd={onAnimationEndHandler}
    >
      {isOnline ? ONLINE_TEXT : OFFLINE_TEXT}
    </Connectivity>
  );
}

export default memo(ConnectivityNotifier);
