// libs
import get from 'lodash/get';

// helpers
import { getVPDoc } from 'shared/components/VisitorPriortization/helpers/csr';

const DEFAULT_CONFIG = {
  attributed_title:
    "<h2 style='font: 500 1.714rem/1.333 Cera; color: #001325; margin: 16px 0; text-align: center;'>Good things come to those who wait.</h2>",
  error_msg:
    "<p style='font: 1.142rem/1.5 Cera; color: #5c6874; margin: 16px 0; text-align: center;'>We are making the Hot Pink Sale bigger and better. Check back shortly.</p>",
  error_img_url: 'https://asset.nykaafashion.com/VP2020/images/il-vp-beauty-hotpink@3x.png',
};

export const getVPConfig = () => {
  const config = getVPDoc() || DEFAULT_CONFIG;

  return {
    attributed_title: get(config, 'attributed_title', DEFAULT_CONFIG.attributed_title),
    error_msg: get(config, 'error_msg', DEFAULT_CONFIG.error_msg),
    error_img_url: get(config, 'error_img_url', DEFAULT_CONFIG.error_img_url),
  };
};
