//libraries
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

//component
import Listing from 'shared/routes/Listing';
import NotFound from 'shared/components/NotFound';

//def
import { IListingProps } from 'shared/routes/Listing/types';

// redux store
import { updateStatusCode } from 'shared/store/app/actions';

// helpers
import { isValidUrl } from './helper';

function Search(props: IListingProps) {
  const { search } = useLocation();
  const dispatch = useDispatch();

  if (isValidUrl(search) === false) {
    dispatch(updateStatusCode(404));

    return <NotFound />;
  }

  return <Listing {...props} isSearch />;
}

export default Search;
