// libs
import { useSelector } from 'react-redux';
import get from 'lodash/get';

// defs
import { RemoteConfigState } from './types';

export const useRemoteConfig = <T extends keyof RemoteConfigState>(
  config: T,
  defaultValue?: RemoteConfigState[T]
): RemoteConfigState[T] =>
  useSelector((state: { remoteConfigs: RemoteConfigState }) =>
    get(state, `remoteConfigs.${config}`, defaultValue)
  );
