// helpers
import { getGlobalThis } from 'shared/helpers/utils';
import { log } from 'shared/helpers/newRelic';
import { getProcessedUrl, getRequestBody } from './helpers';
import {
  captureError,
  captureRequest,
  captureResponse,
  captureResponseError,
} from 'shared/helpers/logger';
import { isAPIBlocked } from 'shared/components/VisitorPriortization/helpers/csr';
import { getStoredUserFormKey } from './authFormKeyStorage';
import { fetchTimeout } from 'shared/helpers/apiTimeout';

// defs
import { IApiHelperOptions } from './types';

// constants
import { ERROR_TAGS, TAG_IDENTIFIER } from 'shared/helpers/SentryLogger/constants';
const DEFAULT_HEADERS = {};

/**
 *
 * A wrapper over fetch API, with some defaults
 */
export const ApiHelper = (
  srcUrl: string,
  {
    method = 'GET',
    mode = 'cors',
    cache = 'no-cache',
    credentials = __BROWSER__ ? 'same-origin' : undefined,
    headers = {},
    isBodyJSON = false,
    redirect,
    referrer,
    body,
    appendCSRFHeader = true,
  }: IApiHelperOptions = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: __BROWSER__ ? 'same-origin' : undefined,
    headers: {},
    isBodyJSON: false,
    appendCSRFHeader: true,
  },
  timeoutValue?: number
): Promise<Response> => {
  const { isBlocked, message } = isAPIBlocked(srcUrl);
  if (isBlocked) {
    return Promise.reject({
      statusCode: 503,
      statusText: 'VP_BLOCKED',
      message,
    });
  }

  const formKey = getStoredUserFormKey();
  const isPostRequest = method === 'POST';
  const { url, requestBody } = getProcessedUrl({ srcUrl, formBody: body, isBodyJSON, method });

  const reqHeaders = {
    ...DEFAULT_HEADERS,
    ...(isPostRequest && body ? { 'Content-Type': 'application/x-www-form-urlencoded' } : {}),
    ...(isBodyJSON ? { 'Content-Type': 'application/json' } : {}),
    ...(appendCSRFHeader && formKey !== '' ? { 'x-csrf-token': formKey } : {}),
    ...headers,
  };

  const fetchOptions = {
    method,
    mode,
    cache,
    credentials,
    redirect,
    referrer,
    body: requestBody,
    headers: reqHeaders,
  };

  const _globalThis = getGlobalThis() as Window;

  const fetch = _globalThis.fetch;

  const fetchCall = fetchTimeout(fetch, url, fetchOptions, timeoutValue);
  if (__SERVER__) {
    const requestParams = { url, ...fetchOptions };
    const requestId = captureRequest(requestParams);
    fetchCall
      .then((res: Response) => {
        if (res.status >= 400) {
          captureResponseError(res, requestId);
        } else {
          captureResponse(res, requestId);
        }
      })
      .catch((err: Error) => {
        // avoiding duplicate logs
        if (err.name !== 'AbortError') {
          captureError(err, requestId, url);
        }
      });
  } else {
    fetchCall
      .then((res: Response) => {
        if (res.status >= 400) {
          const { hostname, pathname } = new URL(url);
          log({
            errTitle: `Error in fetchCall ApiHelper: ${res.status}: ${url}`,
            tags: {
              [TAG_IDENTIFIER.ERROR]: ERROR_TAGS.API,
              [TAG_IDENTIFIER.STATUS_CODE]: String(res.status),
              [TAG_IDENTIFIER.HOSTNAME]: hostname,
              [TAG_IDENTIFIER.PATHNAME]: pathname,
            },
          });
        }
      })
      .catch((error: Error) => {
        // avoiding duplicate logs
        if (error.name !== 'AbortError') {
          log({
            errTitle: 'Error in fetchCall ApiHelper - res.status >= 400',
            error,
            tags: { [TAG_IDENTIFIER.ERROR]: ERROR_TAGS.API },
          });
        }
      });
  }

  return fetchCall;
};

export const ApiHelperForDealsPlatform = (
  url: string,
  method = 'GET',
  params?: { [key: string]: any } | FormData,
  headers?: { [key: string]: string }
) => {
  const { isBlocked, message } = isAPIBlocked(url);
  if (isBlocked) {
    return Promise.reject({
      status: 503,
      statusText: 'VP_BLOCKED',
      message,
    });
  }

  const isPostRequest = method === 'POST';

  const reqHeaders = {
    ...DEFAULT_HEADERS,
    ...(isPostRequest ? { 'Content-Type': 'application/x-www-form-urlencoded' } : {}),
    ...headers,
  };

  const fetchOptions = {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: __BROWSER__ ? 'same-origin' : undefined,
    headers: reqHeaders,
    body: getRequestBody(true, params),
  };

  const _globalThis = getGlobalThis() as Window;

  const fetch = _globalThis.fetch;

  const fetchCall = fetchTimeout(fetch, url, fetchOptions);
  if (__SERVER__) {
    const requestParams = { url, ...fetchOptions };
    const requestId = captureRequest(requestParams);
    fetchCall
      .then((res: Response) => {
        if (res.status >= 400) {
          captureResponseError(res, requestId);
        } else {
          captureResponse(res, requestId);
        }
      })
      .catch((err: Error) => {
        // avoiding duplicate logs
        if (err.name !== 'AbortError') {
          captureError(err, requestId, url);
        }
      });
  } else {
    fetchCall
      .then((res: Response) => {
        if (res.status >= 400) {
          const { hostname, pathname } = new URL(url);
          log({
            errTitle: 'Error in ApiHelperForDealsPlatform - fetchCall - res.status >= 400',
            message: `${res.status}: ${url}`,
            tags: {
              [TAG_IDENTIFIER.ERROR]: ERROR_TAGS.API,
              [TAG_IDENTIFIER.STATUS_CODE]: String(res.status),
              [TAG_IDENTIFIER.HOSTNAME]: hostname,
              [TAG_IDENTIFIER.PATHNAME]: pathname,
            },
          });
        }
      })
      .catch((error: Error) => {
        // avoiding duplicate logs
        if (error.name !== 'AbortError') {
          log({
            errTitle: 'Error in ApiHelperForDealsPlatform - fetchCall - catch',
            error,
            tags: { [TAG_IDENTIFIER.ERROR]: ERROR_TAGS.API },
          });
        }
      });
  }

  return fetchCall;
};
