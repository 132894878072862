// libs
import { styled } from '@nykaa/ui-components';

// constants
import { MWEB_HEADER_HEIGHT } from 'shared/styles/base';

export const Container = styled.div<{
  allowPadding: boolean;
}>`
  ${({ allowPadding }) => allowPadding && `padding-top: ${MWEB_HEADER_HEIGHT}px`};
`;
