import React from 'react';

import ExperimentContext from './ExperimentContext';

interface IExperimentProps {
  children: React.ReactNode;
  experimentId: string;
  placeholder: React.ReactNode;
  defaultVariant: string;
  googleOptimizeWaitTimeout: number;
}

interface IExperimentState {
  variant: null | string;
}

class Experiment extends React.PureComponent<IExperimentProps> {
  public static defaultProps = {
    googleOptimizeWaitTimeout: 3000,
  };

  public state = {
    variant: null,
  };

  private _isMounted = false;
  private _interval: NodeJS.Timeout | undefined;

  public componentDidMount() {
    this._isMounted = true;
    const { experimentId } = this.props;

    if (!experimentId) {
      throw new Error('Please specify the experiment id');
    }

    const startTime = new Date().getTime();
    this._interval = setInterval(() => {
      // @ts-ignore
      if (window.google_optimize !== undefined) {
        // @ts-ignore
        const value = window.google_optimize.get(this.props.experimentId);
        this.updateVariant(value);
        if (this._interval !== undefined) {
          window.clearInterval(this._interval);
        }
      } else {
        const currentTime = new Date().getTime();

        // if wait for googleOptimizeWait exhausted
        if (currentTime - startTime >= this.props.googleOptimizeWaitTimeout) {
          if (this._interval !== undefined) {
            window.clearInterval(this._interval);
          }
          // gtag not found show default
          this.updateVariant();
        }
      }
    }, 100);
  }

  public componentWillUnmount() {
    this._isMounted = false;
    if (this._interval !== undefined) {
      window.clearInterval(this._interval);
    }
  }

  public render() {
    const { variant } = this.state;
    const { placeholder, children } = this.props;

    return (
      <ExperimentContext.Provider value={{ variant }}>
        {variant === null ? placeholder : children}
      </ExperimentContext.Provider>
    );
  }

  private updateVariant = (value?: string) => {
    const { variant: currentVariant } = this.state;

    // wont update variant if we have already updated to not null value
    if (currentVariant !== null) {
      return;
    }

    const { defaultVariant } = this.props;
    const variant = value === undefined || value === null ? defaultVariant : value;

    this._setState({
      variant,
    });
  };

  private _setState = (state: Partial<IExperimentState>, cb?: VoidFunction) => {
    if (this._isMounted) {
      this.setState(state as IExperimentState, cb);
    }
  };
}

export default Experiment;
