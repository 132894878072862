// libs
import { styled } from '@nykaa/ui-components';

// components
import Button from 'shared/components/Button';
import BaseOverlaySheet from 'shared/components/OverlaySheet';

// constants
import { BREAKPOINTS } from 'shared/styles/base';

// styles
export const Actions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: center;
`;

export const Info = styled.p`
  ${({ theme }) => theme.typography.bodyLarge};
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: ${({ theme }) => `0 0 ${theme.spacing.spacing100} 0`};
`;

export const Heading = styled.h3`
  ${({ theme }) => theme.typography.titleLarge};
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: ${({ theme }) => `0 0 ${theme.spacing.spacing40} 0`};
`;

export const SubmitButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing.spacing40};
  white-space: nowrap;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing160}`};
`;

export const LaterButton = styled(Button)`
  background: transparent;
  border: solid 1px ${({ theme }) => theme.colors.textOutline};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const OverlaySheet = styled(BaseOverlaySheet)`
  height: auto;
  width: 100%;
  background-color: white;
  top: initial;
  padding: ${({ theme }) =>
    `${theme.spacing.spacing160} ${theme.spacing.spacing100} ${theme.spacing.spacing100} ${theme.spacing.spacing100}`};
  border-radius: ${({ theme }) => `${theme.borders.radius40} ${theme.borders.radius40} 0 0`};
  box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 0.16); // overlay tokens

  @media screen and (min-width: ${BREAKPOINTS.MOBILE}px) {
    width: 360px;
    left: calc(100% - 360px);
  }
`;
