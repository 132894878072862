// libs
import React, { memo } from 'react';
import { useHistory, useLocation } from 'react-router';

// component
import OtpFlow from 'shared/layouts/OTPVerification/Mobile';
import Logo from 'shared/components/Logo';
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer/RouteChunkRenderer';

// Helpers
import { actionsForMagento } from './helpers';
import { Header, InnerContainer, OuterContainer } from './styles';

//constants
import { ROUTES, urls } from 'shared/api/urls';
import { LOGO_TYPE } from 'shared/constants';

const containDomainName = (text: string) => /(https:\/\/|http:\/\/|www\.)\S+/i.test(text);

interface IMobileAuthFlowProps {
  isEnabledForMobile: boolean;
  isEnabledForDesktop: boolean;
  isUserLoggedIn: boolean;
  isIndianUser: boolean;
  isUserDataFetching: boolean;
}

function MobileAuthFlow({ isUserLoggedIn, isUserDataFetching }: IMobileAuthFlowProps) {
  const { search } = useLocation();
  const history = useHistory();

  const URLParams = new URLSearchParams(search);
  const redirectionURL = (URLParams.get('redirectURL') || '/').replace('@', '?');
  const onCancel = URLParams.get('onCancel');
  const router = URLParams.get('route');
  const isLegacyCheckoutFlow = router === 'checkout';
  const isLegacyDiscoveryFlow = router === 'discovery';
  const comingViaLegacyCode = isLegacyCheckoutFlow || isLegacyDiscoveryFlow;

  const onHide = (isCancelled = false) => {
    const statusQS = new URLSearchParams();
    const actionStatus = isCancelled ? 'cancelled' : 'success';
    statusQS.append('actionStatus', actionStatus);
    const URL = `${redirectionURL}${
      redirectionURL.indexOf('?') < 0 ? '?' : '&'
    }${statusQS.toString()}`;

    if (comingViaLegacyCode) {
      actionsForMagento(isCancelled, URL);
      return;
    }

    if (URL.includes(window.location.host)) {
      // hack to support my orders back
      if (isCancelled && onCancel === 'back') {
        window.history.back();
        return;
      }
      window.location.replace(URL);
      return;
    }

    if (containDomainName(URL)) {
      history.replace('/');
      return;
    }

    history.replace(URL);
  };

  return (
    <RouteChunkRenderer
      mobileComponent={
        <OtpFlow
          hide={onHide}
          isUserLoggedIn={isUserLoggedIn}
          isCheckoutFlow={isLegacyCheckoutFlow}
          isUserDataFetching={isUserDataFetching}
        />
      }
      desktopComponent={
        <OuterContainer>
          <Header>
            <a href={urls[ROUTES.HOME]} aria-label="Logo">
              <Logo type={LOGO_TYPE.DWEB} />
            </a>
          </Header>
          <InnerContainer>
            <OtpFlow
              hide={onHide}
              isUserLoggedIn={isUserLoggedIn}
              isCheckoutFlow={isLegacyCheckoutFlow}
              isUserDataFetching={isUserDataFetching}
            />
          </InnerContainer>
        </OuterContainer>
      }
    />
  );
}

export default memo(MobileAuthFlow);
