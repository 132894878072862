export const IMPRESSION = 'impression';

export const EVENT_TYPES = { CLICK: 'click' };

export const EVENT_NAMES = {
  BANNER_CLICK: 'banner_click',
  BANNER_IMPRESSION: 'banner_impression',
  PRODUCT_IMPRESSION: 'product_impression',
  ADD_TO_WISHLIST: 'product_add_to_wishlist',
  REMOVE_FROM_WISHLIST: 'product_remove_from_wishlist',
  ADD_TO_CART: 'product_add_to_cart',
  NOTIFY_ME: 'product_notify_me',
  PRODUCT_CLICKED: 'product_card_clicked',
  WIDGET_BUTTON_IMPRESSION: 'widget_button_impression',
  WIDGET_BUTTON_CLICK: 'widget_button_clicked',
};

export enum IMPRESSION_TYPE {
  PRODUCT = 'PRODUCT',
  BANNER = 'BANNER',
}
