import { AUTH_URI } from 'shared/constants/uri';
import get from 'lodash/get';

// constants
import {
  QUERY_STRING_AUTH_INITIATER,
  QUERY_STRING_AUTH_VALUE,
} from 'shared/components/AuthModal/constants';

interface IGetRedirectionURLForSamePageProps {
  url: string;
  search: string;
  pathname: string;
}

interface IGetRedirectionURLWithRefererProps {
  url: string;
  redirectUrl: string;
}

interface IUserDetails {
  user_response: {
    status: string;
    response: {
      user: {
        userId: string;
        mobile: string;
        newUser: number;
      };
    };
  };
}

export const getRedirectionURLWithCurrentPage = ({
  url,
  search,
  pathname,
}: IGetRedirectionURLForSamePageProps) => {
  const params = new URLSearchParams();

  // remove ptype=auth is previous url already has a url
  // else code keeps going in the loop
  const existingParams = new URLSearchParams(search);
  if (existingParams.get(QUERY_STRING_AUTH_INITIATER) === QUERY_STRING_AUTH_VALUE) {
    existingParams.delete(QUERY_STRING_AUTH_INITIATER);
  }

  let redirectUrl = pathname;
  if (existingParams.toString().length) {
    redirectUrl = redirectUrl + '?';
  }
  redirectUrl = redirectUrl + existingParams.toString().replace('?', '@');
  params.set('redirectURL', redirectUrl);
  return AUTH_URI.PATH + url + '&' + params.toString();
};

export const getRedirectionURLWithReferer = ({
  url,
  redirectUrl,
}: IGetRedirectionURLWithRefererProps) => {
  const params = new URLSearchParams();

  params.set('redirectURL', redirectUrl);

  return AUTH_URI.PATH + url + '&' + params.toString();
};

export const getUserDetailsToStore = (res: IUserDetails) => {
  const userDetails = get(res, 'response.user', {});
  let userDetailsToStore = {};

  // remove the empty values before sending it to store.
  Object.keys(userDetails).forEach((key: string) => {
    if (userDetails[key] !== null) {
      userDetailsToStore[key] = userDetails[key];
    }
  });

  return userDetailsToStore;
};
