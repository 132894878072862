// libs
import { connect } from 'react-redux';

// components
import MobileAuthFlow from './MobileAuthFlow';

// helpers
import { getOtpConfig } from './helpers';

// constants
import { DEFAULT_COUNTRY } from 'shared/constants';

// store
import { isLoggedIn, getBaseCountry, isUserFetching } from 'shared/store/app/selectors';

const mapStateToProps = (state: any) => {
  const { isEnabledForMobile, isEnabledForDesktop } = getOtpConfig();
  const isUserLoggedIn = isLoggedIn(state);
  const isIndianUser = getBaseCountry(state) === DEFAULT_COUNTRY;
  const isUserDataFetching = isUserFetching(state);

  return {
    isEnabledForMobile,
    isEnabledForDesktop,
    isIndianUser,
    isUserLoggedIn,
    isUserDataFetching,
  };
};

export default connect(mapStateToProps)(MobileAuthFlow);
