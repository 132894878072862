// libs
import React, { memo, useEffect, useState } from 'react';
import { useLocation, Switch } from 'react-router';
import { Route } from 'react-router-dom';

// global components
import AppLayoutMobile from 'shared/layouts/AppLayout/Mobile';
import AppLayoutDesktop from 'shared/layouts/AppLayout/Desktop';
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import BottomNav from 'shared/components/BottomNavV2';

import { routesWithAppLayout, routesWithoutAppLayout } from './routeConfig';

import customRouteProp from './CustomRouteProp';

function ScrollManager() {
  const [currentPath, setCurrentPath] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Scroll to window top if location path changed
    if (location.pathname !== currentPath) {
      window.scrollTo(0, 0);
      setCurrentPath(location.pathname);
    }
  }, [location, currentPath]);

  return null;
}

function RouteWithSubRoutes(route: any) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={customRouteProp(route.component, route.routes)}
    />
  );
}

function BaseRouteLayout() {
  const RoutesWithoutLayout = routesWithoutAppLayout.map((route, i: number) => (
    <RouteWithSubRoutes key={i} {...route} />
  ));

  const RoutesWithAppLayout = routesWithAppLayout.map((route, i: number) => (
    <RouteWithSubRoutes key={i} {...route} />
  ));

  return (
    <>
      <RouteChunkRenderer
        mobileComponent={
          <>
            <Switch>
              {RoutesWithoutLayout}
              <AppLayoutMobile>
                <Switch>{RoutesWithAppLayout}</Switch>
              </AppLayoutMobile>
            </Switch>
            <BottomNav />
          </>
        }
        desktopComponent={
          <Switch>
            {RoutesWithoutLayout}
            <AppLayoutDesktop>
              <Switch>{RoutesWithAppLayout}</Switch>
            </AppLayoutDesktop>
          </Switch>
        }
      />
      <ScrollManager />
    </>
  );
}

export default memo(BaseRouteLayout);
