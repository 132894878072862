// libs
import React, { memo } from 'react';

// component
import Gallery from 'shared/layouts/ReviewImageGallery';
import ChunkFallback from 'shared/components/ChunkFallback';

function ReviewImageGallery() {
  return <Gallery fallback={<ChunkFallback />} />;
}

export default memo(ReviewImageGallery);
