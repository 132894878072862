// libs
import { createSelector } from 'reselect';

// types
import { DynamicPageState } from './types';

export const dynamicPage = (state: { dynamicpage: DynamicPageState }): DynamicPageState =>
  state.dynamicPage;

export const getDynamicPageData = createSelector(
  [dynamicPage],
  (dynamicPageData) => dynamicPageData?.dynamicPageData || {}
);

export const getDynamicPageTitle = createSelector(
  [dynamicPage],
  (dynamicPageData) => dynamicPageData?.dynamicPageData?.h1Tag || ''
);

export const getIsDynamicPageDataFetching = createSelector(
  [dynamicPage],
  (dynamicPageData) => dynamicPageData?.isDynamicPageDataFetching
);

export const getIsDynamicPageDataFetched = createSelector(
  [dynamicPage],
  (dynamicPageData) => dynamicPageData?.isDynamicPageDataFetched
);

export const getSeoFooterContent = createSelector(
  [dynamicPage],
  (dynamicPageData) => dynamicPageData?.dynamicPageData?.footerContent || ''
);
