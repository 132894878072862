// libs
import React, { memo } from 'react';
import Helmet from 'react-helmet';

// global components
import Boot from 'shared/components/Boot';
import VisitorPriortization from 'shared/components/VisitorPriortization';
import UserData from 'shared/components/UserData';

// Routes
import Routes from 'shared/routes';

// styles
import BaseStyles from './styles/BaseStyles';

// components
import { ResponsiveObserver } from 'shared/components/ResponsiveLoader';

// hooks
import useAppHistory from 'shared/hooks/useAppHistory';

// context
import AppProvider from './AppProvider';
import DomainConfigProvider from 'shared/domainConfig/context/Provider';
import ThemeProvider from 'shared/styles/theme/Provider';

// constants
import { DEFAULT_TITLE } from 'shared/constants';

//helpers
import { getDomainConfig } from 'shared/domainConfig/helpers';

function App({ deviceWidth }: { deviceWidth: number }) {
  useAppHistory();
  const { FAVICON, JSON_LD_ORG, JSON_LD_SEARCH } = getDomainConfig();

  return (
    <DomainConfigProvider>
      <ThemeProvider>
        <AppProvider>
          <ResponsiveObserver deviceWidth={deviceWidth}>
            <UserData>
              <BaseStyles />
              <Helmet
                defaultTitle={DEFAULT_TITLE}
                titleTemplate="%s"
                link={[
                  {
                    rel: 'icon',
                    type: 'image/png',
                    href: FAVICON,
                  },
                ]}
                script={[
                  {
                    type: 'application/ld+json',
                    innerHTML: JSON_LD_ORG,
                  },
                  {
                    type: 'application/ld+json',
                    innerHTML: JSON_LD_SEARCH,
                  },
                ]}
              />
              <Boot />
              <VisitorPriortization />
              <Routes />
            </UserData>
          </ResponsiveObserver>
        </AppProvider>
      </ThemeProvider>
    </DomainConfigProvider>
  );
}

export default memo(App);
