// libs
import { memo, useEffect, useState } from 'react';

// context and constants
import { useToastContext, TOASTER_TYPES } from 'shared/components/Toast';
import { getDomainConfig } from 'shared/domainConfig/helpers';

interface ISucceedLoginToastProps {
  shouldShowToast: boolean;
  afterHide: VoidFunction;
}

function SuccessLoginToast({ shouldShowToast, afterHide }: ISucceedLoginToastProps) {
  const { showToast } = useToastContext();
  const { LOGIN_TOAST_MESSAGE } = getDomainConfig();

  const [show, setShow] = useState(false);
  useEffect(() => {
    // Logic to display only one time
    if (show === false && shouldShowToast === true) {
      showToast({
        type: TOASTER_TYPES.SUCCESS,
        message: LOGIN_TOAST_MESSAGE.DWEB,
        afterHide,
        hideTime: 3000,
      });
      setShow(true);
    }
  }, [afterHide, shouldShowToast, show, showToast, LOGIN_TOAST_MESSAGE]);

  return null;
}

export default memo(SuccessLoginToast);
