import { ApiHelper } from 'shared/helpers/api';
import { getUrl, ROUTES } from 'shared/api/urls';
import { transformData } from './transformer';
import { getDomainConfig } from 'shared/domainConfig/helpers';

export const getMegaMenuV2 = (): Promise<any> => {
  const { MEGA_MENU_VERSION } = getDomainConfig();
  const isV4 = MEGA_MENU_VERSION === 'V4';
  const urlVersion = isV4 ? ROUTES.MEGA_MENU_V4 : ROUTES.MEGA_MENU_V2;
  const url = getUrl(urlVersion);
  const categoryUrl = isV4 ? url + `?domain=${process.env.DOMAIN_NAME}` : url;

  return ApiHelper(`${categoryUrl}`).then(async (response: Response) => {
    try {
      const data = await response.json();
      if (response.ok === false) {
        return Promise.reject({ status: response.status, data });
      }

      return data;
    } catch (e) {
      return Promise.reject({ status: 500 });
    }
  });
};

export const getMenuContent = async (): Promise<any> => {
  const data = await getMegaMenuV2();
  return transformData(data);
};
