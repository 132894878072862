const STORAGE_PREFIX = 'scrl';

const getStorageKey = (pathname: string) => `${STORAGE_PREFIX}:${pathname}`;

export const storeScrollY = (pathname: string, scrollY: number) => {
  if (window.sessionStorage === undefined) {
    return;
  }

  window.sessionStorage.setItem(getStorageKey(pathname), String(scrollY));
};

export const getStoredScrollY = (pathname: string) => {
  if (window.sessionStorage === undefined) {
    return null;
  }

  return window.sessionStorage.getItem(getStorageKey(pathname));
};
