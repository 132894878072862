import { styled } from '@nykaa/ui-components';

export const Container = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: 80px;

  .brokenLink {
    ${({ theme }) => theme.typography.type360};
    margin-bottom: ${({ theme }) => theme.spacing.spacing50};
  }

  .msg404 {
    margin: 0 0 36px 0;
    ${({ theme }) => theme.typography.type362};
  }

  .brokenmsg {
    ${({ theme }) => theme.typography.titleMedium};
    width: 231px;
    display: inline-block;
  }

  .corruptmsg {
    margin-top: ${({ theme }) => theme.spacing.spacing240};
    margin-bottom: ${({ theme }) => theme.spacing.spacing240};
    ${({ theme }) => theme.typography.bodyLarge};
  }

  .goBack {
    padding: ${({ theme }) => `${theme.spacing.spacing60} ${theme.spacing.spacing160}`};
    background-color: #000;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    color: #ffffff;
    ${({ theme }) => theme.typography.subTitleLarge};
    border: 1px solid #000;
    border-radius: ${({ theme }) => theme.borders.radius10};
    cursor: pointer;
    text-decoration: none;
  }
`;
