// libs
import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import { getUserData as getUserDataUtil, setUserData as setUserDataUtil } from './helpers';
import { removeEmailInSessionStorage } from 'shared/components/Notify/helpers/emailHelpers';

// Provider
import UserDataContext from './context';

// defs
import { UserDataValues } from './types';
import { removeOMSPlatformSessionIdentifiers } from 'shared/components/AuthModal/helpers';

interface UserDataProps {
  children: React.ReactNode;
}

function UserData({ children }: UserDataProps) {
  const [userData, setUserDataState] = useState(getUserDataUtil());

  /**
   * @description - Store user specific data in local storage, dont store
   * application or any other data as it will be cleared on user logout
   * @param {string} key - identifier for the value to be stored in local storage
   * @param {UserDataValues} value - value to be stored in local storage
   */
  const setUserData = useCallback((key: string, value: UserDataValues) => {
    setUserDataState((existingUserData) => {
      const newData = {
        ...existingUserData,
        [key]: value,
      };
      // update local storage and in memory
      setUserDataUtil(newData);
      // update the react state
      return newData;
    });
  }, []);

  const clearUserData = useCallback(() => {
    // clear my orders related session information
    removeOMSPlatformSessionIdentifiers();
    // clear localstorage and in memory
    setUserDataUtil({});
    // update the react state
    setUserDataState({});
    removeEmailInSessionStorage(); //clearing email id in session storage on logout
  }, []);

  const getUserData = useCallback(
    (key: string, value?: UserDataValues) => {
      if (userData[key]) {
        return userData[key];
      }

      if (value !== undefined) {
        value;
      }

      return null;
    },
    [userData]
  );

  const value = useMemo(
    () => ({
      getUserData,
      setUserData,
      clearUserData,
    }),
    [getUserData, setUserData, clearUserData]
  );

  return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>;
}

export default memo(UserData);
