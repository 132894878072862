// libs
import { createContext, useContext } from 'react';

interface IDwebCartContext {
  showCart: VoidFunction;
  canShowCart: boolean;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

const initialValue: IDwebCartContext = {
  showCart: EMPTY_FUNC,
  canShowCart: false,
};

const dwebCartContext = createContext(initialValue);

export const useDwebCartContext = () => useContext(dwebCartContext);

export default dwebCartContext;
