// NOTE - Use only to cache Priority APIs

interface ICacheProps {
  [key: string]: { expiry: number; response: string };
}

let cacheList: ICacheProps = {};

const MemoizeAPIWithTTL = <T>({
  APIFunction,
  ttl,
  cacheHash,
}: {
  APIFunction: (...args: any[]) => Promise<T>;
  ttl: number;
  cacheHash: string;
}) => {
  return (...args: any[]) => {
    if (cacheHash in cacheList) {
      const { expiry, response } = cacheList[cacheHash];

      if (expiry > new Date().getTime()) {
        // JSON parse - convert back the response as new Object / string / number
        // to avoid mutation

        return Promise.resolve(JSON.parse(response));
      }
    }

    return APIFunction(...args).then((response) => {
      const expiry = new Date().getTime() + ttl;
      const data = {
        expiry,
        // JSON stringify - for cheap deep cloning
        // to avoid mutation
        response: JSON.stringify(response),
      };

      cacheList[cacheHash] = data;

      return response;
    });
  };
};

export default MemoizeAPIWithTTL;

const clearAll = () => {
  const oldResponse = { ...cacheList };

  Object.keys(cacheList).map((cacheItem) => {
    delete cacheList[cacheItem];
  });

  return oldResponse;
};

export { clearAll };
