//libs
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';

//components
import BottomSheetAuth from 'shared/components/BottomSheetOTPVerification/Mobile';
import TruecallerLogin from 'shared/components/TruecallerLogin';

//helpers
import { getExperimentVariant } from '@nykaa/experiment-sdk/client';
import { isAndroid } from './helper';

//selectors
import { isIncognitoMode } from 'shared/store/app/selectors';

//defs
interface LoginBottomSheetWrapperProps {
  hide: () => void;
  isUserDataFetching: boolean;
  isUserLoggedIn: boolean;
  redirectUrl?: string;
  showTruecaller?: boolean;
  onSubmitCb?: VoidFunction;
  onCancelCb?: VoidFunction;
  onProceedCb?: VoidFunction;
  onVisibleCb?: VoidFunction;
  isLoginForced?: boolean;
  isNupiEnabled: boolean;
}

function LoginBottomSheetWrapper({
  hide,
  isUserLoggedIn,
  isUserDataFetching,
  redirectUrl,
  showTruecaller = true,
  onSubmitCb,
  onProceedCb,
  onCancelCb,
  onVisibleCb,
  isLoginForced = false,
  isNupiEnabled,
}: LoginBottomSheetWrapperProps) {
  const { isEligible: isTruecallerEligible } = getExperimentVariant('truecaller-login');
  const [showTruecallerBottomsheet, setShowTruecallerBottomsheet] = useState(true);
  const isIncognito = useSelector(isIncognitoMode);

  const canShowTruecaller =
    isTruecallerEligible &&
    isAndroid() &&
    showTruecallerBottomsheet &&
    showTruecaller &&
    !isIncognito;

  if (canShowTruecaller)
    return (
      <TruecallerLogin
        showFallback={() => setShowTruecallerBottomsheet(false)}
        hide={hide}
        redirectUrl={redirectUrl}
        onBottomSheetVisible={onVisibleCb}
        isUserLoggedIn={isUserLoggedIn}
      />
    );

  return (
    <BottomSheetAuth
      hide={hide}
      isUserDataFetching={isUserDataFetching}
      isUserLoggedIn={isUserLoggedIn}
      redirectUrl={redirectUrl}
      onSubmitCb={onSubmitCb}
      onCancelCb={onCancelCb}
      onProceedCb={onProceedCb}
      isLoginForced={isLoginForced}
      isNupiEnabled={isNupiEnabled}
    />
  );
}

export default memo(LoginBottomSheetWrapper);
