// libs
import React, { memo, useState, useEffect } from 'react';

// styles
import { ToastContainer, Figure, Info } from './styles';

// icon
import ErrorIcon from '@nykaa/ui-components/Icons/alert-negative-filled';
import SuccessIcon from '@nykaa/ui-components/Icons/alert-positive-filled';

// components
import useTheme from '@nykaa/ui-components/themes/hooks/useTheme';

// types
import { Theme } from '@nykaa/ui-components/themes/types';

// types
import { ToastConfig } from 'shared/components/Toast/types';

// analytics
import { trackInlineErrorMessage } from 'shared/helpers/analytics/globalEvents';

// constants
import { TOASTER_TYPES, ALIGNMENT_TYPES } from 'shared/components/Toast/constants';
const ICON_SIZE = '20px';

// defs
interface Props
  extends Pick<
    ToastConfig,
    'textAlign' | 'type' | 'message' | 'hideTime' | 'hideIcon' | 'styles' | 'statusCode'
  > {
  onHide: VoidFunction;
}

const getIcon = (type: TOASTER_TYPES) => {
  switch (type) {
    case TOASTER_TYPES.WARNING:
      return <ErrorIcon size={ICON_SIZE} />;
    case TOASTER_TYPES.ERROR:
      return <ErrorIcon size={ICON_SIZE} />;
    case TOASTER_TYPES.SUCCESS:
      return <SuccessIcon size={ICON_SIZE} />;
    default:
      return <SuccessIcon size={ICON_SIZE} />;
  }
};

function Toast({
  type,
  message,
  onHide,
  textAlign = ALIGNMENT_TYPES.CENTER,
  hideTime = 3000,
  hideIcon = false,
  styles = {},
  statusCode = '',
}: Props) {
  const [hide, setHide] = useState(false);

  const theme: Theme = useTheme();

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setHide(true);
    }, hideTime);

    if (type === TOASTER_TYPES.ERROR) {
      trackInlineErrorMessage({ message, statusCode });
    }

    return () => {
      window.clearTimeout(timer);
    };
  }, [hideTime, message, statusCode, type]);

  const onAnimationEnd = () => {
    hide && onHide();
  };

  const getColorType = (toastType: TOASTER_TYPES) => {
    switch (toastType) {
      case TOASTER_TYPES.WARNING:
        return theme?.colors?.warning;
      case TOASTER_TYPES.ERROR:
        return theme?.colors?.negative;
      case TOASTER_TYPES.SUCCESS:
        return theme?.colors?.positive;
      default:
        return theme?.colors?.positive;
    }
  };

  return (
    <ToastContainer
      hide={hide}
      data-test="toast-info"
      onAnimationEnd={onAnimationEnd}
      textAlign={textAlign}
      style={styles}
    >
      {hideIcon === false && <Figure fillColor={getColorType(type)}>{getIcon(type)}</Figure>}
      <Info data-test="toast-message">{message}</Info>
    </ToastContainer>
  );
}

export default memo(Toast);
