// helpers
import NFA from 'shared/helpers/analytics/NFAnalytics';

// constants
import { GLOBAL_EVENTS } from 'shared/helpers/analytics/constants';

export const sendPageView = () =>
  NFA.track({
    event: GLOBAL_EVENTS.GENERIC_PAGE_VIEW,
  });
