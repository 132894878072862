// libs
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { detectIncognito } from 'detectincognitojs';

//actions
import { setIncognitoMode } from 'shared/store/app/actions';

const DetectIncognito = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    detectIncognito()
      .then((result) => {
        if (result.isPrivate) dispatch(setIncognitoMode(true));
      })
      .catch(() => {});
  }, [dispatch]);

  return null;
};

export default memo(DetectIncognito);
