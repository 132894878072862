// libs
import React, { memo } from 'react';
import { useLocation } from 'react-router';

// hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// constants
import { IOS_APP_ID } from 'shared/constants';
import Helmet from 'react-helmet';

const ARGUMENT_REFERENCE = 'smart_app_banner';

function SmartAppBanner() {
  const { pathname, search } = useLocation();
  const { enabledUrl, enabledForAll } = useRemoteConfig('smartAppBanner');

  if (enabledForAll || enabledUrl.indexOf(pathname) > -1) {
    const searchParam = new URLSearchParams(search);
    searchParam.set('src', ARGUMENT_REFERENCE);

    const url = process.env.API_HOST + pathname + '?' + searchParam.toString();

    return (
      <Helmet>
        <meta name="apple-itunes-app" content={`app-id=${IOS_APP_ID}, app-argument=${url}`}></meta>
      </Helmet>
    );
  }
  return null;
}

export default memo(SmartAppBanner);
