export const VIEW_TYPES = {
  LIST: 'list',
  CARD: 'card',
  BRANDS: 'brands',
  CATEGORY: 'category',
};

export const MM_CATEGORIES = {
  MASTER_CONTENT_ID: 'master',
  MASTER_DATA_ID: '007',
  MASTER_DATA_NAME: 'home',
  BRANDS: 'brands',
  SUPER_CATEGORY: 'super category',
  ALL_BRANDS: 'all brands',
  HOME: 'home',
};

export interface IBrandItem {
  id: number;
  device: string;
  name: string;
  image: '';
  image_url: string;
  view_type: string;
  action_url: string;
  type: string;
  position: number;
  filter_data?: string;
  sort?: string;
  brand_badge?: {
    title: string;
    imageUrl: string;
  };
  channel?: string;
  level: string;
  title?: string;
  isCuration?: string;
}

export interface IBrandCategory {
  id: number;
  image: string;
  level: number;
  name: string;
  parent_id: number;
  position: number;
  children_data: IBrandItem[];
  view_type?: string;
  action_url: string;
  filter_data?: string;
  sort?: string;
}

export interface IBrandsData {
  view_type: string;
  brandsData: IBrandCategory[];
}

export interface ICategoryData {
  children_data: ICategoryData[];
  device?: string;
  id: number | string;
  image: string;
  image_url?: string;
  aspect_ratio?: number;
  type?: string;
  level: number;
  name: string;
  parent_id?: number;
  subTitle: string;
  position: number;
  view_type?: string;
  action_url: string;
  column_count?: number;
}

export type IContentDataType = ICategoryData[] | IBrandsData;
export interface ICategory {
  action_url: string;
  id: number | string;
  image: string;
  image_url: string;
  name: string;
  subTitle: string;
  'sub-title': string;
  children_data: IContentDataType;
  position: number;
}

export const DEVICE_IDENTIFIERS = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};
