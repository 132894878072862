import React, { memo } from 'react';
import { Global, css } from '@emotion/core';

import IconEOT from './assets/icons/icomoon.eot';
import IconTTF from './assets/icons/icomoon.ttf';
import IconWOFF from './assets/icons/icomoon.woff';

export const ICON_VARIABLES = {
  'icon-tick': '\\e902',
  'icon-broken-link': '\\e926',
};

const iconStyles = css`
  @font-face {
    font-family: 'icomoon';
    src: url('${IconWOFF}?jpvbyr') format('woff'), url('${IconTTF}?jpvbyr') format('truetype'),
      url('${IconEOT}?jpvbyr#iefix') format('embedded-opentype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon-tick:before {
    content: '${ICON_VARIABLES['icon-tick']}';
  }
  .icon-broken-link:before {
    content: '${ICON_VARIABLES['icon-broken-link']}';
  }
`;

function Icons() {
  return <Global styles={iconStyles} />;
}

export default memo(Icons);
