// libs
import { createContext, useContext, CSSProperties } from 'react';

// defs
interface IMobileLayoutContext {
  setHeaderContent: (El?: React.ReactNode) => void;
  setHeaderActions: (El?: React.ReactNode) => void;
  setCSS: (css: CSSProperties) => void;
  resetCSS: VoidFunction;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

const initialValue: IMobileLayoutContext = {
  setHeaderContent: EMPTY_FUNC,
  setHeaderActions: EMPTY_FUNC,
  setCSS: EMPTY_FUNC,
  resetCSS: EMPTY_FUNC,
};

const mobileLayoutContext = createContext(initialValue);

export const useMobileLayoutContext = () => useContext(mobileLayoutContext);

export default mobileLayoutContext;
