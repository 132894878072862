import isEmpty from 'lodash/isEmpty';
import Container from './Container';
import { connect } from 'react-redux';
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';
import {
  getCustomerFirstName,
  getCustomerEmail,
  getCustomerMobileNumber,
  isCustomerFromIndia,
} from 'shared/store/app/selectors';

const mapStateToProps = (state: any) => {
  let otp_flow = getRemoteConfig(state, 'otpFlow');

  const email = getCustomerEmail(state);
  const mobile = getCustomerMobileNumber(state);

  const isValid = !isEmpty(email) && isEmpty(mobile) && isCustomerFromIndia(state);

  return {
    ...otp_flow.nudge,
    isValid,
    firstName: getCustomerFirstName(state),
    isEnabledForMobile: otp_flow.isEnabledForMobile,
    isEnabledForDesktop: otp_flow.isEnabledForDesktop,
  };
};

export default connect(mapStateToProps)(Container);
