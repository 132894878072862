// libs
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

// component
import DesktopAuth from './components/AuthWithOTP/Desktop';
import MobileVerificationNudge from 'shared/components/OTPVerification/Nudge';
import LoginBottomSheetWrapper from 'shared/components/LoginBottomSheetWrapper';

// selectors
import {
  isCustomerFromIndia,
  isLoggedIn as _isLoggedIn,
  isUserFetching as _isUserFetching,
} from 'shared/store/app/selectors';
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';

// contexts
import AuthModalWrapperContext from './AuthModalWrapperContext';
import { useResponsiveContext } from 'shared/components/ResponsiveLoader/ResponsiveContext';

// helpers
import {
  getAuthRedirectUrl,
  getOTPFlowStatus,
  hasSocialLoginError,
  isShowAuthRequested,
  removeOMSPlatformSessionIdentifiers,
} from './helpers';
import {
  getRedirectionURLWithCurrentPage,
  getRedirectionURLWithReferer,
} from 'shared/components/OTPVerification/helpers';

// constants
import { BREAKPOINTS } from 'shared/styles/base';
import { AUTH_URI } from 'shared/constants/uri';
import { NUPI_CONFIG } from 'shared/constants/auth';
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// defs
interface IAuthModalWrapperProps {
  children: React.ReactNode;
  isLoggedIn: boolean;
  isUserFetching: boolean;
  isIndianUser: boolean;
  isOTPFlowEnabledForMobile: boolean;
  isOTPFlowEnabledForDesktop: boolean;
  isBottomSheetLoginEnabled: boolean;
}

function AuthModalWrapper({
  children,
  isLoggedIn,
  isUserFetching,
  isIndianUser,
  isOTPFlowEnabledForMobile,
  isOTPFlowEnabledForDesktop,
  isBottomSheetLoginEnabled = false,
}: IAuthModalWrapperProps) {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const [loginInitiator] = useState(search);
  const [canShowAuthModal, setShowAuthModal] = useState(false);
  const [shouldShowBottomSheetAuth, setShowBottomSheetAuth] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();
  const [showTruecaller, setShowTruecaller] = useState(true);
  const { deviceWidth } = useResponsiveContext();
  const isDesktop = deviceWidth > BREAKPOINTS.DESKTOP;
  const isInternationalEnabledConfig = useRemoteConfig('isInternationalOTPFlowEnabled');
  const { disabled: isEmailLoginDisabled } = useRemoteConfig('emailLogin');
  const { enabled: isNupiEnabled } = useRemoteConfig(NUPI_CONFIG);

  const [bottomSheetCallbacks, setBottomSheetCallbacks] = useState<{ [key: string]: VoidFunction }>(
    {}
  );

  const canShowOTPFlow = useMemo(() => {
    const isInternationalOTPFlowEnabled = isDesktop
      ? isInternationalEnabledConfig.desktop
      : isInternationalEnabledConfig.mobile;
    if (isInternationalOTPFlowEnabled) return true;

    return (isDesktop ? isOTPFlowEnabledForDesktop : isOTPFlowEnabledForMobile) && isIndianUser;
  }, [
    isDesktop,
    isIndianUser,
    isInternationalEnabledConfig,
    isOTPFlowEnabledForDesktop,
    isOTPFlowEnabledForMobile,
  ]);

  const canShowBottomSheetAuth = isDesktop === false;

  useEffect(() => {
    // wait till we fetch user api response
    if (isUserFetching) {
      return;
    }

    if (isLoggedIn === false && (isShowAuthRequested(loginInitiator) || hasSocialLoginError())) {
      removeOMSPlatformSessionIdentifiers();
      setShowAuthModal(true);
    }

    if (isLoggedIn === false && isShowAuthRequested(loginInitiator)) {
      removeOMSPlatformSessionIdentifiers();
      setRedirectUrl(getAuthRedirectUrl());
    }
  }, [isLoggedIn, isUserFetching, loginInitiator, search]);

  const showAuthModal = useCallback(
    (URL?: string) => {
      if (isEmpty(URL) === false) {
        history.push(URL);
      } else if (isEmpty(params.get(AUTH_URI.PARAM_KEY)) === true && isDesktop && canShowOTPFlow) {
        if (isEmailLoginDisabled) {
          params.set(AUTH_URI.PARAM_KEY, AUTH_URI.LOGIN_VIA_MOBILE);
        } else params.set(AUTH_URI.PARAM_KEY, AUTH_URI.LOGIN_OPTIONS);
        history.push(pathname + '?' + params.toString());
      }
      setShowAuthModal(true);
    },
    [params, isDesktop, canShowOTPFlow, history, isEmailLoginDisabled, pathname]
  );
  const passiveShowAuthModal = useCallback(
    (url?: string) => {
      if (url) setRedirectUrl(url);
      showAuthModal();
    },
    [showAuthModal]
  );

  const onHideAuthModal = () => {
    setShowAuthModal(false);
    setRedirectUrl(undefined);
  };
  const showBottomSheetAuth = useCallback(
    (url?: string, disableTruecaller?: boolean) => {
      if (!isBottomSheetLoginEnabled) {
        url
          ? history.push(
              getRedirectionURLWithCurrentPage({
                url: url,
                pathname: `${window.location.protocol}//${window.location.host}${url}`,
                search: '',
              })
            )
          : setShowAuthModal(true);
        return;
      }
      setRedirectUrl(url);
      setShowBottomSheetAuth(true);
      if (disableTruecaller) setShowTruecaller(false);
      else setShowTruecaller(true);
    },
    [history, isBottomSheetLoginEnabled]
  );

  const values = useMemo(
    () => ({
      isAuthModalVisible: shouldShowBottomSheetAuth && canShowBottomSheetAuth,
      showAuthModal,
      passiveShowAuthModal,
      showBottomSheetAuth,
      setBottomSheetCallbacks: setBottomSheetCallbacks,
    }),
    [
      canShowBottomSheetAuth,
      passiveShowAuthModal,
      shouldShowBottomSheetAuth,
      showAuthModal,
      showBottomSheetAuth,
    ]
  );

  const hideBottomSheetAuth = () => setShowBottomSheetAuth(false);

  return (
    <>
      <AuthModalWrapperContext.Provider value={values}>
        {children}
        <MobileVerificationNudge />
      </AuthModalWrapperContext.Provider>

      {canShowAuthModal && (
        <>
          {!isDesktop && canShowOTPFlow && (
            <Redirect
              push
              to={
                redirectUrl
                  ? getRedirectionURLWithReferer({
                      url: AUTH_URI.LOGIN_OPTIONS,
                      redirectUrl,
                    })
                  : getRedirectionURLWithCurrentPage({
                      url: AUTH_URI.LOGIN_OPTIONS,
                      search,
                      pathname,
                    })
              }
            />
          )}
          {isDesktop && canShowOTPFlow && (
            <DesktopAuth
              isLoggedIn={isLoggedIn}
              onHide={onHideAuthModal}
              redirectUrl={redirectUrl}
            />
          )}
        </>
      )}
      {shouldShowBottomSheetAuth && canShowBottomSheetAuth && (
        <LoginBottomSheetWrapper
          hide={hideBottomSheetAuth}
          isUserDataFetching={isUserFetching}
          isUserLoggedIn={isLoggedIn}
          redirectUrl={redirectUrl}
          showTruecaller={showTruecaller}
          onSubmitCb={bottomSheetCallbacks['onSubmit']}
          onCancelCb={bottomSheetCallbacks['onCancel']}
          onProceedCb={bottomSheetCallbacks['onProceed']}
          onVisibleCb={bottomSheetCallbacks['onVisible']}
          isNupiEnabled={isNupiEnabled}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  const { isOTPFlowEnabledForMobile, isOTPFlowEnabledForDesktop } = getOTPFlowStatus();

  return {
    isOTPFlowEnabledForMobile,
    isOTPFlowEnabledForDesktop,
    isLoggedIn: _isLoggedIn(state),
    isUserFetching: _isUserFetching(state),
    isIndianUser: isCustomerFromIndia(state),
    isBottomSheetLoginEnabled: getRemoteConfig(state, 'bottomSheetLoginConfig').enabled,
  };
};

export default connect(mapStateToProps)(memo(AuthModalWrapper));
