// libs
import { connect } from 'react-redux';

// components
import TruecallerLogin from './TruecallerLogin';

// redux store
import { updateCustomerDetails } from 'shared/store/app/actions';

const mapDispatchToProps = {
  updateCustomerDetails,
};

export default connect(null, mapDispatchToProps)(TruecallerLogin);
