// libs
import React, { memo, useCallback, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

// global components
import Portal from 'shared/components/Portal';

// helpers
// TODO - move to common use helpers
import { useNavHelper } from 'shared/components/Header/MobileHeader/helpers';
import { hideOverflowY, resetOverflowY } from 'shared/helpers/dom';

// contexts
import OverlaySheetContext from './OverlaySheetContext';

// constants
import { SHEET_TRANSITION } from './constants';
import { OVERLAY_ZINDEX } from 'shared/styles/base';

// styles
import { Container, Overlay } from './styles';

interface IOverlaySheetProps extends RouteComponentProps {
  children: React.ReactNode;
  onHide: VoidFunction;
  navKey: string;
  transitionFrom: SHEET_TRANSITION;
  className?: string;
  allowScroll?: boolean;
  showOverlay?: boolean;
  disableNav?: boolean;
  hideOnOverlayClick?: boolean;
  overlayColor?: string;
  overlayZindex?: number;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

function OverlaySheet({
  children,
  navKey,
  transitionFrom,
  onHide = EMPTY_FUNC,
  history,
  className = '',
  allowScroll = false,
  showOverlay = true,
  disableNav = false,
  hideOnOverlayClick = true,
  overlayColor = '',
  overlayZindex = OVERLAY_ZINDEX,
}: IOverlaySheetProps) {
  const [hide, onHideSheet] = useNavHelper(history, navKey, disableNav);

  const overlayClickAction = () => {
    if (hideOnOverlayClick) {
      onHideSheet();
    }
  };
  useEffect(() => {
    if (allowScroll === false) {
      hideOverflowY('body', false);
      return resetOverflowY;
    }
  }, [allowScroll]);

  const onAnimationEnd = useCallback(() => {
    if (hide === false) {
      return;
    }

    onHide();
    resetOverflowY();
  }, [hide, onHide]);

  return (
    <Portal>
      <OverlaySheetContext.Provider value={{ doHide: onHideSheet }}>
        {showOverlay && (
          <Overlay
            overlayColor={overlayColor}
            onClick={overlayClickAction}
            data-test="os-overlay"
            overlayZindex={overlayZindex}
          />
        )}
        <Container
          transition={transitionFrom}
          hide={hide}
          onAnimationEnd={onAnimationEnd}
          data-test="os-content"
          className={className}
          overlayZindex={overlayZindex}
        >
          {children}
        </Container>
      </OverlaySheetContext.Provider>
    </Portal>
  );
}

export default withRouter(memo(OverlaySheet));
