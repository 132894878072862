// helpers
import NFA from 'shared/helpers/analytics/NFAnalytics';

// constants
import { TRACK_TYPE } from 'shared/helpers/analytics/constants';
import { ERROR_EVENTS } from 'shared/helpers/analytics/constants/errors';
import { TEXT } from './constants';

export const onPageLoad = () => {
  NFA.track({
    data: {
      errorCode: '404',
      errorMessage: TEXT.BROKEN_MSG,
    },
    type: TRACK_TYPE.PAGELOAD,
    event: ERROR_EVENTS.ERROR_PAGE_LOADS,
  });
};
