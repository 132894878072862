// libs
import React, { memo, useEffect } from 'react';

// global components
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import RedirectToHomePage from 'shared/components/RedirectToHomePage/RedirectToHomePage';

// component
import SizeGuide from 'shared/layouts/SizeGuide';

// helpers
import { log } from 'shared/helpers/newRelic';

// constants
import { TAG_IDENTIFIER } from 'shared/helpers/SentryLogger/constants';
const SIZE_GUIDE = 'SIZE_GUIDE';

function SizeGuideRoute() {
  useEffect(() => {
    try {
      // Store the original geolocation object
      const originalGeolocation = navigator.geolocation;

      // Disable geolocation permission requests
      Object.defineProperty(navigator, 'geolocation', {
        value: undefined,
        configurable: true, // So we can change it back later
      });

      // Clean up on unmount
      return () => {
        // Reset navigator.geolocation back to its original value
        Object.defineProperty(navigator, 'geolocation', {
          value: originalGeolocation,
          configurable: true,
        });
      };
    } catch (error) {
      log({
        errTitle: 'Malformed Adplatform Redirection',
        tags: { [TAG_IDENTIFIER.MODULE]: SIZE_GUIDE },
        error: error,
      });
    }
  }, []);

  return (
    <RouteChunkRenderer mobileComponent={<SizeGuide />} desktopComponent={<RedirectToHomePage />} />
  );
}

export default memo(SizeGuideRoute);
