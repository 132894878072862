import { isScriptLoaded } from 'shared/helpers/utils';

export enum LOAD_TYPE {
  ASYNC,
  DEFER,
}

const asyncDeferScript = (scriptPath: string, loadType: LOAD_TYPE = LOAD_TYPE.ASYNC) => {
  if (isScriptLoaded(scriptPath)) return Promise.resolve();

  return new Promise((resolve, reject) => {
    const scriptNode = document.createElement('script');
    scriptNode.src = scriptPath;
    scriptNode.onload = resolve;
    scriptNode.onerror = reject;
    if (loadType === LOAD_TYPE.ASYNC) scriptNode.async = true;
    if (loadType === LOAD_TYPE.DEFER) scriptNode.defer = true;
    document.body.appendChild(scriptNode);
  });
};

export default asyncDeferScript;
