// libs
import isEmpty from 'lodash/isEmpty';

// helpers
import NFA from 'shared/helpers/analytics/NFAnalytics';

// defs
import { ExperimentAllVariant, ExperimentVariant } from '@nykaa/experiment-sdk/types';

interface ExperimentInfo {
  uuid: string;
  experiments: ExperimentAllVariant;
}

interface Experiments {
  [experimentId: string]: Partial<ExperimentVariant>;
}
export const sendExperiments = (experimentsInfo: ExperimentInfo) => {
  const { experiments } = experimentsInfo;
  const abConfigValues: Experiments[] = [];

  Object.keys(experiments).forEach((expId) => {
    const {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      hash,
      /* eslint-disable @typescript-eslint/no-unused-vars */
      ...rest
    } = experiments[expId];
    if (isEmpty(rest) === false) {
      abConfigValues.push({
        [expId]: rest,
      });
    }
  });
  NFA.track({
    event: 'experiment_started',
    data: { ABV2: abConfigValues },
  });
};
