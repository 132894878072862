// libs
import { styled } from '@nykaa/ui-components';
import { keyframes, css } from '@emotion/core';

// constants
import { OVERLAY_ZINDEX, OVERLAY_CSS } from 'shared/styles/base';

// mixins
import { resetButton } from 'shared/styles/mixin';

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -150%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -51.5%);
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, -51.5%);
  }

  to {
    opacity: 0;
    transform: translate(-50%, -150%);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Container = styled.div<{ showModal: boolean }>`
  position: fixed;
  left: -999px;
  max-width: 90%;
  max-height: 90%;
  background: #fff;
  border-radius: ${({ theme }) => theme.borders.radius10};
  animation: ${slideIn} 0.25s ease-in forwards;
  z-index: ${OVERLAY_ZINDEX + 10};
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: ${({ theme }) => theme.elevations.shadow400};
  overflow-y: auto;

  ${({ showModal }) =>
    showModal
      ? css`
          top: 50%;
          left: 50%;
          animation: ${slideIn} 0.25s ease-in forwards;
        `
      : css`
          top: 50%;
          left: 50%;
          animation: ${slideOut} 0.2s ease-out forwards;
        `}

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

export const Button = styled.button`
  ${resetButton}

  position: absolute;
  right: 15px;
  top: 25px;
  padding: ${({ theme }) => theme.spacing.spacing50};
  cursor: pointer;
  font-size: 24px;
  z-index: 10010;
`;

export const Overlay = styled.div<{ hide: boolean }>`
  ${OVERLAY_CSS}

  z-index: ${OVERLAY_ZINDEX + 9};

  ${({ hide }) =>
    hide &&
    css`
      animation: ${fadeOut} 0.2s ease-out forwards;
    `}
`;
