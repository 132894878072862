// libs
import React, { memo, useState, useMemo } from 'react';
import { SerializedStyles } from '@emotion/core';

// global components
import Footer from 'shared/components/Footer/Desktop';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import DesktopHeaderProvider from 'shared/components/Header/DesktopHeaderProvider';
import LoaderProvider from 'shared/components/LoaderProvider';

// contexts
import AppLayoutContext from 'shared/layouts/AppLayout/AppLayoutContext';

// constants
import { MAIN_CONTENT_ID, TAB_INDEX } from 'shared/constants';

// styles
import { Container, AppLayoutContainer } from './styles';

// defs
interface IAppLayout {
  children: React.ReactNode;
}

function AppLayoutDesktop({ children }: IAppLayout) {
  const [childCss, setCSS] = useState<SerializedStyles | undefined>(undefined);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  const styles = useMemo(() => [childCss !== undefined && childCss], [childCss]);

  return (
    <AppLayoutContainer cssProps={styles}>
      <AppLayoutContext.Provider value={{ setCSS, isSubMenuVisible, setIsSubMenuVisible }}>
        <LoaderProvider>
          <DesktopHeaderProvider>
            <ErrorBoundary>
              <Container tabIndex={TAB_INDEX.ACTION_VISIBILITY} id={MAIN_CONTENT_ID}>{children}</Container>
            </ErrorBoundary>
          </DesktopHeaderProvider>
          <Footer />
        </LoaderProvider>
      </AppLayoutContext.Provider>
    </AppLayoutContainer>
  );
}

export default memo(AppLayoutDesktop);
