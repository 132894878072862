import { createContext, useContext } from 'react';

interface IModalContext {
  hide: VoidFunction;
}

const initialValue: IModalContext = {
  hide: () => {
    /** DO NOTING */
  },
};

const ModalContext = createContext(initialValue);

export const useModalContext = () => useContext(ModalContext);

export default ModalContext;
