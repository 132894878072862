// libs
import { createContext, useContext } from 'react';

// defs
import { UserDataValues } from './types';

interface UserDataContextInterface {
  getUserData: (key: string, value?: UserDataValues) => UserDataValues;
  setUserData: (key: string, value: UserDataValues) => void;
  clearUserData: VoidFunction;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

const EMPTY_FUNC_DATA = () => null;

const initialValue: UserDataContextInterface = {
  getUserData: EMPTY_FUNC_DATA,
  setUserData: EMPTY_FUNC,
  clearUserData: EMPTY_FUNC,
};

const userDataContext = createContext(initialValue);

export const useUserData = () => useContext(userDataContext);

export default userDataContext;
