//libs
import React, { memo } from 'react';

//hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

//component
import VisitorPriortization from './loadable';

function VisitorPriortizationWrapper() {
  const config = useRemoteConfig('visitorPriortization');
  if (config.enabled) return <VisitorPriortization />;
  return null;
}

export default memo(VisitorPriortizationWrapper);
