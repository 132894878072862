import { createContext, useContext } from 'react';
import { SerializedStyles } from '@emotion/core';

interface IContainerContextProps {
  setCSS: (css?: SerializedStyles) => void;
  isSubMenuVisible: boolean;
  setIsSubMenuVisible: (state: boolean) => void;
}

const initialValue: IContainerContextProps = {
  setCSS: () => {
    /** Do NOTHING */
  },
  isSubMenuVisible: false,
  setIsSubMenuVisible: () => {
    /** Do NOTHING */
  },
};

const appLayoutContext = createContext(initialValue);

export const useAppLayoutContext = () => useContext(appLayoutContext);

export default appLayoutContext;
