import { getExperimentVariant } from '@nykaa/experiment-sdk/client';

const ONR_AB_EXPERIMENT = 'onr-cart-ab';
// types
import { CartItem } from 'shared/types/cart';
import { CartListElements, CartPageElement, CartPageLoadData } from './types';

// constant
const LOCAL_STORAGE_KEY = 'nykaa_show_onr';
const LOCAL_STORAGE_KEY_EDD = 'isEddV2Enabled';

export const setOnrKeyStorage = () => {
  const { isEligible = false } = getExperimentVariant(ONR_AB_EXPERIMENT);
  try {
    if (window.sessionStorage === undefined) {
      return;
    }
    window.sessionStorage.setItem(LOCAL_STORAGE_KEY, isEligible ? 'true' : 'false');
  } catch (e) {
    /** DO NOTHING */
  }
};

export const getOnrKeyStorage = () => {
  try {
    if (window.sessionStorage === undefined) {
      return;
    }
    return window.sessionStorage.getItem(LOCAL_STORAGE_KEY) === 'true' || false;
  } catch (e) {
    return false;
  }
};

// is product partially available
export const isPartialOOS = (item: CartItem) => {
  if (item.quantity > 1) {
    if (item.availableQty < item.quantity) return true;
  }
  return false;
};

// push element to an array only if the element is valid
const pushIfValid = (arr: string[], element?: any) => {
  if (!element) return arr;

  arr.push(element);
  return arr;
};

const getListToString = (cartListElements: { [key: string]: string[] }) => {
  const transformData: { [key: string]: string } = {};

  Object.keys(cartListElements).forEach((listName: string) => {
    transformData[listName] = cartListElements[listName].join(',');
  });

  return transformData;
};

export const getCartPageLoadData = (cart: CartPageLoadData) => {
  try {
    let { items = [] } = cart;

    let cartElements: CartPageElement = {
      cartOOSCount: 0,
      productWithOfferCount: 0,
      availableOffersCount: 0,
      cartPartialOOSCount: 0,
      cartInStockCount: 0,
      cartDiscountApplied: 0,
      cartProductCount: 0,
      cartSubTotalAmount: 0,
      cartTotalAmount: 0,
      cartTotalQty: 0,
      currency: '',
      pincode: '',
      rewardPointsApplied: 0,
      shippingCharges: 0,
      additionalDiscountApplied: 0,
      cartFinalPrice: 0,
      couponCodeApplied: '',
      isFomoTimerVisible: false,
      cartCouponDiscount: 0,
      isEligibleForSpeculationRule: false,
    };

    let cartListElements: CartListElements = {
      brandNameList: [],
      productIdList: [],
      productNameList: [],
      variantProductIdList: [],
      productTypeList: [],
      categoryLevel1List: [],
      categoryLevel2List: [],
      categoryLevel3List: [],
      categoryLevel4List: [],
      cartAvailableOffers: [],
      cartAppliedOffers: [],
      cartProducts: [],
    };

    cartElements.cartDiscountApplied = cart?.cartTotal?.cartDiscount?.amount;
    cartElements.cartProductCount = cart?.itemCount;
    cartElements.cartSubTotalAmount = cart?.cartTotal?.subTotal?.amount;
    cartElements.cartTotalAmount = cart?.cartTotal?.totalMrp?.amount;
    cartElements.cartTotalQty = cart?.quantity;
    cartElements.currency = cart?.currencyCode;
    cartElements.pincode = cart?.pincode || cart?.address?.postcode;
    cartElements.rewardPointsApplied = cart?.rewardsDTO?.rewardsApplied;
    cartElements.shippingCharges = cart?.cartTotal?.shipping.amount;
    cartElements.additionalDiscountApplied = cart?.cartTotal?.ruleDiscountWithoutLabel?.amount;
    cartElements.cartFinalPrice = cart?.cartTotal?.grandTotal.amount;
    cartElements.couponCodeApplied = cart?.couponCode;
    cartElements.isFomoTimerVisible = cart?.isFomoTimerVisibleOncart;
    cartElements.isEligibleForSpeculationRule = cart?.isEligibleForSpeculationRule;
    cartElements.youPayCount = cart?.youPayCount;
    cartElements.eligibleFreeSamples = cart?.freeGiftsBannerData?.eligibleCount;
    cartElements.claimedFreeSamples = cart?.freeGiftsBannerData?.claimedCount;
    cartElements.cartCouponDiscount = cart?.cartTotal?.couponDiscount?.amount;

    if (cart?.shippingDataDTO?.convenienceChargesDTO) {
      cart.shippingDataDTO.convenienceChargesDTO.map((val) => {
        cartElements[val.analyticsKey] = val.amount;
      });
    }

    items.forEach((item) => {
      const appliedOffers = item.offerCommunication?.appliedOfferIds || [];
      const offers = item.offerCommunication?.availableOfferIds || [];
      const offersCount = offers.length;

      // this is added to support for future, currently we are not getting this value
      const categories = item?.primary_categories_json_all || undefined;

      cartElements.availableOffersCount += offersCount;
      cartElements.productWithOfferCount += offersCount > 0 ? 1 : 0;
      cartElements.cartOOSCount += item.isOutOfStock ? 1 : 0;
      cartElements.cartPartialOOSCount += isPartialOOS(item) ? 1 : 0;
      cartElements.cartInStockCount += item.isOutOfStock || isPartialOOS(item) ? 0 : 1;

      cartListElements.brandNameList = pushIfValid(
        cartListElements.brandNameList,
        item?.brandNames[0]
      );
      cartListElements.productIdList.push(item.parentId);
      cartListElements.productNameList.push(item.name);
      cartListElements.variantProductIdList.push(item.productId);

      cartListElements.productTypeList = pushIfValid(
        cartListElements.productTypeList,
        item.productType
      );
      cartListElements.categoryLevel1List = pushIfValid(
        cartListElements.categoryLevel1List,
        categories?.l1_Category?.name
      );
      cartListElements.categoryLevel2List = pushIfValid(
        cartListElements.categoryLevel2List,
        categories?.l2_Category?.name
      );
      cartListElements.categoryLevel3List = pushIfValid(
        cartListElements.categoryLevel3List,
        categories?.l3_Category?.name
      );
      cartListElements.categoryLevel4List = pushIfValid(
        cartListElements.categoryLevel4List,
        categories?.l4_Category?.name
      );

      if (offersCount > 0) {
        const offerData = `${item.productId}:${offers.join('|')}`;
        cartListElements.cartAvailableOffers.push(offerData);
      }

      if (appliedOffers.length > 0) {
        const offerData = `${item.productId}:${appliedOffers.join('|')}`;
        cartListElements.cartAppliedOffers.push(offerData);
      }

      const productStockStatus = item.isOutOfStock
        ? 'OOS'
        : isPartialOOS(item)
        ? 'partialOOS'
        : 'instock';

      cartListElements.cartProducts.push({
        edd_o2d: item?.estimatedDeliveryTime,
        product_id: item?.parentId,
        product_name: item?.productTitle,
        product_type: item?.productType,
        mrp_price: item?.cartItemPriceDTO?.mrp,
        product_stock_status: productStockStatus,
        brand_name: item?.brandNames[0],
        discounted_percent: item?.cartItemPriceDTO?.catalogDiscountRate,
        discounted_price: item?.cartItemPriceDTO?.price,
        is_edd_present: item?.estimatedDeliveryTime ? true : false,
        quantity: item?.quantity,
        variant_product_id: item?.productId,
        variant_type: item?.packSize,
      });
    });

    cartListElements.cartProducts = JSON.stringify(cartListElements.cartProducts);
    const cartProducts = cartListElements.cartProducts;

    delete cartListElements?.cartProducts;

    return {
      ...cartElements,
      cartProducts,
      ...getListToString(cartListElements),
    };
  } catch (err) {
    return {};
  }
};

export const setStorageEddVariant = (isEddV2Enabled = false) => {
  try {
    if (window.sessionStorage === undefined) {
      return;
    }
    window.sessionStorage.setItem(LOCAL_STORAGE_KEY_EDD, isEddV2Enabled ? 'true' : 'false');
  } catch (e) {
    /** DO NOTHING */
  }
};
