//libraries
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { frontloadConnect } from 'react-frontload';
import isEmpty from 'lodash/isEmpty';

//component
import DynamicPage from './DynamicPage';

// redux store
import { setDynamicPageData } from 'shared/store/dynamicPage/action';
import {
  getIsDynamicPageDataFetched,
  getIsDynamicPageDataFetching,
} from 'shared/store/dynamicPage/selector';
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';

// helpers
import { fetchDynamicPageConfig } from 'shared/api/DynamicUrlPage';
import { DynamicPageData } from './types';

interface DynamicPageConfigResponse {
  status: string;
  data: DynamicPageData;
}

interface Dispatch {
  (payload?: any): void;
}

interface Props {
  isFetching: boolean;
  isDataFetched: boolean;
  setDynamicPageDataDispatch: Dispatch;
  match: {
    params: {
      slug: string;
    };
  };
  allowedDynamicPages: boolean;
}

async function fetchData(props: Props) {
  const { isFetching, setDynamicPageDataDispatch, isDataFetched, match, allowedDynamicPages } =
    props;
  const { params } = match;
  const { slug } = params;

  try {
    if (!allowedDynamicPages || (!isFetching && isEmpty(slug))) {
      setDynamicPageDataDispatch({});
      return;
    }

    if (isDataFetched) {
      return;
    }

    const res: DynamicPageConfigResponse = await fetchDynamicPageConfig(slug);

    if (res?.status === 'success' && res.data?.title) {
      setDynamicPageDataDispatch(res.data);
    } else {
      setDynamicPageDataDispatch({});
    }
    return res;
  } catch (error) {
    setDynamicPageDataDispatch({});
  }
}

const mapStateToProps = (state: any) => ({
  isFetching: getIsDynamicPageDataFetching(state),
  isDataFetched: getIsDynamicPageDataFetched(state),
  allowedDynamicPages: getRemoteConfig(state, 'allowDynamicPages'),
});

const mapDispatchToProps = {
  setDynamicPageDataDispatch: setDynamicPageData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(frontloadConnect(fetchData, { onUpdate: false })(DynamicPage)));
