import { connect } from 'react-redux';

import { onIntlNetwork, getUserFormKey } from 'shared/store/app/selectors';

import { fetchUser } from 'shared/store/app/services';

import { setWebAppType } from 'shared/store/app/actions';

import Boot from './Boot';

const mapStateToProps = (state: any) => ({
  onIntlNetwork: onIntlNetwork(state),
  userFormKey: getUserFormKey(state),
});

const mapDispatchToProps = {
  fetchUser,
  setWebAppType,
};

export default connect(mapStateToProps, mapDispatchToProps)(Boot);
