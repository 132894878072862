export const TEXT = {
  INFO: 'Oops! Something went wrong.',
  BACK_LINK_LABEL: 'GO BACK',
  SOMETHING_WENT_WRONG: 'Something Went Wrong',
  ERROR_DESCRIPTION: 'Whoops! Something seems broken here. Come back later & try again.',
};

export const IMAGE = {
  url: 'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/something_went_wrong.svg',
  width: 1,
  aspectRatio: 1,
  alt: 'something went wrong',
};
