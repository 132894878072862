// libs
import React, { memo, useRef } from 'react';

// component
import { A2hsNudge } from 'shared/components/Header/MobileHeader/components/A2hs';

// contexts
import MobileActionContext from './Context';

// hooks
import useA2hs from './useA2hs';

// defs
type IMobileActionProvider = React.PropsWithChildren<{}>;

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

function MobileActionProvider({ children }: IMobileActionProvider) {
  const showSearchCallback = useRef(EMPTY_FUNC);

  const setShowSearchCallback = (cb: VoidFunction) => {
    showSearchCallback.current = cb;
  };

  const showSearch = () => showSearchCallback.current();

  const { canShowA2hsContent, addToHomeClickHandler } = useA2hs();

  return (
    <MobileActionContext.Provider
      value={{ showSearch, setShowSearchCallback, canShowA2hsContent, addToHomeClickHandler }}
    >
      {children}
      <A2hsNudge />
    </MobileActionContext.Provider>
  );
}

export default memo(MobileActionProvider);
