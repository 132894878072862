import React, { memo } from 'react';

// components
import { SHEET_TRANSITION } from 'shared/components/OverlaySheet';
import Nudge from './Nudge';

// hooks
import { useShouldShowNudge } from './hooks';
import { useResponsiveContext } from 'shared/components/ResponsiveLoader/ResponsiveContext';

// styles
import { OverlaySheet } from './styles';
import { BREAKPOINTS } from 'shared/styles/base';

// defs
import { IContainerProps } from './types';

function Container({
  isValid,
  isEnabledForMobile,
  isEnabledForDesktop,
  firstBreakpoint,
  ttlBeforeFirstBreakpoint,
  ttlAfterFirstBreakpoint,
  maxTimesToShowNudge,
  info,
  firstName = '',
}: IContainerProps) {
  const { deviceWidth } = useResponsiveContext();
  const isDesktop = deviceWidth > BREAKPOINTS.DESKTOP;

  const [showNudge, onNudgeHide] = useShouldShowNudge({
    firstBreakpoint,
    ttlBeforeFirstBreakpoint,
    ttlAfterFirstBreakpoint,
    maxTimesToShowNudge,
    isValid,
    isEnabledForMobile,
    isEnabledForDesktop,
    isDesktop,
  });

  if (showNudge === false) {
    return null;
  }

  return (
    <OverlaySheet
      onHide={onNudgeHide}
      transitionFrom={SHEET_TRANSITION.BOTTOM}
      navKey={'OTP_NUDGE'}
      data-test="mobileVerification-nudge-overlay"
    >
      <Nudge info={info} firstName={firstName} />
    </OverlaySheet>
  );
}

export default memo(Container);
