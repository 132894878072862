const EMAIL = 'NYKAA_FASHION_USER_EMAIL';

export const setEmailInSessionStorage = (email: string) => {
  if (window.sessionStorage === undefined) {
    return;
  }
  window.sessionStorage.setItem(EMAIL, email);
};

export const getEmailInSessionStorage = () => {
  if (window.sessionStorage === undefined) {
    return;
  }
  return window.sessionStorage.getItem(EMAIL) || '';
};

export const removeEmailInSessionStorage = () => {
  if (window.sessionStorage === undefined) {
    return;
  }
  return window.sessionStorage.removeItem(EMAIL);
};
