export enum TOASTER_TYPES {
  SUCCESS,
  WARNING,
  ERROR,
  INFO,
}

export enum ALIGNMENT_TYPES {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}
