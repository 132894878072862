// libs
import { css, keyframes } from '@emotion/core';
import { styled } from '@nykaa/ui-components';

const bubbleAnimation = keyframes`
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`;

const SpinAnimation = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

const stretchAnimation = keyframes`
  0%{
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 0;
  }
  50%{
    stroke-dasharray: 141.3;
    stroke-dashoffset: -141.3;
  }
  100%{
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: -282.6;
  }
`;

export const Container = styled.div<{ fixed: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;

  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
`;

export const InnerLoader = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borders.radiusFull};
  background: #fff;
  box-shadow: ${({ theme }) => theme.elevations.shadow100};
`;

export const SpinningBubbles = styled.div`
  position: relative;
  margin: auto;
  top: 2px;
  left: 2px;
`;

export const BubbleContainer = styled.div`
  position: absolute;
  top: calc(50% - 10px / 2);
  left: calc(50% - 10px / 2);
  transform-origin: -150% 50%;

  &:nth-of-type(0n + 1) {
    transform: translateX(200%) rotate(-90deg);
    animation-delay: -1.5s;
  }
  &:nth-of-type(0n + 2) {
    transform: translateX(200%) rotate(-45deg);
    animation-delay: -1.375s;
  }
  &:nth-of-type(0n + 3) {
    transform: translateX(200%);
    animation-delay: -1.25s;
  }
  &:nth-of-type(0n + 4) {
    transform: translateX(200%) rotate(45deg);
    animation-delay: -1.125s;
  }
  &:nth-of-type(0n + 5) {
    transform: translateX(200%) rotate(90deg);
    animation-delay: -1s;
  }
  &:nth-of-type(0n + 6) {
    transform: translateX(200%) rotate(135deg);
    animation-delay: -0.875s;
  }
  &:nth-of-type(0n + 7) {
    transform: translateX(200%) rotate(180deg);
    animation-delay: -0.75s;
  }
  &:nth-of-type(0n + 8) {
    transform: translateX(200%) rotate(225deg);
    animation-delay: -0.625s;
  }
`;

export const Bubble = styled.div`
  background: #f17584;
  width: 5px;
  height: 5px;
  border-radius: ${({ theme }) => theme.borders.radiusFull};
  animation: ${bubbleAnimation} 1s infinite;
  animation-delay: inherit;
`;

export const SpinnerBase = styled.div`
  display: flex;
  align-content: space-around;
  justify-content: center;
  &.spinnerBase {
    top: 50%;
    left: 50%;
  }
  transform: translate(calc(0px), calc(-33px));
  svg {
    position: absolute;
    height: 56px;
    width: 56px;
    animation: ${SpinAnimation} 2s linear infinite;
  }
  .loader-svg {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke: ${({ theme }) => theme.colors.primary};
    &.animate {
      stroke-dasharray: 242.6;
      animation: ${stretchAnimation} 1.5s ease-in-out infinite;
    }
  }
  .loader-svg.bg {
    stroke-width: 2px;
    stroke: ${({ theme }) => theme.colors.textOutline};
  }
`;

export const loaderCss = css`
  .loading-container {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
  }

  .loader {
    position: relative;
    width: 50px;
    height: 50px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.borders.radiusFull};
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1); // not changing as theme not available
  }

  .loading-container.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }

  /** Settings */

  .loading-spinning-bubbles {
    position: relative;
    margin: auto;
    top: 2px;
    left: 2px;
  }
  .loading-spinning-bubbles .bubble-container {
    position: absolute;
    top: calc(50% - 10px / 2);
    left: calc(50% - 10px / 2);
    transform-origin: -150% 50%;
  }
  .loading-spinning-bubbles .bubble-container .bubble {
    background: #f17584;
    width: 5px;
    height: 5px;
    border-radius: ${({ theme }) => theme.borders.radiusFull};
    animation: bubble 1s infinite;
    animation-delay: inherit;
  }
  .loading-spinning-bubbles .bubble-container:nth-of-type(0n + 1) {
    transform: translateX(200%) rotate(-90deg);
    animation-delay: -1.5s;
  }
  .loading-spinning-bubbles .bubble-container:nth-of-type(0n + 2) {
    transform: translateX(200%) rotate(-45deg);
    animation-delay: -1.375s;
  }
  .loading-spinning-bubbles .bubble-container:nth-of-type(0n + 3) {
    transform: translateX(200%);
    animation-delay: -1.25s;
  }
  .loading-spinning-bubbles .bubble-container:nth-of-type(0n + 4) {
    transform: translateX(200%) rotate(45deg);
    animation-delay: -1.125s;
  }
  .loading-spinning-bubbles .bubble-container:nth-of-type(0n + 5) {
    transform: translateX(200%) rotate(90deg);
    animation-delay: -1s;
  }
  .loading-spinning-bubbles .bubble-container:nth-of-type(0n + 6) {
    transform: translateX(200%) rotate(135deg);
    animation-delay: -0.875s;
  }
  .loading-spinning-bubbles .bubble-container:nth-of-type(0n + 7) {
    transform: translateX(200%) rotate(180deg);
    animation-delay: -0.75s;
  }
  .loading-spinning-bubbles .bubble-container:nth-of-type(0n + 8) {
    transform: translateX(200%) rotate(225deg);
    animation-delay: -0.625s;
  }

  @keyframes bubble {
    0%,
    100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
`;
