// libs
import React, { memo } from 'react';
import ThemeProvider from '@nykaa/ui-components/styles/ThemeProvider';

// helpers
import { getAppTheme } from 'shared/styles/theme';

interface IProvider {
  children: React.ReactNode;
}

function Provider({ children }: IProvider) {
  const theme = getAppTheme();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default memo(Provider);
