// libs
import { styled } from '@nykaa/ui-components';
import { keyframes } from '@emotion/core';

// constants
import { resetButton } from 'shared/styles/mixin';

const slideIn = keyframes`
    0% {
        transform: translateY(100%);
    }

    
    100% {
        transform: translateY(0);
    }
`;

export const Container = styled.section`
  z-index: 11;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.spacing100};
  background: ${({ theme }) => theme.colors.inverse};
  animation: ${slideIn} 0.3s ease-in forwards;
`;

export const Left = styled.div`
  display: flex;
  justify-content: center;

  .action {
    ${resetButton}
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
    ${({ theme }) => theme.typography.subTitleLarge};
    margin-left: ${({ theme }) => theme.spacing.spacing80};
    display: inline-block;
    letter-spacing: 0.22px;
  }
`;
