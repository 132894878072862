// libs
import React, { memo } from 'react';

// global components
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import ChunkFallback from 'shared/components/ChunkFallback';
import RedirectToHomePage from 'shared/components/RedirectToHomePage';

// components
import MegaMenu from 'shared/layouts/MegaMenuV2';

function Detail() {
  return (
    <RouteChunkRenderer
      mobileComponent={<MegaMenu fallback={<ChunkFallback />} />}
      desktopComponent={<RedirectToHomePage />}
    />
  );
}

export default memo(Detail);
