import { createContext } from 'react';

interface IExperimentContext {
  variant: null | string;
}

const initialValue: IExperimentContext = {
  variant: null,
};

export default createContext(initialValue);
