import { OTP_CONFIG } from 'shared/components/AuthModal/constants';

export const getOtpConfig = () => {
  let isEnabled = {} as Partial<typeof OTP_CONFIG>;

  try {
    //@ts-ignore
    const { isEnabledForMobile, isEnabledForDesktop } = OTP_CONFIG;
    isEnabled = { isEnabledForMobile, isEnabledForDesktop };
  } catch (err) {
    // TODO: ADD sentry error
    isEnabled = { isEnabledForMobile: true, isEnabledForDesktop: true };
  }
  return isEnabled;
};

export const actionsForMagento = (closeOnly: boolean, redirectUrl: string) => {
  try {
    if (closeOnly) {
      //@ts-ignore
      if (window.parent.closeLoginIframe) {
        //@ts-ignore
        window.parent.closeLoginIframe();
      }
    } else {
      //@ts-ignore
      if (window.parent.loginDoneViaIframe) {
        //@ts-ignore
        window.parent.loginDoneViaIframe();
      }
      if (redirectUrl) {
        window.parent.location.href = redirectUrl;
      }
    }
  } catch (err) {
    window.parent.location.reload();
    // TODO: Add sentry alert
  }
};
