// libs
import React from 'react';

// components
import SomethingWentWrong from 'shared/components/SomethingWentWrong';

// helpers
import { log } from 'shared/helpers/newRelic';

// constants
import { ERROR_TAGS, TAG_IDENTIFIER } from 'shared/helpers/SentryLogger/constants';

interface IErrorBoundaryProps {
  children: React.ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.PureComponent<IErrorBoundaryProps, IErrorBoundaryState> {
  public state = {
    hasError: false,
  };

  public static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: any) {
    log({
      errTitle: 'Error in Error Boundary',
      error,
      tags: { [TAG_IDENTIFIER.ERROR]: ERROR_TAGS.DEFAULT },
    });
    console.error(error);
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <SomethingWentWrong />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
