let historyLength = 0;

export const getHistoryLength = () => historyLength;

export const setHistoryLength = (newHistoryLength: number) => (historyLength = newHistoryLength);

export const canGoBack = () => {
  const referrer = document.referrer || '';

  // can go back if in memory history length is greater than zero
  // OR referrer is same as our domain
  return historyLength > 0 || referrer.indexOf(window.location.host) >= 0;
};
