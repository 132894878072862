// libs
import React, { memo, useCallback, useState } from 'react';

// global components
import Portal from 'shared/components/Portal';

// components
import Toast from './components/Toast';

// contexts
import ToastContext from './ToastContext';

// defs
import { ToastConfig } from './types';

interface Props {
  children: React.ReactNode;
}

function ToastWrapper({ children }: Props) {
  const [toastConfigs, setToastConfigs] = useState<ToastConfig[]>([]);

  const onHide = (idx: number) => {
    setToastConfigs((configs) => {
      return [...configs.slice(0, idx), ...configs.slice(idx + 1)];
    });
  };

  const showToast = useCallback(({ afterHide, ...config }: ToastConfig) => {
    setToastConfigs((configs) => {
      return [...configs, config];
    });
    afterHide && afterHide();
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Portal>
        {toastConfigs.map((config, idx) => (
          <Toast
            type={config.type}
            message={config.message}
            onHide={() => onHide(idx)}
            hideTime={config.hideTime}
            hideIcon={config.hideIcon}
            textAlign={config.textAlign}
            key={idx}
            styles={config.styles}
            statusCode={config?.statusCode}
          />
        ))}
      </Portal>
    </ToastContext.Provider>
  );
}

export default memo(ToastWrapper);
