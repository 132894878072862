// libs
import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';

// components
import BottomNavRenderer from './LoadableBottomNavRenderer';
import AuthModalWrapper from 'shared/components/AuthModal/AuthModalWrapper';

// hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// helpers
import { isWebView } from 'shared/helpers/utils/url';

function BottomNavV2() {
  const { enable: bottomNavV2Enabled } = useRemoteConfig('bottomNavV2');
  const location = useLocation();

  // if bottom nav v2 not enabled do not render bottom nav v2
  // if webview dont render bottom nav
  if (bottomNavV2Enabled === false || isWebView(location)) {
    return null;
  }

  return (
    <AuthModalWrapper>
      <BottomNavRenderer />
    </AuthModalWrapper>
  );
}

export default memo(BottomNavV2);
