// libs
import React, { memo, useState } from 'react';
import { styled } from '@nykaa/ui-components';

// components
import DwebCartProvider from 'shared/components/DwebCart/DwebCartProvider';
import HeaderV2 from 'shared/components/Header/DesktopHeaderV2';
import AuthModalWrapper from 'shared/components/AuthModal/AuthModalWrapper';

// constants
import {
  DWEB_DISCOUNT_HEIGHT,
  DWEB_PRIMARY_HEADER_HEIGHT,
  DWEB_SECONDARY_HEADER_HEIGHT,
} from 'shared/styles/base';

import { isWebView } from 'shared/helpers/utils/url';
import { useLocation } from 'react-router-dom';
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// styles
const Container = styled.div<{ isSubMenuVisible: boolean; showPromotionalBanner: boolean }>`
  ${({ isSubMenuVisible, showPromotionalBanner }) =>
    isSubMenuVisible
      ? ` padding-top: ${
          showPromotionalBanner
            ? DWEB_PRIMARY_HEADER_HEIGHT + DWEB_DISCOUNT_HEIGHT
            : DWEB_PRIMARY_HEADER_HEIGHT + DWEB_SECONDARY_HEADER_HEIGHT + DWEB_DISCOUNT_HEIGHT
        }px;`
      : ` padding-top: ${
          showPromotionalBanner
            ? DWEB_PRIMARY_HEADER_HEIGHT + DWEB_DISCOUNT_HEIGHT
            : DWEB_PRIMARY_HEADER_HEIGHT
        }px;`}

  transition: padding .1s ease;
`;

// defs
interface IDesktopHeaderProviderProps {
  children: React.ReactNode;
  className?: string;
}

function DesktopHeaderProvider({ children, className }: IDesktopHeaderProviderProps) {
  const location = useLocation();
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);
  const shouldShowHeader = !isWebView(location);
  const showPromotionalBanner = useRemoteConfig('showPromotionalBanner');

  if (shouldShowHeader === false) {
    return <>{children}</>;
  }

  return (
    <Container
      isSubMenuVisible={isSubMenuVisible}
      className={className}
      showPromotionalBanner={showPromotionalBanner}
    >
      <AuthModalWrapper>
        <DwebCartProvider>
          <HeaderV2 setSubMenuVisible={setSubMenuVisible} />
          {children}
        </DwebCartProvider>
      </AuthModalWrapper>
    </Container>
  );
}

export default memo(DesktopHeaderProvider);
