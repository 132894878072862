// libs
import React, { memo } from 'react';
import { useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

// components
import ChunkFallback from 'shared/components/ChunkFallback';
import DetailMobile from 'shared/layouts/DetailsPage/Mobile';
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// helpers
import { calculateExperimentVariant, PDP_EXPT_VARIANT } from './exptHelpers';
import { redirect } from 'shared/store/app/actions';

const HybridPDPExpt = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id: string }>();

  const control = useRemoteConfig('hybridPDPExptControl');

  // calculate user variant
  const { variant: userExperimentVariant, isEligible } = calculateExperimentVariant({
    search,
    control,
  });

  // do not calculate experiment in case of client redirection
  if (__BROWSER__) {
    return <DetailMobile fallback={<ChunkFallback />} isEligibleForHybridPDP={isEligible} />;
  }

  if (userExperimentVariant === PDP_EXPT_VARIANT.HYBRID_PDP) {
    dispatch(redirect(`/hp/${id}${search}`));
  }

  if (userExperimentVariant === PDP_EXPT_VARIANT.HYBRID_PDP_VARIANT2) {
    dispatch(redirect(`/hc/${id}${search}`));
  }

  // return pdp in all cases for the current url
  return <DetailMobile fallback={<ChunkFallback />} isEligibleForHybridPDP={isEligible} />;
};

export default memo(HybridPDPExpt);
