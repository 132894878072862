//defs
export interface TruecallerApiResponse {
  state: string;
  action: string;
  user_response: {
    status: string;
    response: {
      user: {
        userId: string;
        mobile: string;
      };
    };
  };
}

export enum API_STATUS {
  API_PROCESSING,
  NOT_AUTHENTIC_USER,
  ALTERNATE_NUMBER_SELECTED,
  TRUECALLER_LOGIN_SELECTED,
  BACK_BUTTON_CLICKED,
  API_FAILURE,
}

export const getTruecallerSDKUrl = (requestID: string) => {
  return `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${requestID}&partnerKey=${process.env.TRUECALLER_APP_KEY}&partnerName=NykaaFashion&lang=en&ctaPrefix=use&ctaColor=%23001325&ctaTextColor=%23FFFFFF&btnShape=round&loginPrefix=getstarted&loginSuffix=verifymobile&skipOption=useanothernum`;
};

export const getApiStatus = (status: string, response: TruecallerApiResponse) => {
  if (status === 'success' && response.state === 'processing') {
    return API_STATUS.API_PROCESSING;
  }

  if (status === 'fail') {
    // user selected to login through truecaller but the API gave response as 400 / 403 / 401.
    return API_STATUS.NOT_AUTHENTIC_USER;
  }

  if (status === 'success' && response.state === 'processed') {
    if (response?.user_response?.status === 'fail') {
      // user selected to login through truecaller but the request was not authentic
      return API_STATUS.NOT_AUTHENTIC_USER;
    }

    if (response.action === 'use_another_number') {
      // user selected 'Use Another Mobile Number' option
      return API_STATUS.ALTERNATE_NUMBER_SELECTED;
    }

    if (
      response.action !== 'use_another_number' &&
      response.user_response.status === 'success' &&
      response.user_response?.response?.user?.mobile
    ) {
      // user selected to login through truecaller and the API returned user details successfully
      return API_STATUS.TRUECALLER_LOGIN_SELECTED;
    }

    if (response.action === 'user_rejected') {
      // user clicked on back button on seeing truecaller bottomsheet
      return API_STATUS.BACK_BUTTON_CLICKED;
    }
  }

  // return all other cases as failure
  return API_STATUS.API_FAILURE;
};
