import { getUrl, ROUTES } from 'shared/api/urls';
import { ApiHelper } from 'shared/helpers/api';

export const getOfferText = (): Promise<any> => {
  return ApiHelper(getUrl(ROUTES.OFFERS)).then((data: Response) => {
    if (data.status !== 200) {
      return Promise.reject(data.statusText);
    }
    return data.json();
  });
};
