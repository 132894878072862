import { css, keyframes, SerializedStyles } from '@emotion/core';
import { styled } from '@nykaa/ui-components';

export const EMPTY_CSS = css``;

export const RippleContainer = styled.div<{
  cssProps?: boolean | SerializedStyles | SerializedStyles[];
}>`
  position: relative;
  overflow: hidden;

  ${({ cssProps }) => cssProps}
`;

const rippleKeyframes = keyframes`
    0% {
        transform: scale(0);
    }

    to {
        opacity: 0;
        transform: scale(100);
    }
`;

export const RippleChild = styled.div<{ rippleShade?: string }>`
  width: 4px;
  height: 4px;
  background-color: ${({ rippleShade }) => rippleShade};
  border-radius: ${({ theme }) => theme.borders.radiusFull};
  position: absolute;
  animation: ${rippleKeyframes} 0.3s ease-in-out;
  transform: scale(0);
  opacity: 1;
  contain: content;
`;
