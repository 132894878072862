// libs
import React, { memo, useState, useEffect } from 'react';

// contexts
import { useMobileActionContext } from 'shared/components/Header/MobileAction/Context';

// styles
import { Container, Left } from './styles';

// constants
import { INFO, IMAGE_LOGO, DELAY_TO_AVOID_JERK } from './constants';

// helpers
import { setActionTaken, lastSessionValid } from './helpers';

// HOC
import withExperiment from 'shared/components/Header/MobileHeader/components/A2hs/withExperiment';

// icons
import CircleXFilledIcon from '@nykaa/ui-components/Icons/circle-x-filled';

function A2hsNudge() {
  const [show, setShow] = useState(false);
  const { addToHomeClickHandler, canShowA2hsContent } = useMobileActionContext();

  const clickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    addToHomeClickHandler('nudge');
  };

  const closePopup = () => {
    setShow(false);
    setActionTaken();
  };

  useEffect(() => {
    if (lastSessionValid()) {
      return;
    }

    const time = setTimeout(() => {
      setShow(true);
    }, DELAY_TO_AVOID_JERK);

    return () => {
      clearTimeout(time);
    };
  }, []);

  if (show === false || canShowA2hsContent === false) {
    return null;
  }

  return (
    <Container data-test="A2hs-nudge-container">
      <Left>
        <img src={IMAGE_LOGO} alt={INFO} height={21} />
        <button onClick={clickHandler} className="action">
          {INFO}
        </button>
      </Left>
      <span className="cancelIcon" onClick={closePopup} aria-hidden={true}>
        <CircleXFilledIcon />
      </span>
    </Container>
  );
}

export default withExperiment(memo(A2hsNudge));
