import React, { memo } from 'react';
import { Global, css } from '@emotion/core';

import Normalize from './Normalize';
import Fonts from './Fonts';
import Icons from './Icons';

import { FONT_SIZES, BASE_FONT } from './base';

const baseStyles = css`
  html {
    font-size: ${FONT_SIZES.BASE};
  }

  body {
    font-family: ${BASE_FONT};
    font-size: ${FONT_SIZES.BASE};
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html * {
    box-sizing: border-box;
  }

  a {
    color: #000;
  }

  * {
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
  }

  .flex {
    display: flex;
  }

  .hide {
    display: none;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .uppercase {
    text-transform: uppercase;
  }
`;

function BaseStyles() {
  return (
    <>
      <Normalize />
      <Fonts />
      <Icons />
      <Global styles={baseStyles} />
    </>
  );
}

export default memo(BaseStyles);
