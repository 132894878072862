// libs
import React, { forwardRef, memo } from 'react';
import { keyframes } from '@emotion/core';
import { styled } from '@nykaa/ui-components';
import { BG_BUTTON_OVERLAY } from 'shared/styles/base';

const progress = keyframes`
  from {
    transform: scaleX(0);
  }

  60% {
    transform: scaleX(0.8);
  }

  to {
    transform: scaleX(1);
  }
`;

const BaseButton = styled.button`
  display: flex;
  width: 100%;
  border: 0;
  background: #000;
  height: 50px;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  outline: none;
  cursor: pointer;

  &:not([disabled]):hover {
    background: ${({ theme }) => theme.colors.textSecondary};
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    background: ${({ theme }) => theme.colors.textOutline};
  }

  &.in-progress {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      color: #fff;
      left: 0;
      background: ${BG_BUTTON_OVERLAY};
      mix-blend-mode: soft-light;
      width: 90%;
      animation: ${progress} 2s linear;
      transform-origin: top left;
    }
  }
`;

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  inProgress?: boolean;
  className?: string;
}

function Button(
  { children, inProgress = false, className, ...rest }: IButtonProps,
  ref: React.LegacyRef<HTMLButtonElement>
) {
  const btnClass = [className !== undefined && className, inProgress && 'in-progress']
    .filter(Boolean)
    .join(' ');

  return (
    <BaseButton disabled={inProgress} className={btnClass} ref={ref} {...rest}>
      {children}
    </BaseButton>
  );
}

export default memo(forwardRef<HTMLButtonElement, IButtonProps>(Button));
