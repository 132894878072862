import { createContext, useContext } from 'react';

interface IAuthContext {
  isAuthModalVisible: boolean;
  showAuthModal: (url?: string) => void;
  passiveShowAuthModal: (url?: string) => void;
  showBottomSheetAuth: (url?: string, disableTruecaller?: boolean) => void;
  setBottomSheetCallbacks: (callbacks: { [key: string]: VoidFunction }) => void;
}

const initialValue: IAuthContext = {
  isAuthModalVisible: false,
  showAuthModal: () => {
    /** DO NOTHING */
  },
  passiveShowAuthModal: () => {
    // do nothing
  },
  showBottomSheetAuth: () => {
    /** DO NOTHING */
  },
  // eslint-disable-next-line
  setBottomSheetCallbacks: (callbacks) => {
    /** DO NOTHING */
  },
};

const AuthModalWrapperContext = createContext(initialValue);

export const useAuthModalWrapperContext = () => useContext(AuthModalWrapperContext);

export default AuthModalWrapperContext;
