// libs
import React, { memo, useEffect } from 'react';
import { styled } from '@nykaa/ui-components';
// components
import Button from 'shared/components/Button';
// constants
import { TEXT } from './constants';
// analytics
import { onPageLoad } from './analytics';
// icon
import AlertNegativeFilled from '@nykaa/ui-components/Icons/alert-negative-filled';
import { COLORS } from 'shared/styles/base';
const AlertNegativeIconSize = '36px';
// styles
const Container = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: 80px;
`;
const AlertNegativeFilledIcon = styled(AlertNegativeFilled)`
  margin-bottom: ${({ theme }) => theme.spacing.spacing50};
`;
const Info = styled.p`
  ${({ theme }) => theme.typography.type201};
  margin: ${({ theme }) => `${theme.spacing.spacing200} 0`};
`;

interface ISomethingWentWrongProps {
  onBack?: VoidFunction;
  statusCode?: string;
}

function SomethingWentWrong({ onBack, statusCode = '' }: ISomethingWentWrongProps) {
  const clickHandler = () => {
    if (onBack) {
      onBack();
    } else {
      window.location.href = '/';
    }
  };
  useEffect(() => {
    onPageLoad(statusCode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container data-test={'something-went-wrong'}>
      <AlertNegativeFilledIcon color={COLORS.BLACK_100} size={AlertNegativeIconSize} />
      <Info className="info">{TEXT.INFO}</Info>
      <Button onClick={clickHandler}>{TEXT.BACK_LINK_LABEL}</Button>
    </Container>
  );
}
export default memo(SomethingWentWrong);
