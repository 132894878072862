// helpers
import { log } from 'shared/helpers/newRelic';
import { TAG_IDENTIFIER, ERROR_TAGS } from 'shared/helpers/SentryLogger/constants';

const logError = () => {
  if (__SERVER__) {
    return;
  }

  const logging_info = {
    message: 'Mandatory Params Missing',
    tags: { [TAG_IDENTIFIER.MODULE]: ERROR_TAGS.MANDATORY_PARAM_MISSING },
    extraInfo: {
      reference: document.referrer,
    },
  };

  log({
    errTitle: 'Error in Search helper',
    err: logging_info,
  });
};

export const isValidUrl = (search: string) => {
  try {
    const sParams = new URLSearchParams(search);
    const validity = sParams.has('q');

    if (validity === false) {
      logError();
    }

    return validity;
  } catch (err) {
    logError();
  }
};
