// helpers
import NFA from 'shared/helpers/analytics/NFAnalytics';
import { ERROR_EVENTS } from './constants/errors';

interface TrackInlineErrorMessage {
  statusCode: string;
  message: string;
}
export const trackInlineErrorMessage = ({ statusCode, message }: TrackInlineErrorMessage) => {
  if (!statusCode) return;

  NFA.track({
    event: ERROR_EVENTS.ERROR_INLINE_MESSAGES,
    data: {
      errorCode: statusCode !== '' ? statusCode : undefined,
      errorMessage: message,
    },
  });
};
