// libs
import { connect } from 'react-redux';

// selectors
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';

// component
import InitializeExperiment from './InitializeExperiment';

const mapStateToProps = (state: any) => ({
  experimentConfig: getRemoteConfig(state, 'AB_V2'),
  isExperimentV2Enabled: getRemoteConfig(state, 'isExperimentV2Enabled'),
});

export default connect(mapStateToProps)(InitializeExperiment);
