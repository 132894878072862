// libs
import { createContext, useContext } from 'react';

interface ILoaderContext {
  showLoader: (initProps: InitLoaderProps) => void;
  hideLoader: VoidFunction;
}

export interface InitLoaderProps {
  top?: string;
  left?: string;
  right?: string;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

const initialValue: ILoaderContext = {
  showLoader: () => {},
  hideLoader: EMPTY_FUNC,
};

const loaderContext = createContext(initialValue);

export const useLoaderContext = () => useContext(loaderContext);

export default loaderContext;
