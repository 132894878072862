// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// components
import RippleIt from 'shared/components/RippleIt';

// contexts
import { useMobileActionContext } from 'shared/components/Header/MobileAction/Context';

// HOC
import withExperiment from 'shared/components/Header/MobileHeader/components/A2hs/withExperiment';

const TEXT = 'Get Nykaa Fashion App like experience';
const ADD_TO_HOME_SCREEN = 'Add to home screen';
const IMAGE_SRC =
  'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/mobile_screen_dummy.svg';

// styles
const Container = styled.section`
  width: 100%;
  background-color: #d4d8de;
  padding: ${({ theme }) => `${theme.spacing.spacing20} ${theme.spacing.spacing20}`};

  .content {
    padding: ${({ theme }) =>
      `${theme.spacing.spacing50} ${theme.spacing.spacing200} ${theme.spacing.spacing50} ${theme.spacing.spacing80}`};
    color: ${({ theme }) => theme.colors.textPrimary};
    ${({ theme }) => theme.typography.subTitleLarge};
    letter-spacing: 0.5px;
    border: solid 1px #ffffff;
    position: relative;
    width: calc(100% - 19px);

    .dummyImage {
      position: absolute;
      right: -25px;
      top: 8px;
      background-color: #d4d8de;
    }

    .button {
      border: solid 1px ${({ theme }) => theme.colors.textOutline};
      background-color: #ffffff;
      margin: ${({ theme }) => `${theme.spacing.spacing40} 0 0 0`};
      padding: ${({ theme }) => `${theme.spacing.spacing20} ${theme.spacing.spacing80}`};
      text-align: center;
    }
  }
`;

function A2hsMMContent() {
  const { addToHomeClickHandler, canShowA2hsContent } = useMobileActionContext();

  const clickHandler = () => addToHomeClickHandler('leftMenu');

  if (canShowA2hsContent === false) {
    return null;
  }

  return (
    <RippleIt
      onClick={clickHandler}
      className="a2hs-container"
      data-test="a2hs-container-mm"
      preventDefault
    >
      <Container>
        <section className="content">
          {TEXT}
          <p className="button">{ADD_TO_HOME_SCREEN}</p>
          <img
            className="dummyImage"
            src={IMAGE_SRC}
            width={49}
            height={100}
            alt={ADD_TO_HOME_SCREEN}
          />
        </section>
      </Container>
    </RippleIt>
  );
}

export default withExperiment(memo(A2hsMMContent));
