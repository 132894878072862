// helpers
import { ApiHelper } from 'shared/helpers/api';
import { getUrl, ROUTES } from 'shared/api/urls';
import { getDomainConfig } from 'shared/domainConfig/helpers';

//defs
interface DomainConfig {
  [key: string]: string;
}

// constants
const DOMAIN_CONFIG: DomainConfig = {
  NYKAA_FASHION: 'fashion',
  FOOT_LOCKER: 'FOOT_LOCKER',
};

export const fetchDynamicPageConfig = (slug: string) => {
  const url = getUrl(ROUTES.DYNAMIC_PAGE_CONFIG);
  const params = new URLSearchParams();
  const { API } = getDomainConfig();

  params.set('slug', slug);
  params.set('domain', DOMAIN_CONFIG[API.DOMAIN_PARAM]);

  return ApiHelper(`${url}?${params.toString()}`)
    .then((data: Response) => {
      if (!data.ok) {
        return Promise.reject({ statusCode: data?.status, message: data.statusText });
      }

      return data.json();
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
