// libs
import React, { memo } from 'react';

// components
import ToastWrapper from 'shared/components/Toast';
import Toast from 'shared/components/Boot/components/SuccessLoginToast';

interface AppProviderProps {
  children: React.ReactNode;
}

function AppProvider({ children }: AppProviderProps) {
  return (
    <ToastWrapper>
      {children}
      <Toast />
    </ToastWrapper>
  );
}

export default memo(AppProvider);
