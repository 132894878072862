// libs
import React from 'react';

// global components
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import ChunkFallback from 'shared/components/ChunkFallback';

// components
import AppPromoPageMobile from 'shared/layouts/AppPromoPage/Mobile';
import AppPromoPageDesktop from 'shared/layouts/AppPromoPage/Desktop';

function AppPromoPage() {
  return (
    <RouteChunkRenderer
      mobileComponent={<AppPromoPageMobile fallback={<ChunkFallback />} />}
      desktopComponent={<AppPromoPageDesktop fallback={<ChunkFallback />} />}
    />
  );
}

export default AppPromoPage;
