import { ApiHelper } from 'shared/helpers/api';
import { getUrl, ROUTES } from 'shared/api/urls';
import { getNewCartHeader } from 'shared/api/helpers';

export const getLoggedInUser = (): Promise<any> => {
  const url = getUrl(ROUTES.GET_CUSTOMER);
  return ApiHelper(url, {
    method: 'POST',
    headers: getNewCartHeader(),
  }).then((data: Response) => data.json());
};

export const logoutUser = (): Promise<any> => {
  return ApiHelper(getUrl(ROUTES.LOGOUT), {
    method: 'POST',
    isBodyJSON: true,
  }).then(async (data: Response) => {
    if (data.status !== 200) {
      return Promise.reject(data.statusText);
    }
    return await data.json();
  });
};
