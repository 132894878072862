// libs
import React, { memo } from 'react';

// components
import WishlistMobile from 'shared/layouts/Wishlist/Mobile';

function Wishlist() {
  return <WishlistMobile />;
}

export default memo(Wishlist);
