// libs
import { styled } from '@nykaa/ui-components';

// constants
import {
  BREAKPOINTS,
  MWEB_HEADER_DISCOUNT_HEIGHT,
  DWEB_HEADER_HEIGHT,
  FONTS,
} from 'shared/styles/base';

// styles
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - ${MWEB_HEADER_DISCOUNT_HEIGHT}px);
  padding: ${({ theme }) => theme.spacing.spacing80};
  font-family: ${FONTS.BASE_FAMILY};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: calc(100vh - ${DWEB_HEADER_HEIGHT}px);
  }
`;

export const ImageContainer = styled.div`
  height: 216px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => `${theme.spacing.spacing80} 0`};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: 300px;
  }
`;

export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
`;
