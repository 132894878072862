//libs
import React, { memo, useState, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

//styles
import { RippleContainer, RippleChild } from './styles';

//types
import { IRippleProps } from './types';

function RippleIt({
  as = 'div',
  animationDuration = '0.3s',
  onClick,
  children,
  doClickDelay = 300,
  preventDefault = false,
  rippleShade = 'hsla(0, 0%, 78%, 0.6)',
  ...props
}: IRippleProps) {
  const [showRipple, updateRipple] = useState(false);
  const [rippleStyles, updateRippleStyles] = useState({});
  const elRef = useRef<HTMLElement | HTMLDivElement>(null);

  const onElementClick: React.MouseEventHandler<HTMLElement | HTMLDivElement> = (event) => {
    if (preventDefault) {
      event.preventDefault();
    }

    const target = elRef.current || event.target;

    if (isEmpty(target)) {
      return;
    }

    // @ts-ignore
    const { left, top } = target.getBoundingClientRect();
    const X = event.pageX - (left + window.pageXOffset);
    const Y = event.pageY - (top + window.pageYOffset);

    const rippleAnimationStyles = {
      top: `${Y}px`,
      left: `${X}px`,
      animationDuration,
    };

    updateRippleStyles(rippleAnimationStyles);
    updateRipple(true);

    setTimeout(() => {
      onClick(target);
    }, doClickDelay);
  };

  return (
    <RippleContainer
      as={as}
      onClick={onElementClick}
      data-test="ripple-container"
      ref={elRef}
      {...props}
    >
      {children}
      {showRipple && (
        <RippleChild
          style={rippleStyles}
          onAnimationEnd={() => updateRipple(false)}
          data-test="ripple-animation"
          rippleShade={rippleShade}
        />
      )}
    </RippleContainer>
  );
}

export default memo(RippleIt);
