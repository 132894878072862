export enum PDP_EXPT_VARIANT {
  DEFAULT = 'pdp',
  HYBRID_PDP = 'hp',
  HYBRID_PDP_VARIANT2 = 'hc',
}

interface CalculateExperimentVariantProps {
  search: string;
  control: {
    enabled: boolean;
    hybridPDP: number;
    hybridPDPVariant2: number;
    queryParamConfig: {
      name: string;
      includedKeywords: string[];
      excludedKeywords: string[];
    }[];
  };
}

const generateBucket = (hybridPDPBucketSize: number, hybridPDPVariantBucketSize: number) => {
  return {
    hybridPDP: { from: 0, to: hybridPDPBucketSize },
    hybridPDPVariant: {
      from: hybridPDPBucketSize + 1,
      to: hybridPDPVariantBucketSize + hybridPDPBucketSize,
    },
  };
};

const checkURLEligibility = ({ search, control }: CalculateExperimentVariantProps) => {
  const { queryParamConfig } = control;
  const searchParams = new URLSearchParams(search);

  let isEligibleURL = true;

  // validate if the url specified follows the controls mentioned in remote config
  // eg. queryParams: includes 'str1', excludes 'str1'
  //      set isEligibleURL to false, if query string fails the required query params:
  //            url must include 'str1' and must not include 'str2'
  //
  // each iteration of loop represents one set of query param conditions to be fulfilled
  queryParamConfig.forEach((paramConfig) => {
    const { name, includedKeywords, excludedKeywords } = paramConfig;
    const queryParamValue = searchParams.get(name)?.toLowerCase();

    let isQueryParamSatisifed = true;

    // ineligible if the query param does not exist
    if (!queryParamValue) isQueryParamSatisifed = false;

    // check if the url contains any of the specified keywords to be included, and mark it eligible
    let includedKeywordsCheck = false;
    if (includedKeywords.length) {
      includedKeywords.forEach((item) => {
        includedKeywordsCheck = includedKeywordsCheck || Boolean(queryParamValue?.includes(item));
      });
    }

    isQueryParamSatisifed = isQueryParamSatisifed && includedKeywordsCheck;

    // check if the url contain any of the specifed keywords to be excluded, and mark it ineligible
    let excludedKeywordCheck = false;
    if (excludedKeywords.length) {
      excludedKeywords.forEach((item) => {
        excludedKeywordCheck = excludedKeywordCheck || Boolean(queryParamValue?.includes(item));
      });
    }

    isQueryParamSatisifed = isQueryParamSatisifed && !excludedKeywordCheck;

    // update eligibility value after each iteration for the specified query param key
    isEligibleURL = isEligibleURL && isQueryParamSatisifed;
  });

  return isEligibleURL;
};

const getExperimentVariant = (hybridPDPBucketSize: number, hybridPDPVariantBucketSize: number) => {
  // generate a random number between 1 and 100
  const userRandomVariantIdentifier = Math.random() * 100;

  const { hybridPDP, hybridPDPVariant } = generateBucket(
    hybridPDPBucketSize,
    hybridPDPVariantBucketSize
  );

  // determine user bucket and hence return experiment value
  if (userRandomVariantIdentifier <= hybridPDP.to) return PDP_EXPT_VARIANT.HYBRID_PDP;

  if (
    userRandomVariantIdentifier > hybridPDPVariant.from &&
    userRandomVariantIdentifier <= hybridPDPVariant.to
  ) {
    return PDP_EXPT_VARIANT.HYBRID_PDP_VARIANT2;
  }

  // return default pdp at the end of expt and no conclusive result in the process
  return PDP_EXPT_VARIANT.DEFAULT;
};

export const calculateExperimentVariant = ({
  search,
  control,
}: CalculateExperimentVariantProps) => {
  try {
    // calculate sample rate as:
    // hybridPDP: 10 implies bucket size of 10, and sample rate: 0-10
    // hybridPDPVariant2: 10 implies bucket size of 10 over hybrid pdp, and then final sample rate: 11-20
    const {
      enabled,
      hybridPDP: hybridPDPBucketSize,
      hybridPDPVariant2: hybridPDPVariantBucketSize,
      queryParamConfig,
    } = control;

    // validate experiment config: show default layout if
    //      experiment is disabled
    //      both the variants have no assigned traffic
    //      no query param avaiable in the url
    if (
      !enabled ||
      (!hybridPDPBucketSize && !hybridPDPVariantBucketSize) ||
      queryParamConfig.length === 0
    ) {
      return { isEligible: false, variant: PDP_EXPT_VARIANT.DEFAULT };
    }

    // check url eligibility if the config is enabled
    const isEligibleURL = checkURLEligibility({ search, control });

    // if ineligible, return default pdp layout
    if (!isEligibleURL) return { isEligible: false, variant: PDP_EXPT_VARIANT.DEFAULT };

    return {
      isEligible: true,
      variant: getExperimentVariant(hybridPDPBucketSize, hybridPDPVariantBucketSize),
    };
  } catch (error) {
    // in case of failure, return default pdp
    return { isEligible: false, variant: PDP_EXPT_VARIANT.DEFAULT };
  }
};
