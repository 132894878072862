// helpers
import { detectDeviceByRegex } from 'server/helpers/deviceUtility';

// constants
import { WEB_APP_TYPE } from 'shared/constants/index';

const WEB_APP_COFIG = [
  { regex: /nykaa-android/i, value: WEB_APP_TYPE.ANDROID },
  { regex: /nykaa-ios/i, value: WEB_APP_TYPE.IOS },
  { regex: /mobile|phone|ipad/i, value: WEB_APP_TYPE.MWEB },
];

export const getWebAppType = (agent?: any) => {
  try {
    let userAgent = agent;

    if (!agent && __BROWSER__) {
      userAgent = navigator.userAgent || navigator.vendor || window.opera;
    }

    return detectDeviceByRegex(userAgent, WEB_APP_COFIG, WEB_APP_TYPE.DESKTOP);
  } catch (error) {
    return WEB_APP_TYPE.DESKTOP;
    //log exception
  }
};
