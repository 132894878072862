//libraries
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

//component
import Listing from 'shared/routes/Listing';
import NotFound from 'shared/components/NotFound';

//def
import { IListingProps } from 'shared/routes/Listing/types';

// redux store
import { updateStatusCode } from 'shared/store/app/actions';
import {
  getDynamicPageTitle,
  getIsDynamicPageDataFetched,
  getIsDynamicPageDataFetching,
} from 'shared/store/dynamicPage/selector';

function DynamicPage(props: IListingProps) {
  const isFetching = useSelector(getIsDynamicPageDataFetching);
  const pageTitle = useSelector(getDynamicPageTitle);
  const isDynamicDataFetched = useSelector(getIsDynamicPageDataFetched);
  const dispatch = useDispatch();

  if (!isFetching && isDynamicDataFetched && !pageTitle) {
    dispatch(updateStatusCode(404));
    return <NotFound />;
  }

  return <Listing {...props} isSearch />;
}

export default DynamicPage;
