export enum CUSTOM_EVENTS {
  SET_USER = 'SET_USER',
  SET_CART = 'SET_CART',
  VIEW_DETAIL_CLICKED = 'VIEW_DETAIL_CLICKED',
  PROCEED_TO_BUY_CLICKED = 'PROCEED_TO_BUY_CLICKED',
  EDIT_ITEM_CLICKED = 'EDIT_ITEM_CLICKED',
  CHANGE_QUANITY_CLICKED = 'CHANGE_QUANITY_CLICKED',
  CHANGE_SIZE_CLICKED = 'CHANGE_SIZE_CLICKED',
  REMOVE_ITEM_CLICKED = 'REMOVE_ITEM_CLICKED',
  UNDO_DELETE_ITEM_CLICKED = 'UNDO_DELETE_ITEM_CLICKED',
  UNDO_APPLY_COUPON = 'UNDO_APPLY_COUPON',
  CART_APPLY_COUPON_CLICKED = 'CART_APPLY_COUPON_CLICKED',
  APPLY_COUPON_CLICKED = 'APPLY_COUPON_CLICKED',
  REMOVE_COUPON_CLICKED = 'REMOVE_COUPON_CLICKED',
  SHIPPING_INFO_CLICKED = 'SHIPPING_INFO_CLICKED',
  ADDRESS_CHANGE_CLICKED = 'ADDRESS_CHANGE_CLICKED',
  ADDRESS_ADD_NEW_CLICKED = 'ADDRESS_ADD_NEW_CLICKED',
  LIST_ADDRESS_SELECTED = 'LIST_ADDRESS_SELECTED',
  LIST_ADDRESS_EDIT_CLICKED = 'LIST_ADDRESS_EDIT_CLICKED',
  LIST_ADDRESS_SAVE_CLICKED = 'LIST_ADDRESS_SAVE_CLICKED',
  EDIT_ADDRESS_SAVE_CLICKED = 'EDIT_ADDRESS_SAVE_CLICKED',
  EDIT_ADDRESS_CANCEL_CLICKED = 'EDIT_ADDRESS_CANCEL_CLICKED',
  ADD_ADDRESS_SAVE_CLICKED = 'ADD_ADDRESS_SAVE_CLICKED',
  ADD_ADDRESS_CANCEL_CLICKED = 'ADD_ADDRESS_CANCEL_CLICKED',
  GUEST_LOGIN_CLICKED = 'GUEST_LOGIN_CLICKED',
  MESSAGE_REMOVE_ITEMS_CLICKED = 'MESSAGE_REMOVE_ITEMS_CLICKED',
  PRODUCT_IMAGE_CLICKED = 'PRODUCT_IMAGE_CLICKED',
  BACK_BUTTON_CLICKED = 'BACK_BUTTON_CLICKED',
}

export enum SCREENS {
  CART = 'CART',
  ADDRESS = 'ADDRESS',
  ADDRESS_LIST = 'ADDRESS_LIST',
  EDIT_ADDRESS = 'EDIT_ADDRESS',
  ADD_ADDRESS = 'ADD_ADDRESS',
  COUPONS = 'COUPONS',
}

export const TYPE = {
  EVENT: 'event',
  DATA_PUSH: 'data_push',
};

// constants
export const IDENTIFY = 'identify';
export const PAGE_VIEW = 'pageView';
export const RESET = 'reset';
export const PERSISTENT_DATALAYER = 'gtm_datalayer';
export const SET_USER_PROPERTY = 'set_user_property';
export const NF_CURRENT_URL = 'nfCurrentPageUrl';
export const NF_PREVIOUS_URL = 'nfPreviousUrl';
export const FIRST_PAGE_VIEW = 'firstPageView';
export const MP_USER_IDENTIFIED = 'MP_USER_IDENTIFIED';
export const REVIEW_PAGE_VIEW = 'reviewPage';

export const DEVICE_TYPE = {
  MWEB: 'mweb',
  DESKTOP: 'desktop',
};

export enum TRACK_TYPE {
  EVENT = 'event',
  PAGELOAD = 'pageload',
  IMPRESSION = 'impression',
}

export const PRODUCT_INTERACTION = {
  LISTING_PRODUCT: 'listingProduct',
  RECO_WIDGET: 'recoWidget',
  CART_PAGE: 'cartPage',
  TIP_TILE: 'tipTile',
  WISHLIST_PRODUCT: 'wishlistProduct',
};

export const GLOBAL_EVENTS = {
  MP_BOTTOM_NAV_CLICKED: 'bottom_nav_clicked',
  GENERIC_PAGE_VIEW: 'genericPageView',
  PRODUCT_CLICK: 'productClick',
};

export const IMPRESSION_CONFIG = {
  IMPRESSION_INTERVAL: 2000,
  IMPRESSION_BATCH_SIZE: 8,
};
