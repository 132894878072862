// libs
import { memo, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';

// analytics
import { sendPageView } from './analytics';

const EXCLUDED_PATHS = [
  '/', // Home
  '/:slug/p/:id', // PDP
  '/*/c/:id', // PLP
  '/offer.html', // offer
  '/lp/:slug', // landing page
  '/catalogsearch/result', // search page
  '/v2/checkout', // cart page
];

function Pageload() {
  const { pathname } = useLocation();

  useEffect(() => {
    // exclude pages for which we are already sending the
    // pageview independently
    const isMatched = EXCLUDED_PATHS.some((path) =>
      matchPath(pathname, {
        path,
        exact: true,
        strict: false,
      })
    );

    if (isMatched) {
      return;
    }

    sendPageView();
  }, [pathname]);

  return null;
}

export default memo(Pageload);
