// libs
import { css, keyframes } from '@emotion/core';
import { styled } from '@nykaa/ui-components';

// constants
import { BREAKPOINTS } from 'shared/styles/base';
import { ALIGNMENT_TYPES } from 'shared/components/Toast/constants';

const slideUp = (positionY: number) => {
  return keyframes`
  from {
    transform: translateX(-50%) translateY(150%);
  }

  to {
    transform: translateX(-50%) translateY(-${positionY}px);
  }
`;
};

const slideDown = (positionY: number) => {
  return keyframes`
  from {
    transform: translateX(-50%) translateY(${-positionY}px);
  }

  to {
    transform: translateX(-50%) translateY(150%);
  }
`;
};

export const ToastContainer = styled.div<{ hide: boolean; textAlign: ALIGNMENT_TYPES }>`
  align-items: center;
  border-radius: ${({ theme }) => theme.borders.radius10};
  bottom: 0px;
  display: flex;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  z-index: 10;
  animation: ${slideUp(80)} 0.35s ease forwards;
  padding: ${({ theme }) => `${theme.spacing.spacing60}`};
  background-color: ${({ theme }) => theme.colors.surfaceInverse30};
  ${({ textAlign }) => `justify-content: ${textAlign}`};

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    min-width: 95%;
  }

  ${({ hide }) =>
    hide &&
    css`
      animation: ${slideDown(80)} 0.35s ease forwards;
    `}
`;

export const Figure = styled.figure<{ fillColor: string }>`
  margin: ${({ theme }) => `0 ${theme.spacing.spacing50} 0 0`};
  opacity: 1;
  ${({ theme }) => theme.typography.bodyLarge};
  display: flex;
  align-items: center;

  svg path {
    fill: ${({ fillColor }) => fillColor};
  }
`;

export const Info = styled.p`
  color: #fff;
  ${({ theme }) => theme.typography.bodyLarge};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    ${({ theme }) => theme.typography.bodyLarge};
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    max-width: 100%;
  }
`;
