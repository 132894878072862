// libs
import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

// defs
import { IContainerHookProps } from './types';

// helpers
import { getSessionInfo, setSessionInfo, updateSessionInfo } from './helpers';

export const useShouldShowNudge = ({
  firstBreakpoint,
  ttlBeforeFirstBreakpoint,
  ttlAfterFirstBreakpoint,
  maxTimesToShowNudge,
  isValid,
  isEnabledForDesktop,
  isEnabledForMobile,
  isDesktop,
}: IContainerHookProps) => {
  const [showNudge, setShowNudge] = useState(false);

  useEffect(() => {
    const data = getSessionInfo();

    if (isDesktop === true && isEnabledForDesktop === false) {
      setShowNudge(false);
      return;
    }

    if (isDesktop === false && isEnabledForMobile === false) {
      setShowNudge(false);
      return;
    }

    if (isValid === false) {
      setShowNudge(false);
      return;
    }

    if (isEmpty(data)) {
      //set data in local storage and not to show in first time
      setSessionInfo(ttlBeforeFirstBreakpoint);
      setShowNudge(false);
    } else {
      try {
        //@ts-ignores
        const prevValue = JSON.parse(data);
        const currentTime = new Date();

        if (
          currentTime.getTime() > prevValue.expiry &&
          prevValue.shown_time <= maxTimesToShowNudge
        ) {
          // @ts-ignore
          window.requestIdleCallback(() => {
            setShowNudge(true);
          });
          updateSessionInfo({
            currentTime,
            prevValue,
            firstBreakpoint,
            ttlBeforeFirstBreakpoint,
            ttlAfterFirstBreakpoint,
          });
        } else {
          setShowNudge(false);
        }
      } catch (err) {
        // DO nothing
      }
    }
  }, [
    firstBreakpoint,
    maxTimesToShowNudge,
    ttlAfterFirstBreakpoint,
    ttlBeforeFirstBreakpoint,
    isValid,
    isEnabledForDesktop,
    isEnabledForMobile,
    isDesktop,
  ]);

  const onNudgeHide = () => {
    setShowNudge(false);
  };

  return [showNudge, onNudgeHide];
};
