// libs
import isEmpty from 'lodash/isEmpty';

// constants
import { MP_USER_IDENTIFIED, PERSISTENT_DATALAYER, TRACK_TYPE } from './constants';

export const getPersistentData = () => {
  try {
    return JSON.parse(sessionStorage.getItem(PERSISTENT_DATALAYER) || '{}');
  } catch (err) {
    return {};
  }
};

export const removePersistentData = (type: string) => {
  try {
    if (type == TRACK_TYPE.PAGELOAD) {
      sessionStorage.removeItem(PERSISTENT_DATALAYER);
      return;
    }

    let data = getPersistentData();
    if (type === TRACK_TYPE.EVENT) {
      data.event = {};
    } else {
      data.pageload = {};
    }

    sessionStorage.setItem(PERSISTENT_DATALAYER, JSON.stringify(data || {}));
  } catch (err) {
    //
  }
};

export const updatePersistentData = (data: { [key: string]: any }, updateBefore: string) => {
  try {
    const prevData = getPersistentData();

    if (updateBefore === TRACK_TYPE.EVENT) {
      prevData.event = { ...prevData.event, ...data };
    } else {
      prevData.pageload = { ...prevData.pageload, ...data };
    }
    const mergedData = JSON.stringify(prevData);
    sessionStorage.setItem(PERSISTENT_DATALAYER, mergedData);
  } catch (err) {
    //
  }
};

export const setUserIdentified = () => {
  try {
    sessionStorage.setItem(MP_USER_IDENTIFIED, JSON.stringify(true));
  } catch (err) {
    //
  }
};

export const getUserIdentified = () => {
  try {
    return sessionStorage.getItem(MP_USER_IDENTIFIED) === 'true';
  } catch (err) {
    return false;
  }
};
export const removeUserIdentified = () => {
  try {
    sessionStorage.removeItem(MP_USER_IDENTIFIED);
  } catch (err) {
    //
  }
};

export const getPageInfoFromURL = (url = '') => {
  if (url === '') return { pagename: '', pagetype: '' };

  let path = '';
  try {
    path = new URL(url).pathname;
  } catch (err) {
    return { pagename: '', pagetype: '' };
  }

  var listingPageRegex = new RegExp('/c/');

  var detailsPageRegex = new RegExp('/p/');
  var landingPageRegex = new RegExp('/lp/');
  var offerPageRegex = new RegExp('offer.html');
  var searchPageRegex = new RegExp('/catalogsearch/result');
  var wishlistPageRegex = new RegExp('/wishlist');
  var accountPageRegex = new RegExp('/my-account');
  var cartPageRegex = new RegExp('/v2/checkout');
  var couponPageRegex = new RegExp('/v2/checkout/coupons');
  var explorePageRegex = new RegExp('/explore');
  var shopPage = new RegExp('/mega-menu-shop');
  var widgetListingPage = new RegExp('/widget-listing');
  var reviewListingPageRegex = new RegExp('/all-reviews');
  var hybridPDPRegex = new RegExp('/hp/');
  var hybridPDPVariantRegex = new RegExp('/hc/');

  if (path === '/') {
    return { pagename: 'homePage', pagetype: 'homePage' };
  }

  if (landingPageRegex.test(path)) {
    return { pagename: 'nativeLandingPage', pagetype: 'nativeLandingPage' };
  }

  if (shopPage.test(path)) {
    return { pagename: 'shopPage', pagetype: 'shopPage' };
  }

  if (searchPageRegex.test(path)) {
    return { pagename: 'productListingPage', pagetype: 'searchListingPage' };
  }

  if (widgetListingPage.test(path)) {
    return { pagename: 'widgetListingPage', pagetype: 'widgetListingPage' };
  }

  if (listingPageRegex.test(path)) {
    return { pagename: 'productListingPage', pagetype: 'categoryListingPage' };
  }

  if (detailsPageRegex.test(path)) {
    return { pagename: 'productDetailPage', pagetype: 'productDetailPage' };
  }

  if (wishlistPageRegex.test(path)) {
    return { pagename: 'wishlistPage', pagetype: 'wishlistPage' };
  }

  if (explorePageRegex.test(path)) {
    return { pagename: 'explorePage', pagetype: 'explorePage' };
  }

  if (couponPageRegex.test(path)) {
    return { pagename: 'couponPage', pagetype: 'couponPage' };
  }

  if (accountPageRegex.test(path)) {
    return { pagename: 'accountPage', pagetype: 'accountPage' };
  }

  if (cartPageRegex.test(path)) {
    return { pagename: 'cartPage', pagetype: 'cartPage' };
  }

  if (offerPageRegex.test(path)) {
    return { pagename: 'offerPage', pagetype: 'offerPage' };
  }

  if (reviewListingPageRegex.test(path)) {
    return { pagename: 'reviewPage', pagetype: 'reviewPage' };
  }

  if (hybridPDPRegex.test(path) || hybridPDPVariantRegex.test(path)) {
    return { pagename: 'hybridPDP', pagetype: 'hybridPDP' };
  }

  return { pagename: 'otherPage', pagetype: 'otherPage' };
};

// this function returns undefined value if the the given value is not a valid value or is empty
export const getUndefinedIfNotValid = (value: any) => {
  if (isEmpty(value)) {
    return undefined;
  }
  return value;
};

export const pushRetinaDataInDatalayer = (data: Record<string, string>) => {
  const datalayer = { ...window.datalayer };
  datalayer['analyticsSdkData'] = { ...datalayer.analyticsSdkData, ...data };
  window.datalayer = datalayer;
};

// this function returns the merged data with retina data to be sent to NFA.track function
export const getMergedDataWithRetinaData = (retinaData: { [key: string]: any }, data = {}) => {
  return { ...data, retinaObject: retinaData };
};
