// libs
import { createContext, useContext } from 'react';

//data
import JSON_LD_ORGANIZATION from 'shared/constants/JSON_LD/organization';
import JSON_LD_SEARCH from 'shared/constants/JSON_LD/search';
import { emptyImage as emptyCartUrl } from 'shared/styles/assets/images/empty-cart.png';

const EMPTY_OBJECT = {};

//will change interface accordingly

interface ColorConfig {
  MEGA_MENU: string;
  MEGA_MENU_TEXT: string;
  MEGA_MENU_BORDER_BOTTOM: string;
  PROMOTIONAL_BANNER: string;
  PROMOTIONAL_BANNER_TEXT: string;
  CART_COUNT: {
    BORDER: string;
    TEXT: string;
    BACKGROUND: string;
  };
  COUPON_CODE_BACKGROUND: string;
}
interface DomainConfig {
  APP_NAME: string;
  THEME: Record<any, any>;
  LOGO: Record<any, any>;
  FAVICON: string;
  PWA_ICONS: Record<number, string>;
  MY_ACCOUNT_DATA: Record<any, any>;
  ECOM_STRIP_ICONS: Record<any, any>;
  LOADER_CONFIG: { PAGE_LOADER: string; CHUNK_FALLBACK_CONFIG: string };
  CART_PAYMENT_ICONS: Record<any, any>;
  API_REDIRECTION_PATH: string;
  API: {
    DOMAIN_PARAM: string;
  };
  DN_STORE: string;
  COLOR: ColorConfig;
  MY_ACCOUNT_APP_DOWNLOAD: boolean;
  MEGA_MENU_VERSION: string;
  API_OLD: {
    DOMAIN_PARAM: string;
  };
  ABOUT_FOOTER_TEXT: string;
  GUEST_LOGIN_MESSAGE: string;
  LOGIN_TOAST_MESSAGE: {
    DWEB: string;
    MWEB: string;
  };
  DEFAULT_META: {
    title: string;
    description: string;
    keywords: string;
  };
  SHARE: {
    TEXT: string;
    PUBLIC_DOMAIN: string;
    CANONICAL_HOMEPAGE_URL: string;
  };
  COPYRIGHT: string;
  JSON_LD_ORG: string;
  JSON_LD_SEARCH: string;
  LOGIN_IMAGES: {
    STEP_1: string;
    STEP_2: string;
    STEP_3: string;
  };
  CART_IMAGES: {
    EMPTY_CART: string;
    UPDATE_CART: string;
  };
  PRODUCT_NOT_FOUND: string;
  EMPTY_WISHLIST_IMAGE: string;
  COUPONS_IMAGES: {
    REMOVE_COUPON: string;
    APPLY_COUPON: string;
    REMOVE_AND_APPLY: string;
    SOMETHING_WENT_WRONG: string;
    ERROR: string;
    COUPON_BACKGROUND_IMAGE: string;
  };
  ALL_BRANDS_DEFAULT_SELECTION: string;
  OTP_HELP_CENTER_URL: string;
}

const initialValue: DomainConfig = {
  APP_NAME: '',
  THEME: EMPTY_OBJECT,
  LOGO: EMPTY_OBJECT,
  FAVICON: '',
  PWA_ICONS: EMPTY_OBJECT,
  MY_ACCOUNT_DATA: EMPTY_OBJECT,
  ECOM_STRIP_ICONS: EMPTY_OBJECT,
  LOADER_CONFIG: { PAGE_LOADER: '', CHUNK_FALLBACK_CONFIG: '' },
  CART_PAYMENT_ICONS: EMPTY_OBJECT,
  API_REDIRECTION_PATH: 'gateway-api',
  API: {
    DOMAIN_PARAM: '',
  },
  COLOR: {
    MEGA_MENU: '#000000',
    MEGA_MENU_TEXT: '#FFFFFF',
    MEGA_MENU_BORDER_BOTTOM: '#FD7685',
    PROMOTIONAL_BANNER: '#000000',
    PROMOTIONAL_BANNER_TEXT: '#FFFFFF',
    CART_COUNT: {
      BORDER: '#000000',
      TEXT: '#000000',
      BACKGROUND: '#FFFFFF',
    },
    COUPON_CODE_BACKGROUND: '#e6f2ff',
  },
  MY_ACCOUNT_APP_DOWNLOAD: true,
  DN_STORE: '',
  MEGA_MENU_VERSION: 'V2',
  API_OLD: {
    DOMAIN_PARAM: '',
  },
  ABOUT_FOOTER_TEXT: 'About Nykaa Fashion',
  GUEST_LOGIN_MESSAGE:
    'Becoming a Nykaa Fashion member comes with easy order tracking, rewards, offers and more.',
  LOGIN_TOAST_MESSAGE: {
    DWEB: 'You have successfully logged in! Enjoy the shopping at Nykaa Fashion',
    MWEB: 'Dive into your Nykaa profile, coupons, & more',
  },
  DEFAULT_META: {
    title: 'Nykaa Fashion – Online Shopping of Clothes & Accessories from Top Designers',
    description:
      'Nykaa Fashion – Online Shopping Site for Designer Clothes, Accessories & Lifestyle Products in India. Shop at Best Prices &amp; Attractive Offers from India’s best Fashion Website.',
    keywords: 'online shopping, online shopping sites, online shopping websites, fashion',
  },
  SHARE: {
    TEXT: 'Check out this product I found on Nykaa Fashion:',
    PUBLIC_DOMAIN: 'nykaafashion.com',
    CANONICAL_HOMEPAGE_URL: 'https://www.nykaafashion.com',
  },
  COPYRIGHT: ` ${new Date().getFullYear()} Nykaa Fashion Ltd. All Rights Reserved`,
  JSON_LD_ORG: JSON_LD_ORGANIZATION,
  JSON_LD_SEARCH: JSON_LD_SEARCH,
  LOGIN_IMAGES: {
    STEP_1:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/mobileVerification_background_image.png',
    STEP_2:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/mobileVerification_background_image_2.png',
    STEP_3:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/mobileVerification_background_image_3.png',
  },
  CART_IMAGES: {
    EMPTY_CART: emptyCartUrl,
    UPDATE_CART:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/image-2022-01-13-16-16-17-704.png',
  },
  PRODUCT_NOT_FOUND:
    'https://images-static.nykaa.com/uploads/062a2cf8-ac6f-4ed1-81f0-3689961509d6.png?tr=w-256',
  EMPTY_WISHLIST_IMAGE:
    'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/emptyWishlist.jpg',
  COUPONS_IMAGES: {
    REMOVE_COUPON: 'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/remove.svg',
    APPLY_COUPON: 'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/apply.svg',
    REMOVE_AND_APPLY:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/remove_and_apply.svg',
    SOMETHING_WENT_WRONG:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/something_went_wrong.svg',
    ERROR: 'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/error%20state.svg',
    COUPON_BACKGROUND_IMAGE:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/Coupon_bg.svg',
  },
  ALL_BRANDS_DEFAULT_SELECTION: 'Popular Brands',
  OTP_HELP_CENTER_URL: '/help-center/topic/39',
};

const DomainConfigContext = createContext(initialValue);

export const useDomainConfig = () => useContext(DomainConfigContext);

export default DomainConfigContext;
