// libs
import React, { memo } from 'react';

// styles
import { Container, InnerLoader, BubbleContainer, Bubble, SpinningBubbles } from './styles';

// defs
interface ILoaderProps {
  fixed?: boolean;
  className?: string;
  loaderClassName?: string;
}

const DOT_COUNT = 8;

const Loader = ({ fixed = false, className = '', loaderClassName = '' }: ILoaderProps) => {
  const dots = (key: number) => (
    <BubbleContainer data-test="dots" key={key}>
      <Bubble />
    </BubbleContainer>
  );

  const dotsList = new Array(DOT_COUNT).fill(dots);

  return (
    <Container className={className} fixed={fixed} data-test="loader-cnt">
      <InnerLoader className={loaderClassName}>
        <SpinningBubbles>{dotsList.map((dot, i) => dot(i))}</SpinningBubbles>
      </InnerLoader>
    </Container>
  );
};

export default memo(Loader);
