// libs
import isEmpty from 'lodash/isEmpty';

// helpers
import Storage from 'shared/helpers/storage';

// constants
export const STORAGE_FORM_KEY_ID = 'USER_FORM_KEY';

let formKey = '';

export const storeUserFormKey = (formKeyParam: string) => {
  if (isEmpty(formKeyParam)) {
    Storage.removeItem(STORAGE_FORM_KEY_ID);
    return;
  }

  Storage.setItem(STORAGE_FORM_KEY_ID, formKeyParam);
  formKey = formKeyParam;
};

export const getStoredUserFormKey = () => {
  if (__SERVER__) {
    return '';
  }

  if (formKey !== '') {
    return formKey;
  }

  formKey = Storage.getItem(STORAGE_FORM_KEY_ID, '') || '';
  return formKey;
};
