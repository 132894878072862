// helpers
import NFAnalytics from 'shared/helpers/analytics/NFAnalytics';

// constants
import { TRACK_TYPE } from 'shared/helpers/analytics/constants';
import { ERROR_EVENTS } from 'shared/helpers/analytics/constants/errors';
import { TEXT } from './constants';

export const onPageLoad = (statusCode: string) => {
  NFAnalytics.track({
    data: {
      errorCode: statusCode !== '' ? statusCode : undefined,
      errorMessage: TEXT.INFO,
    },
    type: TRACK_TYPE.PAGELOAD,
    event: ERROR_EVENTS.ERROR_PAGE_LOADS,
  });
};
