// libs
import React from 'react';

// global components
import ChunkFallback from 'shared/components/ChunkFallback';

// component
import ReviewListMobile from 'shared/layouts/ReviewList/Mobile';

function AllReviews() {
  return <ReviewListMobile fallback={<ChunkFallback />} />;
}

export default AllReviews;
