// libs
import React, { memo } from 'react';

// styles
import { Image } from './styles';

// context
import { useDomainConfig } from 'shared/domainConfig/context/context';

// defs
interface ILogoProps {
  className?: string;
  type: string;
}

function Logo({ className, type }: ILogoProps) {
  const { LOGO } = useDomainConfig();
  const { WIDTH, HEIGHT, URL } = LOGO[type] || {};
  const { LOGO_ALT_TEXT } = LOGO;
  
  return (
    <Image
      width={WIDTH}
      height={HEIGHT}
      src={URL}
      className={className}
      alt={LOGO_ALT_TEXT}
    />
  );
}

export default memo(Logo);
