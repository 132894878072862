// libs
import get from 'lodash/get';
import { EVENT_NAMES, EVENT_TYPES } from 'shared/helpers/utils/impressions/constants';

const DEFAULT = {
  enabled: {
    mobile: true,
    desktop: false,
  },
  showPrice: true,
  showActionButton: true,
  wishlistName: 'Wishlist',
};

interface IWishlist {
  enabled: {
    mobile: boolean;
    desktop: boolean;
  };
  showPrice: boolean;
  showActionButton: boolean;
  wishlistName: string;
}

export const getWishlistEnabled = (config: IWishlist) => {
  try {
    return get(config, 'enabled', DEFAULT.enabled);
  } catch (e) {
    return DEFAULT.enabled;
  }
};

export const transformTrackingForWishlist = (
  trackingData: Record<string, any>,
  isAddedToWishlist: boolean
) => {
  return {
    ...trackingData,
    event: undefined,
    event_name: isAddedToWishlist ? EVENT_NAMES.REMOVE_FROM_WISHLIST : EVENT_NAMES.ADD_TO_WISHLIST,
    event_type: EVENT_TYPES.CLICK,
  };
};
