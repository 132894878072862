export const isAndroid = () => {
  try {
    let userAgent;

    if (__BROWSER__) {
      userAgent = navigator.userAgent || navigator.vendor || window.opera;
    } else {
      return false;
    }

    return /android/i.test(userAgent);
  } catch (error) {
    return false;
  }
};
