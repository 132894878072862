//libs
import React, { memo, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

//components
import ChunkFallback from 'shared/components/ChunkFallback';

function RedirectToHomePage() {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  useEffect(() => {
    window.requestAnimationFrame(() => {
      return window.requestAnimationFrame(() => {
        setShouldRedirect(true);
      });
    });
  }, []);
  if (shouldRedirect) {
    return <Redirect to={'/'} />;
  }
  return <ChunkFallback />;
}

export default memo(RedirectToHomePage);
