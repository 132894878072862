// libs
import React, { ComponentType } from 'react';

// global components
import Experiment, { Variant } from 'shared/components/Experiment';

// constants
const PWA_OPTIMIZE_ID = 'QvSOiwheTPmrNARdUGN4VQ';

function withExperiment(WrappedComponent: ComponentType<{}>) {
  function HOC() {
    return (
      <Experiment experimentId={PWA_OPTIMIZE_ID} defaultVariant="0" placeholder={null}>
        <Variant value="1">
          <WrappedComponent />
        </Variant>
      </Experiment>
    );
  }

  return HOC;
}

export default withExperiment;
