//libs
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

// api
import { addToWishlist } from 'shared/store/wishlist/services';

// storage
import {
  getStoredProductToBeAddedToWishlist,
  removeStoredProductToBeAddedToWishlist,
} from 'shared/components/Wishlist/storage';

// redux
import { updateShowAnimationForProductId as updateProductId } from 'shared/store/wishlist/actions';
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';
import { isLoggedIn } from 'shared/store/app/selectors';
import { fetchWishListIds } from 'shared/store/wishlist/services';

// helper
import { getWishlistEnabled } from 'shared/components/Wishlist/helper';
import { getStoredScrollY } from 'shared/helpers/utils/scroll';
import { getDeviceType, DEVICE_TYPE } from 'shared/helpers/app';

function WishlistInitialisation() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const deviceType = getDeviceType();
  const isLoggedInUser = useSelector(isLoggedIn);
  const wishlistConfig = useRemoteConfig('wishlist');
  const isWishlistEnabled = getWishlistEnabled(wishlistConfig);
  const getWishListIds = fetchWishListIds;

  const isDisabled =
    isLoggedInUser === false ||
    (deviceType === DEVICE_TYPE.MOBILE && isWishlistEnabled.mobile === false) ||
    (deviceType === DEVICE_TYPE.DESKTOP && isWishlistEnabled.desktop === false);

  useEffect(() => {
    if (isDisabled) {
      return;
    }
    dispatch(getWishListIds());
  }, [dispatch, getWishListIds, isDisabled]);

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const pendingId = getStoredProductToBeAddedToWishlist();

    if (pendingId === '') {
      return;
    }
    dispatch(addToWishlist({ itemId: pendingId })).then(() => {
      // scroll to the product which is added to wishlist
      const scroll = getStoredScrollY(pathname);
      window.scrollTo(0, Number(scroll));

      dispatch(updateProductId(pendingId));
      removeStoredProductToBeAddedToWishlist();
    });
  }, [dispatch, isDisabled, pathname]);

  return null;
}

export default WishlistInitialisation;
