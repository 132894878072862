// libs
import React, { memo, useEffect } from 'react';

// helpers
import { getVPConfig } from './helpers';

// styles
import { Container, ImageContainer, Img } from './styles';

function VPErrorScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const config = getVPConfig();

  return (
    <Container>
      <ImageContainer>
        <Img src={config.error_img_url} />
      </ImageContainer>
      <div dangerouslySetInnerHTML={{ __html: config.attributed_title }} />
      <div dangerouslySetInnerHTML={{ __html: config.error_msg }} />
    </Container>
  );
}

export default memo(VPErrorScreen);
