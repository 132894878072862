// libs
import { connect } from 'react-redux';

// selectors
import { getCustomerId, onIntlNetwork } from 'shared/store/app/selectors';
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';

// constants
const DEFAULT_ANALYTICS_SDK_CONFIG = {
  excludedEventNames: [],
  eventsThreshold: 20,
  batchInterval: 20000,
  maxSessionRefreshTime: 2,
  sessionExpiryTime: 6,
};

// component
import Initialize from './Initialize';

const mapStateToProps = (state: any) => {
  const analyticsSDKConfig = getRemoteConfig(state, 'analyticsSDK') || DEFAULT_ANALYTICS_SDK_CONFIG;
  return {
    customerId: getCustomerId(state),
    onIntlNetwork: onIntlNetwork(state),
    excludedEventNames: analyticsSDKConfig.excludedEventNames,
    eventsThreshold: analyticsSDKConfig.eventsThreshold,
    batchInterval: analyticsSDKConfig.batchInterval,
    maxSessionRefreshTime: analyticsSDKConfig.maxSessionRefreshTime,
    sessionExpiryTime: analyticsSDKConfig.sessionExpiryTime,
  };
};

export default connect(mapStateToProps)(Initialize);
