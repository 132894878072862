//libs
import React, { memo } from 'react';

//global components
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import ChunkFallback from 'shared/components/ChunkFallback';

//components
import ListingMobile from 'shared/layouts/Listing/ListingMobile';
import Desktop from 'shared/layouts/Listing/Desktop';

function OfferListing() {
  return (
    <RouteChunkRenderer
      mobileComponent={
        <ListingMobile isOffer={true} isSearch={false} fallback={<ChunkFallback />} />
      }
      desktopComponent={<Desktop isSearch={false} fallback={<ChunkFallback />} />}
    />
  );
}

export default memo(OfferListing);
