// libs
import React, { memo } from 'react';

// global components
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import ChunkFallback from 'shared/components/ChunkFallback';

// components
import ExptRouteHandler from './HybridPDPExpt';
import DetailDesktop from 'shared/layouts/DetailsPage/Desktop';

function Detail() {
  return (
    <RouteChunkRenderer
      mobileComponent={<ExptRouteHandler />}
      desktopComponent={<DetailDesktop fallback={<ChunkFallback />} />}
    />
  );
}

export default memo(Detail);
