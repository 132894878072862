//libs
import React, { memo } from 'react';

//global components
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import ChunkFallback from 'shared/components/ChunkFallback';

//components
import ListingMobile from 'shared/layouts/Listing/ListingMobile';
import Desktop from 'shared/layouts/Listing/Desktop';

//defs
import { IListingProps } from './types';

function Listing({ isSearch = false }: IListingProps) {
  return (
    <RouteChunkRenderer
      mobileComponent={<ListingMobile isSearch={isSearch} fallback={<ChunkFallback />} />}
      desktopComponent={<Desktop isSearch={isSearch} fallback={<ChunkFallback />} />}
    />
  );
}

export default memo(Listing);
