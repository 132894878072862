// libs
import React, { useEffect, memo, useState, useContext } from 'react';

// global components
import Portal from 'shared/components/Portal';

// contexts
import ModalContext from './ModalContext';
import ResponsiveContext from 'shared/components/ResponsiveLoader/ResponsiveContext';

// helpers
import { hideOverflowY, resetOverflowY } from 'shared/helpers/dom';

// styles
import { Container, Button, Overlay } from './styles';

// icons
import CloseIcon from '@nykaa/ui-components/Icons/close';

const ESCAPE_KEY = 27;

// defs
interface IModalProps {
  children: React.ReactNode;
  onEscapeHide?: boolean;
  onHide: VoidFunction;
  className?: string;
  innerClassName?: string;
  showCrossIcon?: boolean;
  allowBackdropClick?: boolean;
  dataAtValue?: string;
}

function Modal({
  onEscapeHide = true,
  children,
  onHide,
  className = '',
  innerClassName = '',
  showCrossIcon = true,
  allowBackdropClick = true,
  dataAtValue = 'modal-close',
}: IModalProps) {
  const [doHide, updateDoHide] = useState(false);
  const [canShowModal, updateShowModal] = useState(false);
  const [listenEscape] = useState(onEscapeHide);

  const { deviceWidth } = useContext(ResponsiveContext);

  const hide = () => updateDoHide(true);

  useEffect(() => {
    const onKeyEvent = (event: KeyboardEvent) => {
      if (event.which === ESCAPE_KEY) {
        hide();
      }
    };

    window.requestAnimationFrame(() => updateShowModal(true));
    hideOverflowY('body', false);
    listenEscape && document.addEventListener('keydown', onKeyEvent, { passive: true });

    return () => {
      resetOverflowY();
      listenEscape && document.removeEventListener('keydown', onKeyEvent);
    };
  }, [deviceWidth, listenEscape]);

  const afterAnimationEnd = () => doHide && onHide();

  const showModal = canShowModal && doHide === false;

  const backdropClick = allowBackdropClick
    ? hide
    : () => {
        /*Do nothing*/
      };

  return (
    <Portal>
      <Container showModal={showModal} className={className} onAnimationEnd={afterAnimationEnd}>
        <div className={innerClassName}>
          <ModalContext.Provider value={{ hide }}>
            {showCrossIcon && (
              <Button onClick={hide} data-test="btn-cross" data-at={dataAtValue}>
                <CloseIcon />
              </Button>
            )}
            {children}
          </ModalContext.Provider>
        </div>
      </Container>
      <Overlay hide={doHide} data-test="mdl-overlay" onClick={backdropClick} />
    </Portal>
  );
}

export default memo(Modal);
