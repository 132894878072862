// libs
import { useEffect } from 'react';
import { useHistory } from 'react-router';

// helpers
import { getHistoryLength, setHistoryLength } from 'shared/helpers/history';

const useAppHistory = () => {
  const history = useHistory();

  // maintain history stack length
  useEffect(() => {
    let historyLength = getHistoryLength();

    return history.listen((_, action) => {
      if (action === 'PUSH') {
        historyLength = historyLength + 1;
      } else if (action === 'POP') {
        historyLength = historyLength - 1;
      }

      setHistoryLength(historyLength);
    });
  }, [history]);
};

export default useAppHistory;
