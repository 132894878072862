import React, { useEffect, useState, useRef } from 'react';

function useA2hs() {
  const [canShowA2hsContent, setCanShowA2hsContent] = useState(false);
  let deferredPrompt: React.BeforeInstallPromptEvent = useRef(null);

  useEffect(() => {
    const beforeInstallPromptHandler = (e: React.BeforeInstallPromptEvent) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();

      // Update UI to notify the user they can add to home screen
      setCanShowA2hsContent(true);

      // Stash the event so it can be triggered later.
      deferredPrompt.current = e;
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  const addToHomeClickHandler = () => {
    if (deferredPrompt.current) {
      // Show the prompt
      deferredPrompt.current.prompt();

      // Wait for the user to respond to the prompt
      deferredPrompt.current.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // hide our user interface that shows our A2HS button
          setCanShowA2hsContent(false);
        }
        deferredPrompt.current = null;
      });
    }
  };

  return { canShowA2hsContent, addToHomeClickHandler };
}

export default useA2hs;
