// libs
import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@nykaa/ui-components';

// global components
import OverlaySheetBase, { SHEET_TRANSITION } from 'shared/components/OverlaySheet';

// components
import Cart from './components/Cart';

// redux actions
import { fetchUser as _fetchUser } from 'shared/store/app/services';

// styles
const OverlaySheet = styled(OverlaySheetBase)`
  background-color: #fff;
  height: 100%;
  width: 30%;
  max-width: 400px;
  background-color: #fff;
  left: initial;
  overflow-y: auto;
  overflow-x: hidden;
`;

interface IDwebCartProps {
  onHide: VoidFunction;
  fetchUser: typeof _fetchUser;
}

function DwebCart({ onHide, fetchUser }: IDwebCartProps) {
  const onHideHandler = () => {
    onHide();
    fetchUser();
  };

  return (
    <OverlaySheet
      navKey="dweb-cart"
      disableNav
      onHide={onHideHandler}
      transitionFrom={SHEET_TRANSITION.RIGHT}
    >
      <Cart />
    </OverlaySheet>
  );
}

const mapDispatchToProps = {
  fetchUser: _fetchUser,
};

export default connect(null, mapDispatchToProps)(DwebCart);
