//helpers
import { ApiHelper } from 'shared/helpers/api';
import { getUrl, ROUTES } from 'shared/api/urls';
import { getDomain } from 'shared/helpers/app';

//constants
const PLATFORM = 'WEB';

export const getRequestId = (): Promise<any> => {
  const url = getUrl(ROUTES.TRUECALLER_INITIATE);
  const body = {
    domain: getDomain(),
    platform: PLATFORM,
  };

  return ApiHelper(url, { method: 'POST', body, isBodyJSON: true }).then(
    async (response: Response) => {
      try {
        const data = await response.json();
        if (response.ok === false) {
          return Promise.reject({ status: response.status, data });
        }
        return data?.response?.requestId;
      } catch (e) {
        return Promise.reject({ status: 500 });
      }
    }
  );
};

export const getTruecallerDetails = (body: Record<string, string>): Promise<any> => {
  const url = getUrl(ROUTES.TRUECALLER_STATUS);

  return ApiHelper(url, { method: 'POST', body, isBodyJSON: true }).then(
    async (response: Response) => {
      try {
        const data = await response.json();
        if (response.ok === false) {
          return Promise.reject({ status: response.status, data });
        }
        return data;
      } catch (e) {
        return Promise.reject({ status: 500 });
      }
    }
  );
};
