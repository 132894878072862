import { A2HS_KEY, SESSION_TIME_AFTER_CANCEL } from './constants';

/**
 * this helper method will set the flag for next 5 days if, action taken by the user
 * action taken: cancel
 *
 */
export const setActionTaken = () => {
  try {
    if (window.sessionStorage === undefined) {
      return;
    }

    const now = new Date();
    const extendedTime = now.getTime() + SESSION_TIME_AFTER_CANCEL;

    window.sessionStorage.setItem(A2HS_KEY, String(extendedTime));
  } catch (err) {
    // DO Nothing
  }
};

/**
 * this method validates, if the time time period between the
 * last action performed by the user and the next required time interval is valid or not
 *
 * this will return true only if time is expired and allowed to show the nudge
 *
 * @return boolean
 *
 */
export const lastSessionValid = () => {
  try {
    if (window.sessionStorage === undefined) {
      return false;
    }

    const lastSavedTime = window.sessionStorage.getItem(A2HS_KEY) || '';
    const now = new Date();

    if (lastSavedTime === '') {
      return false;
    }

    return Number(lastSavedTime) > now.getTime();
  } catch (err) {
    // Do nothing
  }
};
