// libs
import { createSelector } from 'reselect';
import get from 'lodash/get';

// defs
import { RemoteConfigState } from './types';

export const remoteConfigs = (state: { remoteConfigs: RemoteConfigState }): RemoteConfigState =>
  state.remoteConfigs;

export const getRemoteConfigs = createSelector(
  [remoteConfigs],
  (remoteConfigData) => remoteConfigData
);

export const getRemoteConfig = <T extends keyof RemoteConfigState>(
  state: { remoteConfigs: RemoteConfigState },
  config: T
): RemoteConfigState[T] => {
  const remoteConfigsData = getRemoteConfigs(state);
  return get(remoteConfigsData, config);
};
