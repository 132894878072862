import { useEffect, useState } from 'react';
import { History } from 'history';

export const useNavHelper = (
  history: History,
  navKey: string,
  disableNav = false
): [boolean, VoidFunction] => {
  const [doHide, updateDoHide] = useState(false);

  useEffect(() => {
    if (disableNav) {
      return;
    }

    history.push({
      ...history.location,
      state: { ...history.location.state, [navKey]: true },
    });
  }, [disableNav, history, navKey]);

  useEffect(() => {
    if (disableNav) {
      return;
    }

    if (history.location.state !== undefined && history.location.state[navKey] !== undefined) {
      updateDoHide(false);
    } else {
      updateDoHide(true);
    }
  }, [disableNav, history.location.state, navKey]);

  const onHideMenu = () => {
    if (disableNav) {
      updateDoHide(true);
      return;
    }

    history.goBack();
  };

  return [doHide, onHideMenu];
};
