// libs
import React, { memo, useCallback, useMemo, useState } from 'react';

// global components
import Portal from 'shared/components/Portal';
import BaseDotLoader from 'shared/components/Loader';
import SpinnerBase from 'shared/components/Loader/BaseSpinnerLoader';
import { styled } from '@nykaa/ui-components';

// context
import LoaderContext, { InitLoaderProps } from './context';
import { useDomainConfig } from 'shared/domainConfig/context/context';

// constant
import { LOADER_ZINDEX } from 'shared/styles/base';

// defs
interface ILoaderProviderProps {
  children: React.ReactNode;
}

const LoaderWrapper = styled.div`
  position: fixed;
  z-index: ${LOADER_ZINDEX};
  top: 50%;
  left: 50%;
  right: auto;
`;

const DynamicLoader: Record<string, any> = {
  FLoader: <SpinnerBase />,
  NFLoader: <BaseDotLoader />,
};

function LoaderProvider({ children }: ILoaderProviderProps) {
  const [showLoaderFlg, setShowLoader] = useState(false);
  const [loaderWrapperProp, setLoaderWrapperProp] = useState<InitLoaderProps>({
    top: '',
    left: '',
    right: '',
  });
  const {
    LOADER_CONFIG: { PAGE_LOADER },
  } = useDomainConfig();

  const Loader = DynamicLoader[PAGE_LOADER];

  const showLoader = useCallback((initProps: InitLoaderProps) => {
    setLoaderWrapperProp(initProps);
    setShowLoader(true);
  }, []);

  const hideLoader = useCallback(() => setShowLoader(false), []);

  const values = useMemo(() => ({ showLoader, hideLoader }), [hideLoader, showLoader]);

  const { top, left, right } = loaderWrapperProp;

  const containerStyle = useMemo(() => {
    const stylesToApply = { top: top, left: left };
    return right ? { ...stylesToApply, right: right } : stylesToApply;
  }, [top, left, right]);

  return (
    <LoaderContext.Provider value={values}>
      {children}
      {showLoaderFlg && (
        <Portal>
          <LoaderWrapper style={containerStyle} data-test="loader-cnt">
            {Loader}
          </LoaderWrapper>
        </Portal>
      )}
    </LoaderContext.Provider>
  );
}

export default memo(LoaderProvider);
