// libs
import React, { memo, useMemo } from 'react';

// helpers
import { getAkamaiUrl } from 'shared/helpers/utils';

// components
import SpinnerBase from 'shared/components/Loader/BaseSpinnerLoader';

// styles
import { Container, Blobs, BlobsCenter, Blob, Image } from './styles';

// context
import { useDomainConfig } from 'shared/domainConfig/context/context';

const FashionChunkFallback = (
  <Blobs>
    <BlobsCenter>
      <Image
        src={getAkamaiUrl(
          '/pub/static/frontend/Nykaa/theme001/en_US/Fermion_CustomCheckout/images/N.svg'
        )}
        alt="Nykaa Loader"
      />
    </BlobsCenter>
    <Blob />
    <Blob />
    <Blob />
    <Blob />
    <Blob />
    <Blob />
  </Blobs>
);

const DynamicChunkFallback: Record<string, any> = {
  FLChunkLoader: <SpinnerBase />,
  NFChunkLoader: FashionChunkFallback,
};

function ChunkFallback() {
  const {
    LOADER_CONFIG: { CHUNK_FALLBACK_CONFIG },
  } = useDomainConfig();
  const ChunkFallbackLoader = useMemo(() => {
    return DynamicChunkFallback[CHUNK_FALLBACK_CONFIG];
  }, [CHUNK_FALLBACK_CONFIG]);

  return <Container>{ChunkFallbackLoader}</Container>;
}

export default memo(ChunkFallback, () => true);
