// libs
import React, { memo, useEffect, useState } from 'react';
import { styled } from '@nykaa/ui-components';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';

// context
import { useLoaderContext } from 'shared/components/LoaderProvider/context';
import { useOverlaySheetContext } from 'shared/components/OverlaySheet/OverlaySheetContext';

// constants
import { CHECKOUT_V2_URI, WISHLIST } from 'shared/constants/uri';

// helpers
import { addProductIdToWishlist, removeProductIdFromWishlist } from 'shared/store/wishlist/actions';
import { getNewCheckoutURI } from 'shared/components/CartCTA/helpers';

// styles
export const IFrame = styled.iframe<{ hidden: boolean }>`
  height: 100%;
  width: 100%;
  border: 0;
  display: block;

  ${({ hidden }) => hidden && 'visibility: hidden;'}
`;

const LOADER_INITIAL_POSITION = { right: '14%', left: 'auto' };

function Cart() {
  const [loaded, setLoaded] = useState(true);
  const { doHide } = useOverlaySheetContext();

  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useLoaderContext();
  const initHideLoader = () => {
    setLoaded(false);
    hideLoader();
  };

  useEffect(() => {
    showLoader(LOADER_INITIAL_POSITION);
    return () => {
      hideLoader();
    };
  }, [hideLoader, showLoader]);

  useEffect(() => {
    const onMessage = (
      e: MessageEvent<{
        type: 'HIDE_CART' | 'GO_TO_ADDRESS' | 'GO_TO_WISHLIST' | 'GO_TO_PRODUCT';
      }>
    ) => {
      // message not comming from same host drop it
      if (window.location.host.indexOf(e.origin.replace(/^http(s?):\/\//, '')) < 0) {
        return;
      }

      const action = get(e, 'data.type', '');

      switch (action) {
        case 'HIDE_CART': {
          doHide();
          break;
        }

        case 'GO_TO_ADDRESS': {
          window.location.href = getNewCheckoutURI();
          break;
        }

        case 'GO_TO_PRODUCT': {
          const productSlug = get(e, 'data.payload', '/');
          window.open(productSlug);
          break;
        }

        case 'GO_TO_WISHLIST': {
          window.location.href = WISHLIST;
          break;
        }

        case 'ADD_WISHLIST_ID_IN_PARENT_STORE': {
          const itemId = get(e, 'data.payload');
          dispatch(addProductIdToWishlist(itemId));
          break;
        }

        case 'REMOVE_WISHLIST_ID_IN_PARENT_STORE': {
          const itemId = get(e, 'data.payload');
          dispatch(removeProductIdFromWishlist(itemId));
          break;
        }
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [dispatch, doHide]);

  return (
    <>
      <IFrame
        src={CHECKOUT_V2_URI}
        title="Nykaa Fashion Cart"
        hidden={loaded}
        onLoad={initHideLoader}
      />
    </>
  );
}

export default memo(Cart);
