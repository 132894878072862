// libs
import { styled } from '@nykaa/ui-components';
import { css, keyframes } from '@emotion/core';
import { Keyframes } from '@emotion/serialize';
import { hexToRgb } from '@nykaa/ui-components/styles/utils';

// constants
import { OVERLAY_CSS, OVERLAY_ZINDEX } from 'shared/styles/base';
import { SHEET_TRANSITION } from './constants';

type translateStates = [string | number, string | number, string | number];

// TODO - Move to styles animation helpers
const getTranslate3DKeyframes = (
  [startX, startY, startZ]: translateStates,
  [endX, endY, endZ]: translateStates
) => keyframes`
  from {
    transform: translate3D(${startX}, ${startY}, ${startZ});
  }

  to {
    transform: translate3D(${endX}, ${endY}, ${endZ});
  }
`;

const getAnimationCss = (animation: Keyframes) => css`
  animation: ${animation} 0.2s ease-in;
`;

const getSlideKeyframes = (initialState: translateStates) => [
  getTranslate3DKeyframes(initialState, [0, 0, 0]),
  getTranslate3DKeyframes([0, 0, 0], initialState),
];

const [slideBottomIn, slideBottomOut] = getSlideKeyframes([0, '100%', 0]);
const [slideTopIn, slideTopOut] = getSlideKeyframes([0, '-100%', 0]);
const [slideLeftIn, slideLeftOut] = getSlideKeyframes(['-100%', 0, 0]);
const [slideRightIn, slideRightOut] = getSlideKeyframes(['100%', 0, 0]);

export const sheetTopIn = getAnimationCss(slideTopIn);
export const sheetTopOut = getAnimationCss(slideTopOut);
export const sheetBottomIn = getAnimationCss(slideBottomIn);
export const sheetBottomOut = getAnimationCss(slideBottomOut);
export const sheetLeftIn = getAnimationCss(slideLeftIn);
export const sheetLeftOut = getAnimationCss(slideLeftOut);
export const sheetRightIn = getAnimationCss(slideRightIn);
export const sheetRightOut = getAnimationCss(slideRightOut);

const SHEET_STYLES = {
  [SHEET_TRANSITION.TOP]: [sheetTopIn, sheetTopOut],
  [SHEET_TRANSITION.RIGHT]: [sheetRightIn, sheetRightOut],
  [SHEET_TRANSITION.BOTTOM]: [sheetBottomIn, sheetBottomOut],
  [SHEET_TRANSITION.LEFT]: [sheetLeftIn, sheetLeftOut],
};

export const Overlay = styled.div<{ overlayColor?: string; overlayZindex?: number }>`
  ${OVERLAY_CSS};
  z-index: ${({ overlayZindex }) => overlayZindex && overlayZindex};
  background-color: ${({ theme, overlayColor = '' }) =>
    overlayColor ? overlayColor : hexToRgb(theme.colors.surfaceInverse, 0.64)};
`;

export const Container = styled.div<{
  transition: SHEET_TRANSITION;
  hide: boolean;
  overlayZindex?: number;
}>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ overlayZindex }) => (overlayZindex ? overlayZindex + 1 : OVERLAY_ZINDEX + 1)};
  position: fixed;

  ${({ transition, hide }) => {
    const [sheetInCss, sheetOutCss] = SHEET_STYLES[transition];
    return hide ? sheetOutCss : sheetInCss;
  }};
`;
