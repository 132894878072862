// libs
import React, { memo, useEffect } from 'react';

// helpers
import AnalyticsSDK from '@nykaa/analytics-sdk';
import { getDeviceType, getEnvironment, getCachedCustomerId } from './helpers';

// components
import Pageload from 'shared/components/Analytics/Pageload';

// constants
import { VERTICAL_NAME } from 'shared/constants';

interface InitializeProps {
  customerId: string;
  onIntlNetwork: boolean;
  excludedEventNames: string[];
  eventsThreshold: number;
  batchInterval: number;
  maxSessionRefreshTime: number;
  sessionExpiryTime: number;
}

declare global {
  interface Window {
    na: any;
  }
}

/**
 * React component to initialize analytics SDK
 */
function Initialize({
  customerId,
  onIntlNetwork,
  excludedEventNames,
  eventsThreshold,
  batchInterval,
  maxSessionRefreshTime,
  sessionExpiryTime,
}: InitializeProps) {
  useEffect(() => {
    if (onIntlNetwork) {
      return;
    }

    const localCustomerId = customerId.length > 0 ? customerId : getCachedCustomerId();

    const sdk = new AnalyticsSDK({
      analyticsEndpoint: process.env.ANALYTICS_ENDPOINT,
      environment: getEnvironment(),
      vertical: VERTICAL_NAME,
      platform: getDeviceType(),
      appVersion: __VERSION__,
      customerId: localCustomerId,
      batchInterval,
      excludedEventNames,
      eventsThreshold,
      retinaSdkPlatform: getDeviceType() === 'dweb' ? 'dweb' : 'mweb',
      maxSessionRefreshTime: maxSessionRefreshTime,
      sessionExpiryTime: sessionExpiryTime,
    });

    window.na = sdk;

    return () => {
      sdk.destroy();
    };
  }, [
    customerId,
    onIntlNetwork,
    batchInterval,
    excludedEventNames,
    eventsThreshold,
    maxSessionRefreshTime,
    sessionExpiryTime,
  ]);

  return <Pageload />;
}

export default memo(Initialize);
