// libs
import isEmpty from 'lodash/isEmpty';

// helpers
import { getUrlInfo } from 'shared/helpers/apiExperiment/storage';

// defs
import { IHashMap, RequestBody, ProcessesUrlParams } from './types';
import { IParamExperiementValues } from 'shared/helpers/apiExperiment/types';

//types
import { Coupon, CouponDetails, OfferDetails } from 'shared/layouts/Coupons/types';

const MILLISECONDS_MULTIPLIER = 1000;

const getFormData = (data: IHashMap) => {
  const formBody = [];

  for (let property in data) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }

  return formBody.join('&');
};

export const getRequestBody = (isBodyJSON: boolean, data: RequestBody) => {
  if (data === undefined) {
    return;
  }

  return isBodyJSON ? JSON.stringify(data) : getFormData(data as IHashMap);
};

/**
 *
 * this is a transformer layer for providing url on basis of api-experiment
 *
 * @param {string} srcUrl - the source URL which is use to fetch the request
 * @param {boolean} isPostRequest - Weather the reuest call is Post request or get request
 * @param {object} formBody - it will be the actual body that we need to pass with the request call
 * @param {boolean} isBodyJSON - if the request call is made with JSON body or not
 *
 * @type {Object}
 * @property {string} url The processed url that will be used to actually fetch the request.
 * @property {string} requestBody The query string then needs to append with fetch request.
 *
 * @return {Object}
 */

export const getProcessedUrl = ({ srcUrl, formBody, isBodyJSON, method }: ProcessesUrlParams) => {
  // if it is a server call we will not manipulate the request
  if (__SERVER__) {
    return {
      url: srcUrl,
      requestBody: getRequestBody(isBodyJSON, formBody),
    };
  }

  const urlObj = new URL(srcUrl);
  const apiExperiment: IParamExperiementValues | undefined = getUrlInfo(urlObj.pathname);

  // if the method is PUT , we'll return the body as it is
  if (method === 'PUT') {
    return { url: urlObj.toString(), requestBody: formBody };
  }

  // if we do not found api experiment data in local storage
  // than we will simply return the base request parameters
  if (apiExperiment === undefined) {
    return { url: srcUrl, requestBody: getRequestBody(isBodyJSON, formBody) };
  }

  // if we find the target url in the api-experiment
  // we will replace it with actual url
  if (apiExperiment.targetUrl) {
    urlObj.pathname = apiExperiment.targetUrl;
  }

  // if the request type is GET
  if (isEmpty(apiExperiment.params) === false && method === 'GET') {
    Object.keys({ ...apiExperiment.params }).forEach((param) => {
      const value = apiExperiment.params[param] || '';
      urlObj.searchParams.set(param, value);
    });
  }

  let requestBody = formBody;

  // if the request type is POST
  if (method === 'POST') {
    const newBodyData =
      formBody === undefined ? apiExperiment.params : { ...formBody, ...apiExperiment.params };
    requestBody = getRequestBody(isBodyJSON, newBodyData);
  }

  return { url: urlObj.toString(), requestBody };
};

export const getValidCouponsData = (couponOffer: Coupon[] | CouponDetails[] | OfferDetails[]) => {
  const currentDate = new Date().getTime();
  if (isEmpty(couponOffer)) return couponOffer;
  return couponOffer.filter(
    (value: Coupon | CouponDetails | OfferDetails | undefined) =>
      (value?.expiryEpoch || 0) * MILLISECONDS_MULTIPLIER > currentDate ||
      value?.expiryEpoch === 0 ||
      value?.expiryEpoch === null
  );
};

export const getExpiredCouponsCount = (couponsData: Coupon[] | OfferDetails[]) => {
  let couponsExpiredCount = 0;
  if (couponsData) {
    couponsData.map((data: Coupon | OfferDetails | undefined) => {
      const timeDiff = (data?.expiryEpoch || 0) * MILLISECONDS_MULTIPLIER - new Date().getTime();
      if (timeDiff <= 0 && data?.expiryEpoch !== 0 && data?.expiryEpoch !== null)
        couponsExpiredCount += 1;
    });
  }
  return couponsExpiredCount;
};
