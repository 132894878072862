//libs
import { styled } from '@nykaa/ui-components';

//constants
import { DWEB_PRIMARY_HEADER_HEIGHT } from 'shared/styles/base';

export const OuterContainer = styled.div`
  position: relative;
  min-height: calc(100vh - ${DWEB_PRIMARY_HEADER_HEIGHT}px);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
`;
export const InnerContainer = styled.div`
  display: block;
  position: relative;
  width: 648px;
  margin: auto;
  border: 1px solid ${({ theme }) => theme.colors.textOutline};
  border-radius: ${({ theme }) => theme.borders.radius10};
`;

export const Header = styled.header`
  width: 100%;
  height: ${DWEB_PRIMARY_HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing.spacing240};
`;
