// constants
import { MM_CATEGORIES, VIEW_TYPES, ICategoryData, ICategory } from './types';

export const transformData = (data: { response: any }): ICategoryData[] => {
  // find master/homepage content
  const findSupercategoryData = (id: string | number) => {
    const relevantData = data.response.children_data.filter((item: { id: string | number }) => {
      return item.id === id;
    });
    return relevantData[0].children_data || [];
  };

  let masterContent = data.response.children_data.filter((masterData: ICategory) => {
    return masterData.id === MM_CATEGORIES.MASTER_DATA_ID;
  });

  const findBrandsData = () => {
    return masterContent[0].children_data.filter(
      (item) => item.name.toLowerCase() === MM_CATEGORIES.BRANDS
    )[0];
  };

  let visualContent = masterContent[0].children_data
    .filter((items: ICategory) => {
      return items.name.toLowerCase() === MM_CATEGORIES.MASTER_CONTENT_ID;
    })[0]
    .children_data.sort((a, b) => {
      return a.position - b.position;
    });

  let transformedData: ICategoryData[] = [];

  visualContent.map((child) => {
    if (child.name.toLowerCase() === MM_CATEGORIES.SUPER_CATEGORY) {
      child.children_data.map((category: ICategory) => {
        transformedData.push({
          ...category,
          level: 0,
          children_data: findSupercategoryData(category.id),
          subTitle: category['sub-title'],
          image: category.image || category.image_url,
        });
      });
    } else if (child.name.toLowerCase() === MM_CATEGORIES.ALL_BRANDS) {
      const brandsData = findBrandsData();

      if (child.view_type.toLowerCase() === VIEW_TYPES.LIST) {
        child.children_data[0] = {
          ...child.children_data[0],
          children_data: {
            brandsData: brandsData.children_data,
            view_type: VIEW_TYPES.BRANDS,
          },
          name: 'All Brands',
        };
      }

      transformedData.push(child);
    } else {
      transformedData.push(child);
    }
  });

  return transformedData;
};
