// libs
import React, { memo, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

// components
import Header from 'shared/components/Header/MobileHeader';
import AuthModalWrapper from 'shared/components/AuthModal/AuthModalWrapper';

// provider
import MobileActionProvider from 'shared/components/Header/MobileAction';
import MobileLayoutContext from './MobileLayoutContext';

// styles
import { Container } from './styles';

// defs
import { IHeaderOptions } from 'shared/store/app/types';
import { isWebView } from 'shared/helpers/utils/url';

interface IMobileHeaderProviderProps {
  children: React.ReactNode;
  className?: string;
  headerProps: IHeaderOptions;
  isHeaderVisible: boolean;
}

const DEFAULT_STYLES = {};

function MobileHeaderProvider({
  children,
  className,
  headerProps,
  isHeaderVisible,
}: IMobileHeaderProviderProps) {
  const [headerStyles, setHeaderStyles] = useState<React.CSSProperties>(DEFAULT_STYLES);
  const [HeaderContent, setHeaderContent] = useState<React.ReactNode | undefined>(undefined);
  const [HeaderActions, setHeaderActions] = useState<React.ReactNode | undefined>(undefined);

  const setCSS = useCallback((styles: React.CSSProperties) => setHeaderStyles(styles), []);
  const resetCSS = useCallback(() => setHeaderStyles(DEFAULT_STYLES), []);

  const location = useLocation();

  const { headerMerge } = headerProps;

  const shouldShowHeader = !isWebView(location) && isHeaderVisible;

  return (
    <Container
      allowPadding={shouldShowHeader && headerMerge === false}
      className={className}
      style={headerStyles}
      id="app-container"
    >
      <AuthModalWrapper>
        <MobileActionProvider>
          {shouldShowHeader && (
            <Header {...headerProps} HeaderContent={HeaderContent} MobileActions={HeaderActions} />
          )}
          <MobileLayoutContext.Provider
            value={{ setHeaderContent, setHeaderActions, setCSS, resetCSS }}
          >
            {children}
          </MobileLayoutContext.Provider>
        </MobileActionProvider>
      </AuthModalWrapper>
    </Container>
  );
}

export default memo(MobileHeaderProvider);
