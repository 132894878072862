// helpers
import { getUrl, ROUTES } from 'shared/api/urls';
import { ApiHelper } from 'shared/helpers/api';
import { getDeviceType, getDomain } from 'shared/helpers/app';

// constatns
const DOMAIN_NAME = getDomain();
const WISHLIST_BUCKET_NAME = 'wishlist';
const deviceType = getDeviceType();

interface IAddToWishlist {
  itemId: string;
}

interface IGetWishlistItemsProps {
  pageNumber: number;
  size: number;
  isPriceRevealEnabled?: boolean;
}

interface IRemoveFromWishlist {
  itemId: string;
}

export const getWishlistItemIds = (): Promise<any> => {
  const url = `${getUrl(ROUTES.GET_WISHLIST_ITEM_IDS)}`;

  const params = new URLSearchParams();
  params.set('bucket', WISHLIST_BUCKET_NAME);
  params.set('domain', DOMAIN_NAME);
  params.set('deviceType', deviceType);

  return ApiHelper(`${url}?${params.toString()}`).then((data: Response) => {
    if (!data.ok) {
      return Promise.reject(data.statusText);
    }
    return data.json();
  });
};

export const getWishlistItems = ({
  pageNumber,
  size,
  isPriceRevealEnabled = false,
}: IGetWishlistItemsProps) => {
  const url = `${getUrl(ROUTES.GET_WISHLIST_ITEMS)}`;
  const params = new URLSearchParams();
  params.set('bucket', WISHLIST_BUCKET_NAME);
  params.set('domain', DOMAIN_NAME);
  params.set('deviceType', deviceType);
  params.set('pageNumber', String(pageNumber));
  params.set('size', String(size));

  if (isPriceRevealEnabled) {
    params.append('enable_pr', '1');
  }

  return ApiHelper(`${url}?${params.toString()}`).then((data: Response) => {
    if (!data.ok) {
      return Promise.reject({ statusCode: data?.status, message: data.statusText });
    }
    return data.json();
  });
};

export const addToWishlist = ({ itemId }: IAddToWishlist): Promise<any> => {
  const url = `${getUrl(ROUTES.ADD_TO_WISHLIST)}`;

  const body = {
    bucket: WISHLIST_BUCKET_NAME,
    deviceType,
    domain: DOMAIN_NAME,
    itemId: String(itemId),
    quantity: 1,
  };

  return ApiHelper(url, {
    method: 'POST',
    body,
    isBodyJSON: true,
  }).then((data: Response) => {
    if (!data.ok) {
      return Promise.reject({
        statusCode: data.status,
      });
    }
    return data.json();
  });
};

export const removeFromWishlist = ({ itemId }: IRemoveFromWishlist): Promise<any> => {
  const url = `${getUrl(ROUTES.REMOVE_FROM_WISHLIST)}`;

  const body = {
    bucket: WISHLIST_BUCKET_NAME,
    deviceType,
    domain: DOMAIN_NAME,
    itemId: itemId,
    quantity: 1,
  };

  return ApiHelper(url, {
    method: 'DELETE',
    body,
    isBodyJSON: true,
  }).then((data: Response) => {
    if (!data.ok) {
      return Promise.reject({ statusCode: data?.status });
    }
    return data.json();
  });
};
