// libs
import React, { memo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

// constants
import { TEXT } from './constants';
import { AUTH_URI } from 'shared/constants/uri';

// context
import { useOverlaySheetContext } from 'shared/components/OverlaySheet';
import { useResponsiveContext } from 'shared/components/ResponsiveLoader/ResponsiveContext';
import { useAuthModalWrapperContext } from 'shared/components/AuthModal/AuthModalWrapperContext';

// styles
import { Actions, Info, Heading, SubmitButton, LaterButton } from './styles';
import { BREAKPOINTS } from 'shared/styles/base';

// helpers
import { getRedirectionURLWithCurrentPage } from 'shared/components/OTPVerification/helpers';

interface INudgeProps {
  info: string;
  firstName: string;
}

function Nudge({ info, firstName }: INudgeProps) {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const { doHide } = useOverlaySheetContext();
  const { showAuthModal } = useAuthModalWrapperContext();
  const { deviceWidth } = useResponsiveContext();

  const isDesktop = deviceWidth > BREAKPOINTS.DESKTOP;

  const onSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (isDesktop) {
        params.set(AUTH_URI.PARAM_KEY, AUTH_URI.MOBILE_NUMBER_VERIFICATION);
        const url = pathname + '?' + params.toString();
        showAuthModal(url);

        return;
      }

      history.push(
        getRedirectionURLWithCurrentPage({
          url: AUTH_URI.MOBILE_NUMBER_VERIFICATION,
          search,
          pathname,
        })
      );
    },
    [history, isDesktop, showAuthModal, search, pathname, params]
  );

  return (
    <>
      <Heading data-test="nudge-header">
        {TEXT.HEADING_PREFIX}
        {firstName}
      </Heading>
      <Info data-test="nudge-info">{info}</Info>
      <Actions>
        <SubmitButton
          onClick={onSubmit}
          aria-label={TEXT.SUBMIT_BUTTON_LABEL}
          data-test="nudge-submit-action"
        >
          {TEXT.SUBMIT_BUTTON_LABEL}
        </SubmitButton>
        <LaterButton
          onClick={doHide}
          aria-label={TEXT.LATER_BUTTON_LABEL}
          data-test="nudge-later-button"
        >
          {TEXT.LATER_BUTTON_LABEL}
        </LaterButton>
      </Actions>
    </>
  );
}

export default memo(Nudge);
