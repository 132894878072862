// libs
import React, { memo, useEffect } from 'react';

// components
import SmartAppBanner from './components/SmartAppBanner';
import ConnectivityNotifier from './ConnectivityNotifier';
import InitializeExperiment from 'shared/components/ExperimentV2/components/InitializeExperiment';
import InitializeAnalytics from 'shared/components/Analytics/Initialize';
import WishlistInitialisation from 'shared/components/Wishlist/components/WishlistInitialisation';
import UserInit from './components/UserInit';
import NewRelicBrowser from 'shared/components/Boot/components/NewRelicBrowser';
import DetectIncognito from 'shared/components/Boot/components/DetectIncognito';

// redux actions
import { fetchUser as _fetchUser } from 'shared/store/app/services';
import { setWebAppType as _setWebAppType } from 'shared/store/app/actions';

// helpers
import { loadScripts, removeCartCheckoutExperimentCookies } from './helpers';
import { LOAD_TYPE } from 'shared/helpers/dom/deferScript';
import { storeUserFormKey } from 'shared/helpers/api/authFormKeyStorage';
import { setOnrKeyStorage } from 'shared/layouts/Cart/helpers';
import { getWebAppType } from 'shared/helpers/deviceType/webAppType';
import NFA from 'shared/helpers/analytics/NFAnalytics';

// defs
interface IBootProps {
  fetchUser: typeof _fetchUser;
  setWebAppType: typeof _setWebAppType;
  onIntlNetwork: boolean;
  userFormKey: string;
}

/**
 * Use this component to execute on Browser side - On Boot
 *
 * NOTE: Only execute scripts which impact globally
 * e.g - Do API calls to update redux - which would be used by multiple components on client
 */
function Boot({ fetchUser, setWebAppType, onIntlNetwork, userFormKey }: IBootProps) {
  // fetch user information on mount
  useEffect(() => {
    if (onIntlNetwork) {
      return;
    }

    NFA.setSourcePlatform();
    const GTM_SRC = `https://www.googletagmanager.com/gtm.js?id=${process.env.GTM_ID}`;

    // defer loading tag managers
    loadScripts([
      {
        scriptPath: GTM_SRC,
        loadType: LOAD_TYPE.DEFER,
      },
    ]);
  }, [onIntlNetwork]);

  // update user form key on store updates
  useEffect(() => {
    // dont set form key if empty, would overwrite cached value
    if (!userFormKey || userFormKey.length === 0) {
      return;
    }

    storeUserFormKey(userFormKey);
  }, [userFormKey]);

  useEffect(() => {
    const webAppType = getWebAppType();
    setWebAppType(webAppType);
  }, [setWebAppType]);

  useEffect(() => {
    removeCartCheckoutExperimentCookies();
    setOnrKeyStorage();
  }, []);

  return (
    <>
      <NewRelicBrowser />
      <SmartAppBanner />
      <UserInit fetchUser={fetchUser} />
      <ConnectivityNotifier />
      <WishlistInitialisation />
      <InitializeExperiment />
      <InitializeAnalytics />
      <DetectIncognito />
    </>
  );
}

export default memo(Boot);
