// libs
import { createContext, useContext } from 'react';

// defs
interface IMobileActionContext {
  showSearch: VoidFunction;
  setShowSearchCallback: (cb: VoidFunction) => void;
  canShowA2hsContent: boolean;
  addToHomeClickHandler: (via: string) => void;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

const initialValue: IMobileActionContext = {
  showSearch: EMPTY_FUNC,
  setShowSearchCallback: EMPTY_FUNC,
  canShowA2hsContent: false,
  addToHomeClickHandler: EMPTY_FUNC,
};

const MobileActionContext = createContext(initialValue);

export const useMobileActionContext = () => useContext(MobileActionContext);

export default MobileActionContext;
