export const TEXTS = {
  BY_SIGNING: 'By signing in you agree to our',
  TERMS: 'Terms and Conditions.',
};

export const OMS_LOGIN_COOKIE_NAME = 'head_data_react';
export const LOGIN_ERROR_COOKIE = 'loginError';
export const VALID_LOGIN_ERROR = 'email';

export const QUERY_STRING_AUTH_INITIATER = 'ptype';
export const QUERY_STRING_AUTH_VALUE = 'auth';

export const OTP_CONFIG = {
  isEnabledForMobile: true,
  isEnabledForDesktop: true,
  inputLength: 6,
  resend: { maxResendAllowed: 3, otpMaxTimeAllowed: 45 },
  nudge: {
    firstBreakpoint: 6,
    ttlBeforeFirstBreakpoint: 3600000,
    ttlAfterFirstBreakpoint: 3600000,
    maxTimesToShowNudge: 10,
    info: 'To get instantaneous updates on your order and exclusive offers, kindly verify your mobile number.',
  },
};
