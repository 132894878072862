// libs
import React, { memo, useState, useMemo } from 'react';
import { SerializedStyles } from '@emotion/core';

// global components
import Footer from 'shared/components/Footer/Mobile';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import MobileHeaderProvider from 'shared/components/Header/MobileHeaderProvider';
import LoaderProvider from 'shared/components/LoaderProvider';

// contexts
import AppLayoutContext from 'shared/layouts/AppLayout/AppLayoutContext';

// styles
import { Container, AppLayoutContainer } from './styles';

// defs
interface IAppLayout {
  children: React.ReactNode;
}

function AppLayoutMobile({ children }: IAppLayout) {
  const [childCss, setCSS] = useState<SerializedStyles | undefined>(undefined);

  const styles = useMemo(() => [childCss !== undefined && childCss], [childCss]);

  return (
    <AppLayoutContainer cssProps={styles}>
      <AppLayoutContext.Provider value={{ setCSS }}>
        <LoaderProvider>
          <MobileHeaderProvider>
            <ErrorBoundary>
              <Container>{children}</Container>
            </ErrorBoundary>
          </MobileHeaderProvider>
          <Footer />
        </LoaderProvider>
      </AppLayoutContext.Provider>
    </AppLayoutContainer>
  );
}

export default memo(AppLayoutMobile);
