// libs
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// components
import ErrorScreen from 'shared/components/VisitorPriortization/ErrorScreen';

// helpers
import { setOnBlockCallback } from 'shared/components/VisitorPriortization/helpers/csr';

// global components
import ChunkFallback from 'shared/components/ChunkFallback';

// defs
type ComponentType = React.ComponentClass | React.FC;

interface IRouteRenderProps extends RouteComponentProps<any> {
  Component: ComponentType;
  routes?: string[];
}

function RouteRender(routeProps: IRouteRenderProps) {
  const [showVPScreen, setShowVPScreen] = useState(false);
  const { Component, ...props } = routeProps;

  useEffect(() => {
    const onError = () => {
      setShowVPScreen(true);
    };

    const clearOnBlockCallback = setOnBlockCallback(onError);

    return () => {
      clearOnBlockCallback();
    };
  }, []);

  if (showVPScreen) {
    return <ErrorScreen />;
  }

  // @ts-ignore
  return (
    <>
      <Component {...props} fallback={<ChunkFallback />} />
    </>
  );
}

function customRoute(Component: ComponentType, routes: string[]) {
  // eslint-disable-next-line react/display-name
  return (props: RouteComponentProps<any>) => {
    return <RouteRender {...props} routes={routes} Component={Component} />;
  };
}

export default customRoute;
