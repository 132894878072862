//libs
import get from 'lodash/get';
import { getCachedVPDoc } from './helpers';
import { VP_BLOCK_MODE, VP_STATE, VP_USER } from 'shared/components/VisitorPriortization/constants';
import Storage from 'shared/helpers/storage';

const getUserSegment = () => {
  try {
    const cachedUserInfo = Storage.getItem<string>(VP_USER) || '';
    if (cachedUserInfo === '') {
      return {
        key: 0,
        ruleID: '',
      };
    }
    return JSON.parse(cachedUserInfo);
  } catch (_) {
    return {
      key: 0,
      ruleID: '',
    };
  }
};

let visitorPriortizationConfig = __BROWSER__ ? getCachedVPDoc() : undefined;
const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

let onBlockedCallback = EMPTY_FUNC;

const { key: userSegment = 0 } = getUserSegment();
export const callOnBlockCallback = () => {
  // call on block end of the current stack execution
  setTimeout(() => {
    onBlockedCallback();
  }, 0);
};

const getEndpointResponse = (endpoint: string) => {
  let vpErrorMessage = get(
    visitorPriortizationConfig,
    'default_message',
    'Unfortunately, we are currently not serving. Hold tight, we will be back soon!'
  );
  let vpBlockMode = VP_BLOCK_MODE.RESPONSE;

  const apiResponseMode = get(visitorPriortizationConfig, 'response_mode');

  if (!apiResponseMode) {
    return { vpErrorMessage, vpBlockMode };
  }

  const matchedApiResponse = Object.keys(apiResponseMode).find(
    (api: string) => endpoint.indexOf(api) >= 0
  );

  if (!matchedApiResponse || !apiResponseMode[matchedApiResponse]) {
    return { vpErrorMessage, vpBlockMode };
  }

  vpBlockMode =
    apiResponseMode[matchedApiResponse].mode === VP_BLOCK_MODE.FULL_PAGE
      ? VP_BLOCK_MODE.FULL_PAGE
      : VP_BLOCK_MODE.RESPONSE;

  const responseMessage = get(apiResponseMode[matchedApiResponse], 'message');

  if (responseMessage) {
    vpErrorMessage = responseMessage;
  }

  return { vpErrorMessage, vpBlockMode };
};

/**
 * Check if API is blocked for current user as per visitor priortization
 * @param endpoint API URL
 */
export const isAPIBlocked = (endpoint: string) => {
  const vpStatus = get(visitorPriortizationConfig, 'state', VP_STATE.ALLOW_ALL);
  if (
    visitorPriortizationConfig === undefined ||
    // visitor priortization disabled
    vpStatus === VP_STATE.ALLOW_ALL
  ) {
    return { isBlocked: false };
  }

  const blockPercentage = get(visitorPriortizationConfig, 'block_percentage', 0);

  // If user is not in blocked segment API request is allowed
  if (userSegment > blockPercentage) {
    return { isBlocked: false };
  }

  const { vpBlockMode, vpErrorMessage } = getEndpointResponse(endpoint);

  // if whole application blocked and user is in blocked segment, all API request is blocked
  if (vpStatus === VP_STATE.BLOCKED_ALL) {
    // show full page block only if added in full page block mode
    if (vpBlockMode === VP_BLOCK_MODE.FULL_PAGE) {
      callOnBlockCallback();
    }
    return { isBlocked: true, message: vpErrorMessage };
  }

  const blockedAPIs = get(visitorPriortizationConfig, 'web_apis', []);
  const apiMatched = blockedAPIs.some((api: string) => endpoint.indexOf(api) >= 0);

  // if api endpoint is not in blocked list do nothing
  if (apiMatched === false) {
    return { isBlocked: false };
  }

  // show full page block only if added in full page block mode
  if (vpBlockMode === VP_BLOCK_MODE.FULL_PAGE) {
    callOnBlockCallback();
  }
  // api request is blocked
  return { isBlocked: true, message: vpErrorMessage };
};

export const setOnBlockCallback = (cb: VoidFunction) => {
  onBlockedCallback = cb;

  // clear function
  return () => {
    if (onBlockedCallback === cb) {
      onBlockedCallback = EMPTY_FUNC;
    }
  };
};

export const getVPDoc = () => visitorPriortizationConfig;
