// libs
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// components
import DwebCart from './DwebCart';
// contexts
import DwebCartContext from './context';

// constants
const SHOW_CART = 'showCart';

interface IDwebCartProviderProps {
  children: React.ReactNode;
}

function DwebCartProvider({ children }: IDwebCartProviderProps) {
  const [canShowCart, setShowCart] = useState(false);
  const { pathname, search } = useLocation();

  const history = useHistory();

  // on show cart appending showcart = 1 param
  const showCart = useCallback(() => {
    const params = new URLSearchParams(search);

    params.set(SHOW_CART, '1');

    let url = pathname + '?' + params.toString();

    history.push(url);
  }, [history, pathname, search]);

  // on hide cart delete showcart param
  const onHide = useCallback(() => {
    const params = new URLSearchParams(search);

    // remove showCart param
    params.delete(SHOW_CART);

    let url = pathname + '?' + params.toString();

    history.replace(url);
  }, [history, pathname, search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    // set true if showcart=1 is present else false
    setShowCart(searchParams.get(SHOW_CART) === '1');
  }, [search]);

  const values = useMemo(() => ({ showCart, canShowCart }), [canShowCart, showCart]);

  return (
    <DwebCartContext.Provider value={values}>
      {children}
      {canShowCart && <DwebCart onHide={onHide} />}
    </DwebCartContext.Provider>
  );
}

export default memo(DwebCartProvider);
