import { css } from '@emotion/core';
import { styled } from '@nykaa/ui-components';

export const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const resetButton = css`
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
`;

export const BaseButton = styled.button`
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
`;

/**
 * Takes inputs in hex with opacity and return rgba value
 * @param {string} hexCode - hex value of color
 * @param {number} [opacity] -  opacity value (0-100)
 *
 * @returns {string} - calculated rgba value
 */
export const convertHexToRGBA = (hexCode: string, opacity: number) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

/**
 * @param {number} elementHeight - height of the element without considering other ui/system elements
 *
 * @description to be used for all the elements with expected css values, position: fixed and bottom: 0
 *  the returned css props would calculate new height for element considering all the obscure browser/system ui and place the elements to top
 *
 * @returns {string} - calculated css props for height and padding
 */
export const fixedBottomCss = (elementHeight: number = 0) => {
  return `height: calc(env(safe-area-inset-bottom, 0) + ${elementHeight}px); 
            padding-bottom: env(safe-area-inset-bottom); 
            @media screen and (orientation:landscape) {
                height: calc(env(safe-area-inset-left, 0) + ${elementHeight}px); 
                padding-bottom: env(safe-area-inset-left);
             }
            `;
};
